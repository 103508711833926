<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
    <!-- SECTION HERO + SUBNAV -->
    <hero></hero>
    <subnav [isTitleMode]="true" title="{{ 'wap1_p_info_title' | translate }}"></subnav>

    <div class="container px-4">
        <!-- HERO -->
        <div class="row my-5 justify-content-center">
            <div class="col-12 text-center">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_section1' | translate | safeMark">
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onJoinClick()">{{ 'wap1_p_info_joinNow' | translate}}</btn-standard>
                </div>
            </div>
        </div>

        <div class="row my-5 justify-content-center">
            <div class="col-12 text-center">
                <div class="embed-responsive">
                    <iframe class="w-100 h-100" [src]="videoUrl" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="YouTube video player">
                    </iframe>
                </div>
            </div>
        </div>

        <!-- BENEFITS -->
        <div class="row my-5 justify-content-center">
            <div class="col-12 text-center">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_benefitTitle' | translate | safeMark">
                </div>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_1_en.jpg')" />
            </div>

            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_benefit1' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_benefit2' | translate | safeMark">
                </div>
            </div>

            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_2_en.jpg')" />
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_3_en.jpg')" />
            </div>

            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_benefit3' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_benefit4' | translate | safeMark">
                </div>
            </div>

            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_4_en.jpg')" />
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_5_en.jpg')" />
            </div>

            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_benefit5' | translate | safeMark">
                </div>
            </div>
        </div>
        <!-- FEATURES -->
        <div class="row my-5 justify-content-center">
            <div class="col-12 text-center">
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onJoinClick()">{{ 'wap1_p_info_joinNow' | translate}}</btn-standard>
                </div>
                <br>
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_featureTitle' | translate | safeMark">
                </div>
            </div>
        </div>
        <div class="row my-5 justify-content-center">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 hover-scale">
                <div (click)="onFeatureClick('feature1')" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature1' | translate | safeMark">
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 hover-scale">
                <div (click)="onFeatureClick('feature2')" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature2' | translate | safeMark">
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 hover-scale">
                <div (click)="onFeatureClick('feature3')" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature3' | translate | safeMark">
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 hover-scale">
                <div (click)="onFeatureClick('feature4')" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature4' | translate | safeMark">
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 hover-scale">
                <div (click)="onFeatureClick('feature5')" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature5' | translate | safeMark">
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4  hover-scale"> <!-- No mb-4 for the last item -->
                <div (click)="onFeatureClick('feature6')" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature6' | translate | safeMark">
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <btn-standard (click)="onJoinClick()">{{ 'wap1_p_info_joinNow' | translate}}</btn-standard>
            </div>
        </div>

        <!-- FAQ -->
        <div class="row my-5 justify-content-center">
            <div class="col-12">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_info_faq' | translate | safeMark">
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onJoinClick()">{{ 'wap1_p_info_joinNow' | translate}}</btn-standard>
                </div>
            </div>
        </div>

        <!-- hereForYou -->
        <div class="row my-5 justify-content-center">
            <div class="col-12">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>

        <hr>
        <footer></footer>
    </div>
</div>