import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'chip-barcode',
  templateUrl: './chip-barcode.component.html',
  styleUrls: ['./chip-barcode.component.scss']
})

export class ChipBarcodeComponent implements OnInit
{
    constructor()
    {
    }

    ngOnInit(): void
    {
    }
}
