
import { AuthModal } from '../../interfaces/modal';
import { ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthType } from '../../interfaces/authType';
import { NavigatorService } from '../../services/navigator.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'modal-auth',
  templateUrl: './modal-auth.component.html',
  styleUrls: ['./modal-auth.component.scss']
})
export class ModalAuthComponent implements OnInit
{
    private modalSubscription: Subscription | undefined;

    public AuthType = AuthType;
    public authType: AuthType = AuthType.SignIn;

    // Sign Up
    get signupform() { return this.signUpForm.controls; }
    get signinform() { return this.signInForm.controls; }
    get forgotpassform() { return this.forgotPassForm.controls; }
    
    signUpForm: FormGroup;
    signInForm: FormGroup;
    forgotPassForm: FormGroup;
    
    signUpSubmittedForm: boolean = false;
    signInSubmittedForm: boolean = false;
    forgotSubmittedForm: boolean = false;

    wrongpassword: boolean = false;
    userNotFound: boolean = false;
    sendForgotPassWithSuccess: boolean = false;
    errorEmailAlreadyExist: boolean = false;

    authModal: AuthModal | undefined;

    isTabSignUp: boolean = false;

    constructor(private authService: AuthService, private modalService: ModalService,
                private formBuilder : FormBuilder, private navigatorService: NavigatorService)
    {
        this.signInForm = this.formBuilder.group(
        {
            email: [null, [Validators.required]],
            password: [null, [Validators.required]],
        });

        this.signUpForm = this.formBuilder.group(
        {
            username: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.minLength(6)]],
            confirmpassword: [null, [Validators.required, Validators.minLength(6)]]
        }, { validator: this.checkPasswords });

        this.forgotPassForm = this.formBuilder.group(
        {
            email: [null, [Validators.required, Validators.email]],
        });

        this.forgotPassForm.valueChanges.subscribe(() => {
            this.userNotFound = false;
        });

        this.signInForm.valueChanges.subscribe(() => {
            this.wrongpassword = false;
            this.userNotFound = false;
        });
        this.signUpForm.valueChanges.subscribe(() => {
            this.errorEmailAlreadyExist = false;
        });
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onAuthModalChange().subscribe(modalData =>
        {
            this.authModal = modalData;

            this.reset();
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }
    
    onCloseClick(): void
    {
        this.modalService.closeAuthModal();
    }
    
    onInsideClick(e: Event)
    {
        e.stopPropagation();
    }

    // Sign Up - S'inscrire
    onSignUp()
    {
        this.signUpSubmittedForm = true;

        if (this.signUpForm.valid)
        {
            this.authService.signUp(this.signUpForm.value.email, this.signUpForm.value.password, this.signUpForm.value.username).then(() =>
            {
                this.modalService.closeAuthModal();
                this.navigatorService.navigateToProfile();
            }).catch(err => {
                if (err.code === "auth/email-already-in-use")
                {
                    this.errorEmailAlreadyExist = true;
                }
            });
        }
    }
    
    checkPasswords(group: FormGroup)
    {
        const pass = group.controls['password'].value;
        const confirmPass = group.controls['confirmpassword'].value;

        return pass === confirmPass ? null : { notSame: true };
    }

    // Sign In - S'identifier
    onLoginStandard()
    {
        this.signInSubmittedForm = true;

        if (this.signInForm.valid)
        {
            this.authService.loginStandard(this.signInForm.value.email, this.signInForm.value.password).then(() =>
            {
                this.navigatorService.navigateToHome();
                this.modalService.closeAuthModal();
            }).catch(err => {
                if (err.code === "auth/wrong-password")
                {
                    this.wrongpassword = true;
                }
                else if (err.code === "auth/user-not-found")
                {
                    this.userNotFound = true;
                }
                else if (err.code === "auth/invalid-email")
                {
                    this.userNotFound = true;
                }
            });
        }
    }

    onLoginGoogle(): void
    {
        this.authService.loginGoogle().then(() =>
        {
            this.navigatorService.navigateToHome();
            this.modalService.closeAuthModal();
        }).catch(err => {
            console.log("oui", err);
        });
    }

    onLoginMicrosoft(): void
    {
        this.authService.loginMicrosoft().then(() =>
        {
            this.navigatorService.navigateToHome();
            this.modalService.closeAuthModal();
        }).catch(err => {
            console.log("oui", err);
        });
    }

    // ForgotPass
    public onForgotPass(): void
    {
        this.forgotSubmittedForm = true;

        if (this.forgotPassForm.valid)
        {
            this.authService.sendForgotPassMail(this.forgotPassForm.value.email).then((err: any) =>
            {
                this.sendForgotPassWithSuccess = true;
            }).catch((err: any) =>
            {
                if (err.code === "auth/user-not-found")
                {
                    this.userNotFound = true;
                }
                return Promise.resolve();
            });
        }
    }

    // Tabs
    public onTabSignInClick(): void
    {
        this.reset();
        this.authType = AuthType.SignIn;
    }

    public onTabSignUpClick(): void
    {
        this.reset();
        this.authType = AuthType.SignUp;
    }

    public onForgotPassClick(): void
    {
        this.reset();
        this.authType = AuthType.ForgotPass;
    }

    private reset()
    {
        this.authType = AuthType.SignIn;

        this.signInForm.reset();
        this.signUpForm.reset();
        this.forgotPassForm.reset();

        this.signInSubmittedForm = false;
        this.signUpSubmittedForm = false;
        this.forgotSubmittedForm = false;

        this.wrongpassword = false;
        this.userNotFound = false;
        this.sendForgotPassWithSuccess = false;
        this.errorEmailAlreadyExist = false;
    }
}
