import { ApiService } from '../../services/api.service';
import { ModalService } from '../../services/modal.service';
import { ProfileService } from '../../services/profile.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip-barcode-request',
  templateUrl: './chip-barcode-request.component.html',
  styleUrls: ['./chip-barcode-request.component.scss']
})
export class ChipBarcodeRequestComponent implements OnInit
{
    @Input() ean: string | null = null;

    hasBeenRequest: boolean = false;

    constructor(private apiService: ApiService, private profileService: ProfileService, private modalService: ModalService) 
    { 
        
    }

    ngOnInit(): void
    {
    }
  
    onRequestClick(): void
    {
        if (this.profileService.HasProfileConnected)
        {
            if (this.ean)
            {
                this.hasBeenRequest = true;
                this.apiService.requestProduct(this.ean).subscribe();
            }
        }
        else
        {
            this.modalService.openAuthModal();
        }
    }
}
