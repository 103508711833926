
import { Injectable } from '@angular/core';

import {
    GoogleAuthProvider, getAuth, signInWithPopup, OAuthProvider,
    EmailAuthCredential, EmailAuthProvider, signInWithCredential,
    createUserWithEmailAndPassword, sendPasswordResetEmail, updateProfile, User, updatePassword
} from 'firebase/auth';
import { I18nService } from './i18n.service';
import { ProfileService } from './profile.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public onProfileChange(): BehaviorSubject<boolean> {
        return this.profileSubject;
    }
    public get user(): User | null {
        return getAuth().currentUser;
    }
    public get isLogin(): boolean {
        return getAuth().currentUser !== null;
    }

    token: string | null = null;

    profileSubject = new BehaviorSubject<boolean>(false);

    constructor(private i18nService: I18nService, private profileService: ProfileService) {
    }

    // Retourne si un utilisateur est connecté ou non et ajoute le profile si il l'est
    public async getCurrentUserWithProfile(forceRefresh: boolean = false): Promise<boolean> {
        const user = await this.getCurrentUser(forceRefresh);
        if (user) {
            const isResolved = await this.profileService.connectProfile();
            if (!isResolved) {
                await this.signOut();
            }
            return true;
        } else {
            return false;
        }
    }    

    private async getCurrentUser(forceRefresh: boolean = false): Promise<User | null> {
        try {
            const user = await new Promise<User | null>((resolve, reject) => {
                const unsubscribe = getAuth().onAuthStateChanged(async user => {
                    unsubscribe(); // Unsubscribe immediately once the user state is received
                    if (user) {
                        try {
                            const tokenres = await user.getIdTokenResult(forceRefresh);
                            this.token = tokenres.token;
                            resolve(user);
                        } catch (err) {
                            console.error("Error in getCurrentUser:", err);
                            reject(err);
                        }
                    } else {
                        this.token = null;
                        resolve(null);
                    }
                }, reject);
            });
            return user;
        } catch (err) {
            console.error("Error in getCurrentUser:", err);
            throw err; // Rethrow the error after logging
        }
    }     

    public async loginStandard(email: string, password: string): Promise<void> {
        try {
            const auth = getAuth();
            const credential: EmailAuthCredential = EmailAuthProvider.credential(email, password);
            
            await signInWithCredential(auth, credential);
            await this.getCurrentUserWithProfile();
            this.profileSubject.next(true);
        } catch (err) {
            console.error("Error in loginStandard:", err);
            throw err; // Or handle it as per your error handling strategy
        }
    }    

    public async loginGoogle(): Promise<void> {
        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({ prompt: "select_account", language: this.i18nService.Language });

            const auth = getAuth();
            auth.languageCode = this.i18nService.Language;
    
            await signInWithPopup(auth, provider);
            await this.getCurrentUserWithProfile();
            this.profileSubject.next(true);
        } catch (err) {
            console.error("Error in loginGoogle:", err);
            throw err; 
        }
    }    

    public async loginMicrosoft(): Promise<void> {
        try {
            const provider = new OAuthProvider("microsoft.com");
            provider.setCustomParameters({ prompt: "select_account", language: this.i18nService.Language });
    
            const auth = getAuth();
            auth.languageCode = this.i18nService.Language;

            await signInWithPopup(auth, provider);
            await this.getCurrentUserWithProfile();
            this.profileSubject.next(true);
        } catch (err) {
            console.error("Error in loginMicrosoft:", err);
            throw err; 
        }
    }    

    public async signUp(email: string, password: string, username: string): Promise<void> {
        try {
            const auth = getAuth();
            const resultUser = await createUserWithEmailAndPassword(auth, email, password);
            await updateProfile(resultUser.user, { displayName: username });
    
            // Appel getCurrentUser pour attendre le token d'authentification avant le createprofile
            await this.getCurrentUser();
            await this.profileService.createProfile();
        } catch (err) {
            console.error("Error in signUp:", err);
            throw err;
        }
    }    

    public signOut(): Promise<void> {
        this.profileService.disconnectProfile();
        
        this.token = null;
        this.profileSubject.next(false);

        return getAuth().signOut();
    }

    public changePassword(newPassword: string): Promise<void> {
        return updatePassword(getAuth().currentUser!, newPassword);
    }

    public async deleteAccount(): Promise<void> {
        try {
            await this.profileService.deleteProfile();
            await getAuth().currentUser!.delete();
        } catch (err) {
            console.error("Error in deleteAccount:", err);
            throw err; // Or handle it as per your error handling strategy
        }
    }    

    public sendForgotPassMail(email: string): Promise<void> {
        const auth = getAuth();
        return sendPasswordResetEmail(auth, email);
    }
}