import { I18nService } from "../services/i18n.service";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class I18nResolver {
    constructor(private i18nService: I18nService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const locale: string | null = route.paramMap.get('lgcn') || this.i18nService.FALLBACK_LOCALE;
    
        return this.i18nService.loadLanguage(locale).pipe(
            map(result => {
                if (result) {
                    return true;
                } else {
                    console.error('Failed to load language:', locale);
                    return false;
                }
            }),
            catchError(error => {
                console.error('Error in I18nResolver:', error);
                if (locale !== this.i18nService.FALLBACK_LOCALE) {
                    return this.i18nService.loadLanguage(this.i18nService.FALLBACK_LOCALE);
                } else {
                    return of(false);
                }
            })
        );
    }    
}
