
import { L0_Definition, L1_Definition, LX_Definition } from '../../interfaces/Serialization/PagesDefinition';
import { NavigatorService } from '../../services/navigator.service';
import { ProductService } from '../../services/product.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss']
})
export class SubnavComponent implements OnInit, OnChanges
{
    @Input() origin: string = 'Home';
    @Input() isTitleMode: boolean = false;
    @Input() title: string = '';
    @Input() isSearchMode: boolean = false;
    @Input() searchInputUser: string = '';
    @Input() L0_Array: L0_Definition[] | undefined;
    @Input() LX_Array: L1_Definition[] | undefined;
    @Input() useL0Chip: boolean = false;
    @Input() useLXChip: boolean = false;

    constructor(public productService: ProductService, private navigatorService: NavigatorService, private router: Router)
    {
    }

    ngOnChanges(changes: SimpleChanges): void
    {
    }
    
    ngOnInit(): void
    {
    }

    onHomeClicked(): void
    {
        if (this.origin == 'Home')
        {
            this.navigatorService.navigateToHome();
        }
        else if (this.origin == "Messages")
        {
            this.navigatorService.navigateToMessages();
        }
    }

    onL0Clicked(LX: LX_Definition): void
    {
        if (this.origin == 'Home')
        {
            this.navigatorService.navigateToHome_ByCategories(LX.Id, "all", "all");
        }
        else if (this.origin == "Messages")
        {
            this.navigatorService.navigateToMessages_ByCategories(LX.Id, "all");
        }
    }

    onLXClicked(LX: LX_Definition): void
    {
        if (this.origin == 'Home')
        {
            this.navigatorService.navigateToHome_ByCategories(this.productService.L0.Id, LX.Id, "all");
        }
        else if (this.origin == "Messages")
        {
            this.navigatorService.navigateToMessages_ByCategories(this.productService.L0.Id, LX.Id);
        }
    }
}
