import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { Route } from './interfaces/route';

import { ModalResolver } from './resolvers/modal.resolve';
import { I18nResolver } from './resolvers/i18n.resolve';

import { AboutComponent } from './pages/about/about.component';
import { CategoryComponent } from './pages/category/category.component';
import { DealsComponent } from './pages/deals/deals.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PlanSuccessComponent } from './pages/plan-success/plan-success.component';
import { ProductComponent } from './pages/product/product.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TvComponent } from './pages/tv/tv.component';
import { RegionNotOpenComponent } from './pages/region-not-open/region-not-open.component';
import { ThankYouComponent } from './pages/thanks/thanks.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { InfoComponent } from './pages/info/info.component';
import { B2bComponent } from './pages/b2b/b2b.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CookieComponent } from './pages/cookie/cookie.component';
import { DemoComponent } from './pages/demo/demo.component';
import { HelpComponent } from './pages/help/help.component';
import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { SeeyouComponent } from './pages/seeyou/seeyou.component';
import { FeaturesComponent } from './pages/features/features.component';
import { ContactComponent } from './pages/contact/contact.component';
import { VerifyUserLoginMandatoryGuard } from './guards/vulm.guards';
import { VerifyUserLoginOptionalGuard } from './guards/vulo.guards';

const routes: Routes = [
    
    { path: '' , component: NavigatorComponent, pathMatch: 'full' },
    { path: ":lgcn", component: NavigatorComponent, children: [
            { path: '', redirectTo: Route.Home, pathMatch: 'full' },

            { path: Route.Demo, component: DemoComponent, resolve: { mod: ModalResolver } },

            { path: Route.Home, component: DealsComponent, canActivate: [VerifyUserLoginOptionalGuard], resolve: { mod: ModalResolver } },
            { path: Route.Messages, component: MessagesComponent, canActivate: [VerifyUserLoginMandatoryGuard], resolve: { mod: ModalResolver } },
            { path: Route.Apps, component: TvComponent, resolve: { mod: ModalResolver } },
            { path: Route.About, component: AboutComponent, resolve: { mod: ModalResolver } },
            { path: Route.Product + "/:productname/:productid", component: ProductComponent, resolve: { mod: ModalResolver } },
            { path: Route.Category + "/:L0/:L1/:L3", component: CategoryComponent, resolve: { mod: ModalResolver } },
            { path: Route.PlanSuccess, component: PlanSuccessComponent, canActivate: [VerifyUserLoginMandatoryGuard],resolve: { mod: ModalResolver } },
            
            { path: Route.Profile, component: ProfileComponent, canActivate: [VerifyUserLoginOptionalGuard], resolve: { mod: ModalResolver } },
            { path: Route.PageNotFound, component: PageNotFoundComponent, resolve: { mod: ModalResolver } },
            { path: Route.RegionNotOpen, component: RegionNotOpenComponent, resolve: { mod: ModalResolver } },
            { path: Route.ThankYou, component: ThankYouComponent, resolve: { mod: ModalResolver } },
            { path: Route.Affiliate, component: AffiliateComponent, resolve: { mod: ModalResolver } },
            { path: Route.Terms, component: TermsComponent, resolve: { mod: ModalResolver } },
            { path: Route.Cookie, component: CookieComponent, resolve: { mod: ModalResolver } },
            { path: Route.Privacy, component: PrivacyComponent, resolve: { mod: ModalResolver } },
            { path: Route.Pricing, component: PricingComponent, resolve: { mod: ModalResolver } },
            { path: Route.Info, component: InfoComponent, resolve: { mod: ModalResolver } },
            { path: Route.B2b, component: B2bComponent, resolve: { mod: ModalResolver } },
            { path: Route.Help, component: HelpComponent, resolve: { mod: ModalResolver } },
            { path: Route.Seeyou, component: SeeyouComponent, resolve: { mod: ModalResolver } },
            { path: Route.Features, component: FeaturesComponent, resolve: { mod: ModalResolver } },
            { path: Route.Contact, component: ContactComponent, resolve: { mod: ModalResolver } },
            
            { path: '**' , component: PageNotFoundComponent, pathMatch: 'full' },
        ],
        resolve: { i18n: I18nResolver }
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
