<div class="container">
    <!--
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="">
                <div class="typo-footer-bold mb-2">Prems</div>
                Track prices and never overpay again!<br>
                Be the first to know about the new deals, stock alerts and price drops.
            </div>
        </div>

        <div class="col-12 col-lg-6">
            <div class="d-flex flex-column align-items-end typo-footer-bold">
                <div class="mb-2">Follow us on social</div>
                <div>
                    <a class="mx-1" href="https://www.instagram.com/Prems/"><img class="picto-big" src="../../../assets/images/231116/instagram.png" /></a>
                    <a class="mx-1" href="https://www.facebook.com/Secret-Beauty-Deals-103747345369265"><img class="picto-big" src="../../../assets/images/231116/facebook.png" /></a>
                    <a class="mx-1" href="https://twitter.com/secretdeals_ca"><img class="picto-big" src="../../../assets/images/231116/twitter.png" /></a>
                    <a class="mx-1" href="https://www.pinterest.ca/Prems/"><img class="picto-big" src="../../../assets/images/231116/pinterest.png" /></a>
                </div>
            </div>
        </div>
    </div>
    -->

    <!--
    <div class="row">
        <div class="col">
            <hr>
            <div class="typo-disclaimer">* All prices are in CAD - Canadian Dollars excluding taxes. Shipping costs are not included. All prices, rankings, delivery times and shipping costs may be subject to interim changes.</div>
            <div class="typo-disclaimer">** Save = Saving compared to the highest price over the last 24 hours.</div>
            <hr>
        </div>
    </div>
    -->
    <!-- TrustBox widget - Review Collector -->
    <div class="trustpilot-widget" [attr.data-locale]="getLocale()" data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="65202dfae7656131fde6bf5f" data-style-height="52px" data-style-width="100%">
        <a href="https://www.trustpilot.com/review/prems.ai" target="_blank" rel="noopener">Trustpilot</a>
    </div>
    <!-- End TrustBox widget -->
    <br>

    <div class="row align-items-center">
        <!--
        <div class="col-1">
            <div class="d-flex align-items-center">
                <img class="picto-big" src="../../../assets/images/231116/Logo.png" [routerLink]="['/Deals']" />
            </div>
        </div>
        -->

        <div class="col-5">
            <div class="typo-disclaimer">
                Copyright ©2024 Prems Ai. All rights reserved. All other trademarks are the property of their respective
                owners.
            </div>
        </div>

        <div class="col-7">
            <div class="d-flex flex-column align-items-center align-items-md-end justify-content-end">
                <div class="d-flex justify-content-end text-center">
                    <a class="typo-disclaimer-link" href="https://prems.ai/en-us/terms/">Terms</a>
                    <a class="typo-disclaimer-link mx-4" href="https://prems.ai/en-us/cookie/">Cookie</a>
                    <a class="typo-disclaimer-link" href="https://prems.ai/en-us/privacy/">Privacy</a>
                </div>
                <div class="typo-disclaimer mt-2">
                    By accessing and using this page you agree to the Terms and Conditions.
                </div>
            </div>
        </div>
    </div>
</div>