import { Component, Input, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { I18nService } from '../../services/i18n.service';
import { NavigatorService } from '../../services/navigator.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})

export class RatingComponent implements AfterViewInit {
  @Input() labelRating: string = "pleaseRate";
  @Input() overrideStars: number = -1;

  nbStarHover: number = -1;

  constructor(public i18nService: I18nService, private navigatorService: NavigatorService, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.nbStarHover = this.overrideStars;
  }

  ngAfterViewInit(): void {
    this.nbStarHover = this.overrideStars;
    this.cd.detectChanges();
  }

  onRatingHoverEnter(idRate: number) {
    if (this.overrideStars === -1) this.nbStarHover = idRate;
  }

  onRatingHoverExit(idRate: number) {
    if (this.overrideStars === -1) this.nbStarHover = -1;
  }

  onRateClick(idRate: number) {
    if (this.overrideStars === -1) {
      if (idRate < 4) {
        this.navigatorService.navigateToReview(idRate + 1);
      }
      else {
        let newTab = window.open(`${environment.web_store_url["chrome"]}`, '_blank');
        if (newTab) {
          newTab.opener = null;
        }
      }
    }
  }
}
