import { Component } from '@angular/core';
import { ExtensionService } from '../../services/extension.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss']
})
export class HeroComponent {
    useOurExtension: boolean = false;

    browserData: { name: string, url: string } | undefined;

    constructor(private extensionService: ExtensionService) {
        this.extensionService.isPluginInstalled().then(() => {
            this.useOurExtension = true;
        }).catch(err => {});
        if (environment.env_type === "dev" || environment.env_type === "dev_local")
        {
            //this.useOurExtension = true;
        }
        this.browserData = this.getBrowserData();
    }

    getBrowserData(): { name: string, url: string } | undefined {
        let userAgent = navigator.userAgent;

        if (userAgent.match(/opr\//i)) {
            //return { name: "opera", url: environment.web_store_url["opera"] };
            return undefined;
        }
        else if (userAgent.match(/firefox/i)) {
            //return { name: "firefox", url: environment.web_store_url["firefox"] };
            return undefined;
        }
        else if (userAgent.match(/edg/i)) {
            return { name: "edge", url: environment.web_store_url["edge"] };
        }
        else if (userAgent.match(/chrome/i)) {
            return { name: "chrome", url: environment.web_store_url["chrome"] };
        }
        // Keep Safari at the end ! It is everywhere.
        else if (userAgent.match(/safari/i)) {
            //return { name: "safari", url: environment.web_store_url["safari"] };
            return undefined;
        }
        else {
            return undefined;
        }
    }
}
