
import { L0_Definition } from '../../interfaces/Serialization/PagesDefinition';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { I18nService } from '../../services/i18n.service';
import { ModalService } from '../../services/modal.service';
import { NavigatorService } from '../../services/navigator.service';
import { ProfileService } from '../../services/profile.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DarkModeService } from '../../services/dark-mode.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    L0_Array: L0_Definition[] | undefined;

    isHeaderMobile: boolean = false;
    isHeaderMobileOpen: boolean = false;

    isCategoriesExpanded: boolean = false;
    isLanguagesExpanded: boolean = false;
    isRegionsExpanded: boolean = false;
    isInfoAboutUsExpanded: boolean = false;
    isInfoLegalExpanded: boolean = false;

    activeRouteUrl: string | undefined;

    lang_Array: string[];
    region_Array: string[];

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isHeaderMobileOpen = false;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkHeaderSize();
    }

    constructor(public authService: AuthService, private router: Router, public i18nService: I18nService, private modalService: ModalService,
        private eRef: ElementRef, private apiService: ApiService, public profileService: ProfileService, private navigatorService: NavigatorService,
        private darkModeService: DarkModeService) {
        this.lang_Array = environment.languages;
        this.region_Array = environment.regionMenu;

        // S'abonne a la route pour détecter les changements de route
        this.router.events.subscribe((val) => {
            this.activeRouteUrl = this.router.url;
        });
    }

    ngOnInit(): void {
        this.L0_Array = this.apiService.PagesDefinition?.Pages.find(board => board.Id === "Deals")?.L0;

        this.checkHeaderSize();
    }

    checkHeaderSize(): void {
        this.isHeaderMobile = window.innerWidth < 768;
    }

    toggleMenu(): void {
        this.isHeaderMobileOpen = !this.isHeaderMobileOpen;
    }

    toggleDarkMode() {
        this.darkModeService.toggleDarkMode();
    }

    scanBarCode(): void {
        this.modalService.openSearchModal();
    }

    onDealsClick(): void {
        this.resetPage();

        this.navigatorService.navigateToHome();
    }

    onMessagesClick(): void {
        this.resetPage();

        this.navigatorService.navigateToMessages();
    }

    onSearchClick(): void {
        this.modalService.openSearchModal();
    }

    onTVClick(): void {
        this.resetPage();

        this.navigatorService.navigateToTV();
    }

    onProfileClick(): void {
        this.resetPage();

        this.navigatorService.navigateToProfile();
    }

    onNotificationClick(): void {
        this.resetPage();

        this.navigatorService.navigateToMessages();
    }

    onCategoriesClick() {
        this.isCategoriesExpanded = !this.isCategoriesExpanded;
        this.isLanguagesExpanded = false;
        this.isRegionsExpanded = false;
        this.isInfoAboutUsExpanded = false;
        this.isInfoLegalExpanded = false;
    }

    onLanguagesClick() {
        this.isLanguagesExpanded = !this.isLanguagesExpanded;
        this.isCategoriesExpanded = false;
        this.isRegionsExpanded = false;
        this.isInfoAboutUsExpanded = false;
        this.isInfoLegalExpanded = false;
    }

    onRegionsClick() {
        this.isRegionsExpanded = !this.isRegionsExpanded;
        this.isCategoriesExpanded = false;
        this.isLanguagesExpanded = false;
        this.isInfoAboutUsExpanded = false;
        this.isInfoLegalExpanded = false;
    }

    onInfoAboutUsClick() {
        this.isInfoAboutUsExpanded = !this.isInfoAboutUsExpanded;
        this.isCategoriesExpanded = false;
        this.isLanguagesExpanded = false;
        this.isRegionsExpanded = false;
        this.isInfoLegalExpanded = false;
    }

    isInfoLegalClick() {
        this.isInfoLegalExpanded = !this.isInfoLegalExpanded;
        this.isCategoriesExpanded = false;
        this.isLanguagesExpanded = false;
        this.isRegionsExpanded = false;
        this.isInfoAboutUsExpanded = false;
    }

    onCategoryClick(L0: L0_Definition) {
        this.resetPage();

        this.navigatorService.navigateToHome_ByCategories(L0.Id, "all", "all");
    }

    onLanguageClick(newLang: string) {
        if (!environment.languages.includes(newLang)) {
            newLang = 'en';  // Default to 'en' if newLang is not in lang_Array
        }

        // Split the current URL by '/'
        const segments = this.router.url.split('/');

        // Find the segment matching the pattern of language-territory and replace the language part
        for (let i = 0; i < segments.length; i++) {
            if (environment.languages.some(lang => segments[i].startsWith(lang))) {
                const territory = segments[i].split('-')[1];
                segments[i] = `${newLang}-${territory}`;
                break;
            }
        }

        // Construct the new path
        const newPath = segments.join('/');

        // Navigate to the new path
        this.router.navigateByUrl(newPath);
    }


    onRegionClick(region: string) {
        this.resetPage();
        this.navigatorService.navigateToHome_ByRegions(region);
    }

    onAuthClick(): void {

        this.modalService.openAuthModal();
    }

    getPhotoProfile(): string {
        if (this.authService.isLogin && this.authService.user) {
            if (this.authService.user.photoURL) {
                return this.authService.user.photoURL;
            }

            if (this.profileService.Plan === "Elite") return "../../../assets/images/231116/pictos/Profile_Elite.svg";
            else if (this.profileService.Plan === "Prime") return "../../../assets/images/231116/pictos/Profile_Prime.svg";
            else return "../../../assets/images/231116/pictos/Profile_Free.svg";
        }
        else {
            return "../../../assets/images/231116/pictos/Profile.svg";
        }
    }

    logout() {
        this.authService.signOut().then(() => {
            this.navigatorService.navigateToHome();
        })
    }

    onAboutClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/about`);
    }

    onAffiliatesClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/affiliates`);
    }

    onPricingClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/pricing`);
    }

    onHelpClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/help`);
    }

    onInfoClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/services`);
    }

    onFeaturesClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/features`);
    }

    onContactClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/contact`);
    }

    onTermClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/terms`);
    }

    onCookieClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/cookie`);
    }

    onPrivacyClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/privacy`);
    }

    onRoadmapClick(): void {
        const { Language } = this.i18nService;
        let redirectUrl = 'https://feedback.prems.ai/?ref=prems.ai&via=prems';
        if (Language !== 'en') {
            redirectUrl = `https://translate.google.com/translate?sl=en&hl=${Language}&u=` + redirectUrl;
        }
        window.open(redirectUrl, "_blank");
    }

    private resetPage(): void {
        this.isHeaderMobileOpen = false;
    }
}
