import { ProductModal } from '../../interfaces/modal';
import { ResultDefinition, OfferDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ModalService } from '../../services/modal.service';
import { CardService } from '../../services/card.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { NavigatorService } from '../../services/navigator.service';
import { LDJsonService } from '../../services/ldjson.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
    selector: 'modal-product',
    templateUrl: './modal-product.component.html',
    styleUrls: ['./modal-product.component.scss']
})
export class ModalProductComponent implements OnInit, OnDestroy
{
    @ViewChild('scrollable') scrollable: ElementRef | undefined; 
    
    private modalSubscription: Subscription | undefined;

    productModal: ProductModal | undefined;
    card: ResultDefinition | null = null;

    similarCards: ResultDefinition[] | null = null;
    mayLikeCards: ResultDefinition[] | null = null;
    
    labelsPriceHistory: Array<string> = new Array<string>();
    dataPriceHistory: Array<number> = new Array<number>();
    marketPriceHistory: Array<number> = new Array<number>();
    dataPricePredict: Array<number> = new Array<number>();
    currencySymbol: string = "";
    wstd: number = 0;
    wstdp: number = 0;
  
    isTubeCard: boolean = false;

    constructor(public authService: AuthService, public profileService: ProfileService, public cardService: CardService, private navigatorService: NavigatorService, 
                private modalService: ModalService, private apiService: ApiService, private ldJsonService: LDJsonService)
    {
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onCardModalChange().subscribe(modalData =>
        {
            this.productModal = modalData;
            this.card = modalData.card;

            // Reinitialise les cartes si on réouvre la popup
            this.similarCards = [];
            this.mayLikeCards = [];

            // Scroll au début si on vient de cette popup (similar et mayalsolike)
            if (this.scrollable)
            {
                this.scrollable.nativeElement.scrollTo(0, 0);
            }
            
            if (this.card && this.card.Product?.CreatorName && this.card.Product?.CreatorName !== "")
            {
                this.isTubeCard = true;
            }
            else
            {
                this.isTubeCard = false;
            }

            if (this.card && this.card.Product)
            {
                this.ldJsonService.setTmpLdJsonCard(this.card);
                
                this.cardService.getPriceHistory(this.card);
                
                if (this.card.Product.Tags.L3)
                {
                    this.apiService.getSimilarProduct(this.card.Product.Tags.L3).subscribe((result) =>
                    {
                        // Supprime la carte qui peut etre identique et limite au 4 premières cartes
                        this.similarCards = result.results.filter(c => c.Product.Id !== this.card?.Product.Id).slice(0, 3);
                    });
                }

                if (this.card.Product.BrandName)
                {
                    this.apiService.getMayLikeProduct(this.card.Product.BrandName, this.card.Product.Tags.L3).subscribe((result) =>
                    {
                        // Supprime la carte qui peut etre identique et limite au 4 premières cartes
                        this.mayLikeCards = result.results.filter(c => c.Product.Id !== this.card?.Product.Id).slice(0, 3);
                    });
                }
            }
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    onOpenOffer(offerDef: OfferDefinition)
    {
        if(this.card) this.cardService.openOfferUrl(offerDef);
    }
    
    onCloseClick(): void
    {
        this.ldJsonService.restoreLdJsonCards();

        this.card = null;
        this.modalService.closeCardModal();
    }

    onInsideClick(e: Event)
    {
        e.stopPropagation();
    }

    goProfile(): void
    {
        this.navigatorService.navigateToProfile();
        this.onCloseClick();
    }

    goPrime(): void
    {
        this.navigatorService.navigateToProfile();
        this.onCloseClick();
    }

    goElite(): void
    {
        this.navigatorService.navigateToProfile();
        this.onCloseClick();
    }

    onSeeAllSimilar(): void
    {
        if (this.card && this.card.Product && this.card.Product.Tags.L3)
        {
            this.navigatorService.navigateToHome_ByCategories(this.card.Product.Tags.L0, this.card.Product.Tags.L1, this.card.Product.Tags.L3);
            this.onCloseClick();
        }
    }

    onSeeAllYouMayLike(): void
    {
    }
}
