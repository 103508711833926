import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { CardService } from '../../services/card.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'market-level',
  templateUrl: './market-level.component.html',
  styleUrls: ['./market-level.component.scss']
})
export class MarketLevelComponent implements OnInit
{
    @Input() card: ResultDefinition | null = null;

    constructor(public cardService: CardService)
    {
    }
    
    ngOnInit(): void
    {
    }
}
