import { ProductService } from '../../services/product.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit
{
    @Input() useLuxuryButton: boolean = false;
    
    viewSelected: number = 0;

    constructor(public productService: ProductService)
    {
    }

    ngOnInit(): void
    {
    }

    onViewChanged(viewTarget: any): void
    {
        if (viewTarget.target.value)
        {
            const viewId: number = parseInt(viewTarget.target.value);
            this.productService.ViewId = viewId;
            this.productService.reload();
        }
    }

    onReloadClicked(): void
    {
        this.productService.reload();
    }

    onTopDealsChanged(): void
    {
        this.productService.TopDealsOnly = !this.productService.TopDealsOnly;
        this.productService.reload();
    }

    onLuxuryClicked(): void
    {
        this.productService.TopDealsOnly = !this.productService.TopDealsOnly;
        this.productService.reload();
    }
}
