import { Component } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent {
  locale: string;
  videoUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, public i18nService: I18nService, private router: Router) {
    // Extract the locale from the URL
    const urlSegments = this.router.url.split('/');
    if (urlSegments && urlSegments.length > 1) {
      this.locale = urlSegments[1];
    } else {
      this.locale = 'en-us';  // default locale
    }

    const youtubeVideoId: string = this.i18nService.getText("wap1_p_service_YTlink");
    const url: string = `https://www.youtube.com/embed/${youtubeVideoId}`;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

  }

  getImagePath(originalLink: string): string {
    // Extract the file extension
    const fileExtension = originalLink.split('.').pop();

    // Extract the base part of the link without the _en suffix and file extension
    const baseLinkWithoutLocale = originalLink.substring(0, originalLink.lastIndexOf('_en'));

    // Determine the locale suffix
    let imageLocale = 'en';  // default locale
    const detectedLocale = this.locale.substring(0, 2).toLowerCase(); // get the first two characters and make them uppercase
    if (environment.languages.includes(detectedLocale)) {
      imageLocale = detectedLocale;
    }

    // Construct the locale-specific link with original file extension
    const localeSpecificLink = `${baseLinkWithoutLocale}_${imageLocale}.${fileExtension}`;

    return localeSpecificLink;
  }

  onJoinClick(): void {
    const { Locale } = this.i18nService;
    this.router.navigateByUrl(`/${Locale}/deals;auth=true`);
  }
  
  onFeatureClick(featureID: string): void {
    const { Locale } = this.i18nService;
    this.router.navigateByUrl(`/${Locale}/features#${featureID}`);
  }
}
