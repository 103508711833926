import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-b2b',
  templateUrl: './b2b.component.html',
  styleUrls: ['./b2b.component.scss']
})
export class B2bComponent {
  locale: string;

  constructor(private router: Router) {
    // Extract the locale from the URL
    const urlSegments = this.router.url.split('/');
    if (urlSegments && urlSegments.length > 1) {
      this.locale = urlSegments[1];
    } else {
      this.locale = 'en-us';  // default locale
    }
  }
  
  getImagePath(originalLink: string): string {
    // Extract the file extension
    const fileExtension = originalLink.split('.').pop();
  
    // Extract the base part of the link without the _en suffix and file extension
    const baseLinkWithoutLocale = originalLink.substring(0, originalLink.lastIndexOf('_en'));
  
    // Determine the locale suffix
    let imageLocale = 'en';  // default locale
    const detectedLocale = this.locale.substring(0, 2).toLowerCase(); // get the first two characters and make them uppercase
    if (environment.languages.includes(detectedLocale)) {
      imageLocale = detectedLocale;
    }
    
    // Construct the locale-specific link with original file extension
    const localeSpecificLink = `${baseLinkWithoutLocale}_${imageLocale}.${fileExtension}`;
    
    return localeSpecificLink;
  }
}