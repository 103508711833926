<header></header>

<div class="overflow-y-scroll" (scroll)="onScroll($event)">

    <hero></hero>
    <subnav origin="Messages" [L0_Array]="L0_Array" [LX_Array]="L3_Array" [useL0Chip]="productService.L0.Id === 'all'" [useLXChip]="productService.L0.Id !== 'all'"></subnav>
    
    <div *ngIf="productService.IsLoading" class="spinner text-light d-flex justify-content-center align-items-center p-3">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    
    <div *ngIf="!productService.IsLoading && productService.cardsLoaded.length === 0">
        <div class="container px-4">    
            <div class="row my-5">
                <div class="col-12 col-md-5">
                    <div id="feature1" class="markdown-container p-3"
                        [innerHTML]="'wap1_c_messages_noWatch' | translate | safeMark">
                    </div>
                </div>
    
                <div class="col-12 col-md-7">
                    <video class="w-100 rounded darker-shadow" controls
                        [poster]="getImagePath('../../../assets/images/231116/static/prems_3_en.jpg')">
                        <source src="../../../external/videos/featureTrack_en.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>

                <br>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onJoinClick()">{{ 'wap1_p_info_joinNow' | translate}}</btn-standard>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid px-4">
        <div class="row">
            
            <div class="col-12 col-sm-6 col-xl-3 my-3" *ngFor="let card of productService.cardsLoaded">
                <card *ngIf="!card.Product?.CreatorName" [card]="card"></card>
				<card-tube *ngIf="card.Product?.CreatorName" [card]="card"></card-tube>
            </div>
    
        </div>
    </div>
    
</div>







