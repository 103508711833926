
<div *ngIf="card">

    <ng-container *ngIf="!card.Product.HasPriceHistory">

        <div class="d-flex-row rect-rounded notavailable typo-flag align-items-center justify-content-between px-2 py-1" 
            [ngClass]="{ 'notavailable': card.Product.TotalAvailability === 0 }"
            [ngStyle]="{ 'border-color': 'slategray', 'color': 'slategray' }">
            <div>{{ 'slategray' | translate }}</div>

            <div *ngIf="cardService.getBestPrice(card) as price" class="d-flex-row">
                <div class="typo-currency ms-2 me-1" *ngIf="card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
                <div class="typo-price">{{ price | i18nPrice }}</div>
                <div class="typo-currency ms-1" *ngIf="!card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
            </div>

            <div *ngIf="!cardService.getBestPrice(card)" class="d-flex-row">
                <div class="typo-currency ms-2 me-1" *ngIf="card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
                <div class="typo-price">-</div>
                <div class="typo-currency ms-1" *ngIf="!card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="card.Product.HasPriceHistory">

        <div class="d-flex-row rect-rounded notavailable typo-flag align-items-center justify-content-between px-2 py-1"
            [ngClass]="{ 'notavailable': card.Product.TotalAvailability === 0 }"
            [ngStyle]="{ 'border-color': card.Product.PriceDropLowestOfferFlag, 'color': card.Product.PriceDropLowestOfferFlag }">
            
            <div>{{ card.Product.PriceDropLowestOfferFlag | translate }}</div>
            
            <div *ngIf="cardService.getBestPrice(card) as price" class="d-flex-row">
                <div class="typo-currency ms-2 me-1" *ngIf="card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
                <div class="typo-price">{{ price | i18nPrice }}</div>
                <div class="typo-currency ms-1" *ngIf="!card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
            </div>

            <div *ngIf="!cardService.getBestPrice(card)" class="d-flex-row">
                <div class="typo-currency ms-2 me-1" *ngIf="card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
                <div class="typo-price">-</div>
                <div class="typo-currency ms-1" *ngIf="!card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
                
            </div>
            
        </div>
        
        <div class="d-flex-row margin-caret">
            <div class="rect grow-1 bg-color-blue">
                <svg *ngIf="card.Product.PriceDropLowestOfferFlag === 'blue'" xmlns="http://www.w3.org/2000/svg" width="40" height="40" [ngStyle]="{ 'fill': card.Product.PriceDropLowestOfferFlag }" class="caret-down" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            </div>
            <div class="rect grow-1 bg-color-green">
                <svg *ngIf="card.Product.PriceDropLowestOfferFlag === 'green'" xmlns="http://www.w3.org/2000/svg" width="40" height="40" [ngStyle]="{ 'fill': card.Product.PriceDropLowestOfferFlag }" class="caret-down" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            </div>
            <div class="rect grow-2 bg-color-orange">
                <svg *ngIf="card.Product.PriceDropLowestOfferFlag === 'orange'" xmlns="http://www.w3.org/2000/svg" width="30" height="15" [ngStyle]="{ 'fill': card.Product.PriceDropLowestOfferFlag }" class="caret-down" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>

                <div *ngIf="cardService.getPriceRedThreshold(card) as Threshold" class="d-flex-row price-highest typo-excess" >
                    <div class="typo-excess-small me-1" *ngIf="card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
                    <div class="typo-excess">{{ Threshold | i18nPrice }}</div>
                    <div class="typo-excess-small ms-1" *ngIf="!card.Product.CurrencyPositionLeft">{{ card.Product.CurrencySymbol }}</div>
                </div>
            </div>
            <div class="rect grow-2 bg-color-red position-relative">
                <svg *ngIf="card.Product.PriceDropLowestOfferFlag === 'red'" xmlns="http://www.w3.org/2000/svg" width="40" height="40" [ngStyle]="{ 'fill': card.Product.PriceDropLowestOfferFlag }" class="caret-down" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            </div>
        </div>

    </ng-container>

</div>