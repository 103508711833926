import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { CardService } from '../../services/card.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip-tube',
  templateUrl: './chip-tube.component.html',
  styleUrls: ['./chip-tube.component.scss']
})
export class ChipTubeComponent implements OnInit
{
    @Input() card: ResultDefinition | null = null;

    constructor(public cardService: CardService)
    {
    }

    ngOnInit(): void
    {
    }

    onTubeClick(): void
    {

    }
}
