import { Route } from '../interfaces/route';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService
{
    constructor(private router: Router, private i18nService: I18nService)
    {
    }

    public navigateToHome(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Home]); //, { queryParams: { refresh: new Date().getTime() } });
    }
    
    public navigateToMessages(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Messages]); //, { queryParams: { refresh: new Date().getTime() } });
    }
    
    public navigateToTV(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Apps]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToProfile(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Profile]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToSearch(search: string): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Home, { search: search }]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToHome_ByCategories(_L0: string, _L1: string, _L3: string): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Home, { L0: _L0, L1: _L1, L3: _L3 }]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToHome_ByLangs(lang: string): void
    {
        this.router.navigate([this.getRouteByLang(lang) + Route.Home]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToHome_ByRegions(region: string): void
    {
        this.router.navigate([this.getRouteByRegion(region) + Route.Home]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToMessages_ByCategories(_L0: string, _L3: string): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Messages, { L0: _L0, L3: _L3 }]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToLanguage(language: string): void
    {
        // Language forme : fr-FR
        this.router.navigate(["/" + language]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToPageNotFound(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.PageNotFound]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToPageRegionNotOpen(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.RegionNotOpen]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToPageThankYou(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.ThankYou]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToPageAffiliate(): void
    {
        this.router.navigate([this.getRouteLgcn() + Route.Affiliate]); //, { queryParams: { refresh: new Date().getTime() } });
    }

    public navigateToProduct(productId: string): void
    {
        this.router.navigateByUrl(this.getRouteLgcn() + Route.Home + ";product=" + productId);
    }

    public navigateToReview(idRate: number): void
    {
        this.router.navigateByUrl(this.getRouteLgcn() + Route.Home + ";review=" + idRate);
    }

    public navigateToContact(idRate: number): void
    {
        this.router.navigateByUrl(this.getRouteLgcn() + Route.Home + ";contact=" + idRate);
    }
    
    public getUrlFromShare(productName: string, productId: string): string
    {
        return encodeURI(environment.hostname + this.getRouteLgcn() + Route.Product + "/" + productName + "/" + productId);
    }

    private getRouteLgcn(): string
    {
        return "/" + this.i18nService.Locale + "/";
    }

    private getRouteByLang(lang: string): string
    {
        const region = this.i18nService.Locale.split("-")[1];
        return `/${lang}-${region}/`;
    }

    private getRouteByRegion(region: string): string
    {
        const lang = this.i18nService.Locale.split("-")[0];
        return `/${lang}-${region}/`;
    }
}
