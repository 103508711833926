
<div *ngIf="shareModal && card" (click)="onCloseClick()" class="modal position-fixed w-100 h-100 align-items-center justify-content-center" [ngClass]="{ 'active': shareModal.isModalOpen }">
    <div (click)="onInsideClick($event)" class="mod-dialog">

        <!-- Header-->
        <div class="mod-header bg-color-14 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="typo-modal-header">{{ 'wap1_m_share_share' | translate }}</div>
            <img class="mod-close mx-1" src="assets/images/231116/pictos/Close.svg" (click)="onCloseClick()"/>
        </div>
        
        <!-- Body -->
        <div class="mod-body d-flex justify-content-center align-items-center">

            <share-buttons [theme]="'default'"
                [include]="['facebook', 'twitter', 'messenger', 'whatsapp', 'email', 'copy']"
                [show]="6"
                [url]="newUrl"
                [autoSetMeta]="false">
            </share-buttons>

        </div>

    </div>
</div>