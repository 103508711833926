import { Route } from "../interfaces/route";
import { ResultDefinition } from "../interfaces/Serialization/ResultsDefinition";
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { environment } from "../../environments/environment";
import { CardService } from "./card.service";
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })
export class LDJsonService
{
    private currentScript: any;
    private currentRenderer: Renderer2 | null = null;

    private cardsToRestore: ResultDefinition[] = [];

    constructor(@Inject(DOCUMENT) private document: Document, private cardService: CardService, private i18nService: I18nService)
    {
    }

    public setLdJsonBase(renderer2: Renderer2, baseLdJson: object): void
    {
        this.document.getElementById("baseLdJson")?.remove();

        let baseScript = renderer2.createElement('script');
        baseScript.setAttribute("id", "baseLdJson");
        baseScript.type = 'application/ld+json';
        baseScript.text = `${JSON.stringify(baseLdJson)}`;

        renderer2.appendChild(this.document.head, baseScript);
    }

    public setLdJsonCards(renderer2: Renderer2, cards: ResultDefinition[], useRestore: boolean = true): void
    {
        if (this.currentScript)
        {
            renderer2.removeChild(this.document.head, this.currentScript);
        }

        var ldJsonProducts: { "@context": string; "@type": string; brand: { "@type": string; name: string; }; category: string; keywords: string[]; offers: { "@type": string; highPrice: string | number | undefined; lowPrice: string | number | undefined; priceCurrency: string; offerCount: number; offers: { "@type": string; itemCondition: string; price: number | undefined; priceCurrency: string; seller: { "@type": string; name: string; }; url: string; }[]; }; description: string; image: { "@type": string; url: string | undefined; }; name: string; url: string; }[] = [];

        cards.forEach(card =>
        {
            var keywords = [];
            if (card.Product.Tags?.L0 !== '') keywords.push(card.Product.Tags.L0);
            if (card.Product.Tags?.L1 !== '') keywords.push(card.Product.Tags.L1);
            if (card.Product.Tags?.L2 !== '') keywords.push(card.Product.Tags.L2);
            if (card.Product.Tags?.L3 !== '') keywords.push(card.Product.Tags.L3);

            var offers: { 
                "@type": string; itemCondition: string; price: number | undefined; priceCurrency: string; seller: { "@type": string; name: string; }; 
                url: string; }[] = [];

            var lowPrice = -1, highPrice = -1;
            card.MarketOffers.forEach(market =>
            {
                if (market.Price) lowPrice = Math.min(lowPrice, market.Price);
                if (market.Price) highPrice = Math.max(highPrice, market.Price);

                offers.push({
                    "@type": "Offer",
                    "itemCondition": "https://schema.org/NewCondition",
                    "price": market.Price,
                    "priceCurrency": "CAD",
                    "seller":{
                        "@type": "Organization",
                        "name": market.MarketPlaceName
                    },
                    "url": market.OfferUrls.length > 0 ? market.OfferUrls[0].Url : ""
                });
            });

            var product = {
                "@context": "http://schema.org",
                "@type": "Product",
                "brand": {
                    "@type": "Brand",
                    "name": card.Product.BrandName,
                },
                "category": card.Product.Tags.L3,
                "keywords": keywords,
                "offers": {
                    "@type": "AggregateOffer",
                    "highPrice": card.Product.PriceDropHighestOffer > 0 ? card.Product.PriceDropHighestOffer : "",
                    "lowPrice": card.Product.PriceDropLowestOffer > 0 ? card.Product.PriceDropLowestOffer : "",
                    "priceCurrency": "CAD",
                    "offerCount": card.MarketOffers.length,
                    "offers": offers
                },
                "description": card.Product.ShortDescription,
                "image": {
                    "@type": "ImageObject",
                    "url": this.cardService.getPrimeImageUrl(card),
                },
                "name": card.Product.Title,
                "url": environment.hostname + "/" + this.i18nService.Locale + "/" + Route.Home + ";product=" + card.Product.Id,
            };

            ldJsonProducts.push(product);
        });

        this.currentScript = renderer2.createElement('script');
        this.currentScript.type = 'application/ld+json';
        this.currentScript.text = `${JSON.stringify(ldJsonProducts)}`;

        renderer2.appendChild(this.document.head, this.currentScript);
        
        // To restore later if needed
        if (useRestore)
        {
            this.currentRenderer = renderer2;
            this.cardsToRestore = cards;
        }
    }
    
    public setTmpLdJsonCard(card: ResultDefinition): void
    {
        if (this.currentRenderer)
        {
            this.setLdJsonCards(this.currentRenderer, [card], false);
        }
    }

    public restoreLdJsonCards(): void
    {
        if (this.currentRenderer)
        {
            this.setLdJsonCards(this.currentRenderer, this.cardsToRestore, false);
        }
    }
}