
import { Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../services/i18n.service';

@Pipe({
    name: 'i18nPrice'
})

export class I18nPricePipe implements PipeTransform 
{
    constructor(private i18nService: I18nService) {}

    transform(price: number)
    {
        // If the price is an integer, return it as is; otherwise, return the price with 2 fraction digits
        return price % 1 === 0 ? new Intl.NumberFormat(this.i18nService.Locale).format(price) : 
            new Intl.NumberFormat(this.i18nService.Locale, {minimumFractionDigits:2}).format(price);
    }
}