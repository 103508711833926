
export class LX_Definition
{
    Id: string = '';
    constructor(id: string)
    {
        this.Id = id;
    }
}

export class L3_Definition extends LX_Definition
{
}

export class L2_Definition extends LX_Definition
{
}

export class L1_Definition extends LX_Definition
{
}

export class L0_Definition extends LX_Definition
{
    L1: L1_Definition[] = new Array<L1_Definition>();
}

export class PageDefinition
{
    Id: string = '';
    L0: L0_Definition[] = new Array<L0_Definition>();
}

export class PagesDefinition
{
    Pages: PageDefinition[] = new Array<PageDefinition>();
}

export class ResultPagesDefinition
{
    status: string = '';
    result: PagesDefinition = new PagesDefinition();
}