import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ApiService } from '../../services/api.service';
import { CardService } from '../../services/card.service';
import { LogEventService } from '../../services/logevent.service';
import { ModalService } from '../../services/modal.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip-share',
  templateUrl: './chip-share.component.html',
  styleUrls: ['./chip-share.component.scss']
})
export class ChipShareComponent implements OnInit
{
    @Input() card: ResultDefinition | null = null;

    constructor(public cardService: CardService, private modalService: ModalService, private logEvent: LogEventService, private apiService: ApiService)
    {
    }

    ngOnInit(): void
    {
    }

    onShareClick(): void
    {
        if (this.card)
        {
            this.apiService.share(this.card).subscribe(result => 
            {
                this.card!.Product.Counters = result.Counters;
            });

            this.logEvent.sendEventShare();
            this.modalService.openShareModal(this.card);
        }
    }
}
