import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'btn-standard',
  templateUrl: './btn-standard.component.html',
  styleUrls: ['./btn-standard.component.scss']
})
export class BtnStandardComponent
{
    @Input() useSmall: boolean = false;
    @Input() useBlack: boolean = false;
    @Input() fixedSize: boolean = false;
    @Input() icon: string = '';
}
