import { Component, Input, AfterViewInit, DoCheck, ViewChild, ElementRef, IterableDiffers } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

@Component({
  selector: 'historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss']
})
export class HistoricComponent implements AfterViewInit, DoCheck {
  public chartId: number = Math.random();

  @ViewChild('HistoryChart', { static: true }) canvas: ElementRef<HTMLCanvasElement> | undefined;

  @Input() currencySymbol: string = "";
  @Input() wstd: number = 0;
  @Input() wstdp: number = 0;
  @Input() labelsPriceHistory: Array<string> = [];
  @Input() dataPriceHistory: Array<number> = [];
  @Input() dataPricePredict: Array<number> = [];
  @Input() marketPriceHistory: Array<number> = [];

  public chart: any;

  iterableDiffer;

  constructor(private i18nService: I18nService, private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create();
  }

  ngDoCheck(): void {
    let changesLabels = this.iterableDiffer.diff(this.labelsPriceHistory);
    if (changesLabels) {
      this.refreshChart();
    }
  }

  ngAfterViewInit(): void {
    this.refreshChart();
  }

  refreshChart(): void {
    if (this.canvas && this.canvas.nativeElement && this.labelsPriceHistory) {
      //const ctxFct = this.canvas.nativeElement.getContext('2d');

      /*       const highestYValue = Math.max(...parsedData.map(point => point.y));
            const highestIndex = parsedData.findIndex(point => point.y === highestYValue); */

      const endOfDay = new Date(new Date());
      endOfDay.setHours(23, 59, 59, 999); // Set to 23:59:59.999

      const parsedData = this.labelsPriceHistory
        .filter((date) => new Date(date) <= endOfDay)
        .map((date, index) => ({
          x: date,
          y: this.dataPriceHistory[index]
        }));

      const marketData = this.labelsPriceHistory
        .filter((date) => new Date(date) <= endOfDay)
        .map((date, index) => ({
          x: date,
          y: this.marketPriceHistory[index]
        }));

      const predictData = this.labelsPriceHistory
        .filter((date) => new Date(date) > endOfDay)
        .map((date, index) => ({
          x: date,
          y: this.dataPricePredict[index]
        }));

      if (this.chart) {
        this.chart.destroy();
      }

      try {
        this.chart = new Chart(this.canvas.nativeElement,
          {
            type: 'line',
            data: {
              labels: this.labelsPriceHistory,
              datasets: [
                {
                  label: this.i18nService.getText("wap1_c_historic_priceLegend"),
                  data: parsedData,
                  backgroundColor: 'transparent', // You can adjust this to your preference
                  borderColor: '#d4af37',
                  borderWidth: 3,
                  pointRadius: 0,
                  tension: 0.5,
                  fill: true,
                  stepped: true
                },
                {
                  label: this.i18nService.getText("wap1_c_historic_marketLegend"),
                  data: marketData,
                  backgroundColor: function (context) {
                    // Ensure the chart is fully defined.
                    if (!context.chart || !context.chart.ctx) {
                      console.error('Chart or its context is not defined.');
                      return 'rgba(168, 154, 254, 0.5)';  // Default color if there's an issue
                    }

                    const chartContext = context.chart.ctx;
                    const canvas = chartContext.canvas;
                    // Assuming a vertical gradient. Adjust the y1 and y2 values as necessary.
                    const gradient = chartContext.createLinearGradient(0, 0, 0, canvas.clientHeight);

                    // Add color stops. Adjust the stop positions as needed.
                    gradient.addColorStop(0, 'rgba(168, 154, 254, 1)');  // Purple at the top
                    gradient.addColorStop(0.8, 'rgba(168, 154, 254, 0)');  // Transparent towards the middle
                    gradient.addColorStop(1, 'rgba(168, 154, 254, 0)');  // Transparent at the bottom

                    return gradient;
                  },

                  borderColor: '#5135FE',
                  borderWidth: 3,
                  pointRadius: 0,
                  tension: 0.15,
                  fill: true
                },
                {
                  label: this.i18nService.getText("wap1_c_historic_predictLegend"),
                  data: predictData,
                  backgroundColor: 'transparent',
                  borderColor: '#d4af37',
                  borderWidth: 3,
                  borderDash: [3, 3],
                  pointRadius: 0,
                  tension: 0.5,
                  fill: true,
                  stepped: true
                }
              ]
            },
            options: {
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  mode: 'x',
                  intersect: false, // if true, the tooltip mode applies only when the pointer is intersecting an item on the chart
                  position: 'average', // will place the tooltip at the average position of the items displayed in the tooltip
                  usePointStyle: true, // if true, will use the same style for the tooltip items as the point style,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',  // Adjust the RGBA values accordingly
                  bodyColor: '#5135FE',
                  titleColor: '#5135FE',
                  callbacks: {
                    labelColor: (context) => {
                      const datasetColors = ['#d4af37', '#5135FE', '#d4af37'];  // Assuming 2 datasets; add more if needed
                      const color = datasetColors[context.datasetIndex];
                      return {
                        borderColor: color,
                        backgroundColor: color
                      };
                    }
                  }
                }
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    parser: 'YYYY-MM-DDTHH:mm:ss.SSSZ', // This matches your date format
                    tooltipFormat: 'll',
                    displayFormats: {
                      day: 'MM-DD'  // Display only month and day on the x-axis
                    }
                  },
                  title: {
                    display: false
                  }
                },
                y: {
                  title: {
                    display: false
                  }
                }
              },
              animation: {
                duration: 1500, // Duration in milliseconds (e.g., 2000ms = 2 seconds)
                easing: 'easeOutQuad', // Type of animation easing
                /*               onComplete: function () {
                                const chartInstance = this;
                                if (chartInstance.tooltip) {
                                  chartInstance.setActiveElements([{datasetIndex: 0, index: highestIndex}]);
                                  chartInstance.render();
                                }
                              } */
              }
            }
          });
      } catch (error) {
        console.error("Error creating chart:", error);
      }
    }
  }
}
