<div class="container">
	<div class="row">
		<div class="col-3"></div>
		<div class="col-9">
			<div class="d-flex flex-row align-items-center bg-color-gray-dark border-radius-std color-white p-2">
				<div>BB</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-3">
			<div class="border-around d-flex flex-column p-2"></div>
		</div>
		<div class="col-9"></div>
	</div>
</div>