
import { ProductModal, AuthModal, ShareModal, SearchModal, InfoModal, ReviewModal, ContactModal } from '../interfaces/modal';
import { ResultDefinition } from '../interfaces/Serialization/ResultsDefinition';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalService {
    // Evenement à s'abonner pour être informé de changement dans la fenêtre carte modal
    public onCardModalChange(): BehaviorSubject<ProductModal> {
        return this.modalCardSubject;
    }
    public onShareModalChange(): BehaviorSubject<ShareModal> {
        return this.modalShareSubject;
    }
    public onSearchModalChange(): BehaviorSubject<SearchModal> {
        return this.modalSearchSubject;
    }
    public onAuthModalChange(): BehaviorSubject<AuthModal> {
        return this.modalAuthSubject;
    }
    public onInfoModalChange(): BehaviorSubject<InfoModal> {
        return this.modalInfoSubject;
    }
    public onReviewModalChange(): BehaviorSubject<ReviewModal> {
        return this.modalReviewSubject;
    }
    public onContactModalChange(): BehaviorSubject<ContactModal> {
        return this.modalContactSubject;
    }

    private modalCardSubject: BehaviorSubject<ProductModal>;
    private modalShareSubject: BehaviorSubject<ShareModal>;
    private modalAuthSubject: BehaviorSubject<AuthModal>;
    private modalSearchSubject: BehaviorSubject<SearchModal>;
    private modalInfoSubject: BehaviorSubject<InfoModal>;
    private modalReviewSubject: BehaviorSubject<ReviewModal>;
    private modalContactSubject: BehaviorSubject<ContactModal>;

    private emptyShareModal: ShareModal = new ShareModal(false, null);
    private emptyProductModal: ProductModal = new ProductModal(false, null);
    private emptyAuthModal: AuthModal = new AuthModal(false, false);
    private emptySearchModal: SearchModal = new SearchModal(false);
    private emptyInfoModal: InfoModal = new InfoModal(false, "", "", false);
    private emptyReviewModal: ReviewModal = new ReviewModal(false, 5);
    private emptyContactModal: ContactModal = new ContactModal(false);

    constructor() {
        this.modalCardSubject = new BehaviorSubject<ProductModal>(this.emptyProductModal);
        this.modalShareSubject = new BehaviorSubject<ShareModal>(this.emptyShareModal);
        this.modalAuthSubject = new BehaviorSubject<AuthModal>(this.emptyAuthModal);
        this.modalSearchSubject = new BehaviorSubject<SearchModal>(this.emptyAuthModal);
        this.modalInfoSubject = new BehaviorSubject<InfoModal>(this.emptyInfoModal);
        this.modalReviewSubject = new BehaviorSubject<ReviewModal>(this.emptyReviewModal);
        this.modalContactSubject = new BehaviorSubject<ContactModal>(this.emptyContactModal);
    }

    // Modal Opening
    public openProductModal(card: ResultDefinition): void {
        this.modalCardSubject.next(new ProductModal(true, card));
    }
    public openShareModal(card: ResultDefinition): void {
        this.modalShareSubject.next(new ShareModal(true, card));
    }
    public openAuthModal(isSessionExpired: boolean = false): void {
        this.modalAuthSubject.next(new AuthModal(true, isSessionExpired));
    }
    public openSearchModal(): void {
        this.modalSearchSubject.next(new SearchModal(true));
    }
    public openInfoModal(title: string, description: string, usePrimeBtn: boolean): void {
        this.modalInfoSubject.next(new InfoModal(true, title, description, usePrimeBtn));
    }
    public openReviewModal(idRate: number): void {
        this.modalReviewSubject.next(new ReviewModal(true, idRate));
    }
    public openContactModal(): void {
        this.modalContactSubject.next(new ContactModal(true));
    }

    // Modal Closing
    public closeCardModal(): void {
        this.modalCardSubject.next(this.emptyProductModal);
    }
    public closeShareModal(): void {
        this.modalShareSubject.next(this.emptyShareModal);
    }
    public closeAuthModal(): void {
        this.modalAuthSubject.next(this.emptyAuthModal);
    }
    public closeSearchModal(): void {
        this.modalSearchSubject.next(this.emptySearchModal);
    }
    public closeInfoModal(): void {
        this.modalInfoSubject.next(this.emptyInfoModal);
    }
    public closeReviewModal(): void {
        this.modalReviewSubject.next(this.emptyReviewModal);
    }
    public closeContactModal(): void {
        this.modalContactSubject.next(this.emptyContactModal);
    }
}