import { Component } from '@angular/core';
import { InfoModal } from '../../interfaces/modal';
import { ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { NavigatorService } from '../../services/navigator.service';

@Component({
  selector: 'modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent
{
    private modalSubscription: Subscription | undefined;

    infoModal: InfoModal | undefined;

    constructor(private modalService: ModalService, private navigatorService: NavigatorService)
    {
    }
    
    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onInfoModalChange().subscribe((modalData: any) =>
        {
            this.infoModal = modalData;
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    goPrime(): void
    {
        this.navigatorService.navigateToProfile();
    }
    
    onCloseClick(): void
    {
        this.modalService.closeInfoModal();
    }
    
    onInsideClick(e: Event)
    {
        e.stopPropagation();
    }
}
