import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ApiService } from '../../services/api.service';
import { CardService } from '../../services/card.service';
import { LogEventService } from '../../services/logevent.service';
import { ModalService } from '../../services/modal.service';
import { ProfileService } from '../../services/profile.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip-like',
  templateUrl: './chip-like.component.html',
  styleUrls: ['./chip-like.component.scss']
})
export class ChipLikeComponent implements OnInit
{
    @Input() card: ResultDefinition | null = null;

    isLiked: boolean = false;

    constructor(public cardService: CardService, private apiService: ApiService, private profileService: ProfileService, private logEvent: LogEventService, private modalService: ModalService)
    {
    }

    ngOnInit(): void
    {
        if (this.card && this.profileService.HasProfileConnected)
        {
            this.isLiked = this.profileService.isCardLiked(this.card);
        }
    }

    onLikeClick(): void
    {
        if (this.card && this.profileService.HasProfileConnected)
        {
            this.profileService.toggleCardLike(this.card).then((like) =>
            {
                this.isLiked = like;

                if (this.card && this.isLiked)
                {
                    this.apiService.like(this.card).subscribe(result => 
                    {
                        this.card!.Product.Counters = result.Counters;
                    });
                }
                else if (this.card && !this.isLiked)
                {
                    this.apiService.unlike(this.card).subscribe(result => 
                    {
                        this.card!.Product.Counters = result.Counters;
                    });
                }
            });

            this.logEvent.sendEventLike();
        }
        else
        {
            this.modalService.openAuthModal();
        }
    }
}
