<ng-container *ngIf="card && card.Product">

    <div class="product d-flex flex-column" [ngClass]="{ 'prime': cardService.isCardForPrime(card) }">
        
        <!-- Found by -->
        <div class="d-flex flex-row justify-content-between align-items-center p-2">
            <foundby [card]="card"></foundby>
            <chip-follow [card]="card" [followType]="0"></chip-follow>
        </div>

        <!-- Images -->
        <div class="position-relative" (click)="openCard(card)">
            <div class="aspect-ratio-container cursor-pointer" [ngClass]="{ 'bg-color-lavender': card.Product.Tags.Selfridges }">
                <img [ngClass]="{'fadded': card.Product._ToggleHistory }" [src]="cardService.getPrimeImageUrl(card)" />
                <div class="w-100 h-100 px-2 pt-2" [ngClass]="{'fadded': card.Product._ToggleHistory }">
                    <historic [wstd]="card.Product._Wstd" [wstdp]="card.Product._Wstdp" [currencySymbol]="card.Product._CurrencySymbol" [labelsPriceHistory]="card.Product._LabelsPriceHistory" [dataPriceHistory]="card.Product._DataPriceHistory" [marketPriceHistory]="card.Product._MarketPriceHistory" [dataPricePredict]="card.Product._DataPricePredict"></historic>
                </div>
            </div>
            <div class="position-absolute bottom-0 start-0">
                <div *ngIf="card.Product.HasPriceHistory && card.Product.PriceDropLowestEver === cardService.getBestPrice(card)"
                    class="d-flex flex-row align-items-center lowest-ever px-2 py-1">
                    <img class="picto-medium" [ngClass]="{'fadded': card.Product._ToggleHistory }" src="'../../../assets/images/231116/pictos/LowestEver.svg" />
                    <div class="typo-lowest-ever px-2" [ngClass]="{'fadded': card.Product._ToggleHistory }">{{ 'wap1_m_product_lowestEver' | translate }}</div>
                </div>
            </div>
            <div *ngIf="card.Product.Tags.TopRight !== undefined" class="position-absolute new-true">
                <img class="picto-xl" [src]="'../../../assets/images/231116/pictos/' + card.Product.Tags.TopRight + '.svg'" />
            </div>
            <div *ngIf="card.Product.Tags.Luxury" class="position-absolute luxury-true">
                <img class="picto-big" src="../../../assets/images/231116/pictos/LuxuryTrue.svg" />
            </div>
            
            <!-- <div class="d-flex align-items-center ms-3 position-absolute bottom-0 end-0">
                <img class="picto-medium" src="../../../assets/images/231116/pictos/Eye-fill.svg" />
                <div class="mx-2" *ngIf="cardService.getNumberOfView(card) as nbView">{{ nbView }}</div>
            </div> -->
        </div>
        
        <!-- Title -->
        <div class="px-3 d-flex flex-row align-items-center">
            <div class="typo-regular-black h-limit">{{ card.Product.Title }}</div>
        </div>
        
        <!-- Market Level + Historic Button-->
        <div class="d-flex flex-row align-items-center justify-content-between p-3 cursor-pointer" (click)="onMarketPlaceClick()">
            <market-level [card]="card"></market-level>

            <div *ngIf="card.Product.HasPriceHistory" class="btn-track chip d-inline-block px-3 py-1 ms-3">
                <div class="d-flex flex-row align-items-center">
                    <img src="../../../assets/images/231116/pictos/graph-down-arrow.svg" class="picto-medium" />
                    <div class="typo-chip ms-2">{{ "wap1_c_card_seeGraph" | translate }}</div>
                </div>
            </div>
        </div>

        <!-- Infos product -->
        <div class="d-flex flex-column justify-content-between h-100">
            <div class="d-flex flex-row justify-content-between align-items-center mt-2 px-3">
                <div *ngIf="cardService.getBestPrice(card)" class="d-flex flex-row align-items-center">
                    <img class="picto-medium me-2" [src]="cardService.getBestMarketstoreLogo(card)" />
                    <div class="typo-marketplace">{{ cardService.getBestMarketstore(card) }}</div>
                </div>
                <chip-marketplace *ngIf="cardService.getBestPrice(card)" [card]="card"></chip-marketplace>
            </div>
        </div>
        
        <div class="d-flex flex-row justify-content-between align-items-center p-3">
            <chip-share [card]="card"></chip-share>
            <chip-like class="mx-2" [card]="card"></chip-like>
            <chip-watch class="ms-2" [card]="card"></chip-watch>
        </div>

    </div>

</ng-container>