import { FollowType } from '../../interfaces/followType';
import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { LogEventService } from '../../services/logevent.service';
import { ModalService } from '../../services/modal.service';
import { ProfileService } from '../../services/profile.service';
import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip-follow',
  templateUrl: './chip-follow.component.html',
  styleUrls: ['./chip-follow.component.scss']
})

export class ChipFollowComponent implements OnInit
{
    @Input() followType: FollowType = FollowType.Brands;
    @Input() card: ResultDefinition | null = null;

    @HostListener('click', ['$event'])
    onHostClick(event: MouseEvent)
    {
        event.stopPropagation();
    }

    isFollow: boolean = false;

    constructor(private profileService: ProfileService, private logEvent: LogEventService, private modalService: ModalService)
    {
    }

    ngOnInit(): void
    {
        if (this.card && this.profileService.HasProfileConnected)
        {
            this.isFollow = this.profileService.isCardFollow(this.card, this.followType);
        }
    }

    onFollowClick(card: ResultDefinition): void
    {
        if (this.card && this.profileService.HasProfileConnected)
        {
            this.logEvent.sendEventFollow();
            this.isFollow = this.profileService.toggleCardFollow(this.card, this.followType);
        }
        else
        {
            this.modalService.openAuthModal();
        }
    }

    isValid(): boolean
    {
        if (this.card)
        {
            if (this.followType === FollowType.Brands && this.card.Product.BrandName)
            {
                return true;
            }
            else if (this.followType === FollowType.MarketPlaces && this.card.Product.PriceDropMarketPlaceName)
            {
                return true;
            }
            else if (this.followType === FollowType.Tags && this.card.Product.Tags.L3)
            {
                return true;
            }
        }
        return false;
    }

    getChipText(): string | undefined
    {
        if (this.card)
        {
            if (this.followType === FollowType.Brands)
            {
                return this.card.Product.BrandName;
            }
            else if (this.followType === FollowType.MarketPlaces)
            {
                return this.card.Product.PriceDropMarketPlaceName;
            }
            else if (this.followType === FollowType.Tags)
            {
                return this.card.Product.Tags.L3;
            }
            else if (this.followType === FollowType.Channel)
            {
                return this.card.Product.CreatorName;
            }
        }
        return '';
    }
}
