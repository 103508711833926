
import { LoadType } from '../interfaces/loadType';
import { ResultDefinition, ResultsDefinition } from '../interfaces/Serialization/ResultsDefinition';
import { L0_Definition, L1_Definition, L3_Definition } from '../interfaces/Serialization/PagesDefinition';
import { WatchDefinition } from '../interfaces/Serialization/ProfileDefinition';
import { Injectable } from '@angular/core';
import { Observable, Subscription, catchError } from 'rxjs';
import { ApiService } from './api.service';
import { ProfileService } from './profile.service';
import { NavigatorService } from './navigator.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService
{
    public get IsLoading(): boolean
    {
        return this.isLoading;
    }
    public get IsSearching(): boolean
    {
        return this.loadType === LoadType.SEARCH;
    }
    public get ViewId(): number
    {
        return this.view;
    }
    public set ViewId(value: number)
    {
        this.view = value;
    }
    public get TopDealsOnly(): boolean
    {
        return this.requestTopDeals;
    }
    public set TopDealsOnly(value: boolean)
    {
        this.requestTopDeals = value;
    }
    public get Keyword(): string
    {
        return this.keyword;
    }
    public set Keyword(value: string)
    {
        value = value.replace("'", "´");
        this.keyword = value;
        this.view = 0;
        this._L0 = new L0_Definition("all");
        this._L1 = new L1_Definition("all");
        this._L3 = new L3_Definition("all");
    }
    public get L0(): L0_Definition
    {
        return this._L0;
    }
    public set L0(value: L0_Definition)
    {
        this.keyword = '';
        this.view = 0;
        this._L0 = value;
        this._L1 = new L1_Definition("all");
        this._L3 = new L3_Definition("all");
    }
    public get L1(): L1_Definition
    {
        return this._L1;
    }
    public set L1(value: L1_Definition)
    {
        this._L1 = value;
    }
    public get L3(): L3_Definition
    {
        return this._L3;
    }
    public set L3(value: L3_Definition)
    {
        this._L3 = value;
    }
    public get UseAlternateTube(): boolean
    {
        return this.useAlternateTube;
    }
    public set UseAlternateTube(value: boolean)
    {
        this.useAlternateTube = value;
    }

    private loadType: LoadType = LoadType.PRODUCT;
    private view: number = 0;
    private _L0: L0_Definition = new L0_Definition("all");
    private _L1: L1_Definition = new L1_Definition("all");
    private _L3: L1_Definition = new L1_Definition("all");
    private keyword: string = '';
    private discount: number = 0;
    private requestTopDeals: boolean = false;
    private sortType: number = 0;
    private page: number = 1;
    private useAlternateTube: boolean = false;

    private isLoading: boolean = false;

    private indexTubeCard: number = 0;

    public cardsLoaded: ResultDefinition[] = new Array<ResultDefinition>();
    private tubeCardsLoaded: ResultDefinition[] = new Array<ResultDefinition>();

    private currentLoadProducts: Subscription | null = null;

    constructor(private apiService: ApiService, private profileService: ProfileService, private navigatorService: NavigatorService)
    {
    }

    public initialize(loadType: LoadType): void
    {
        this.loadType = loadType;

        this.ClearAll();
    }

    private ClearAll(): void
    {
        this.view = 0;
        this._L0 = new L0_Definition("all");
        this._L1 = new L1_Definition("all");
        this._L3 = new L3_Definition("all");
        this.keyword = '';
        this.discount = 0;
        this.requestTopDeals = false;
        this.sortType = 0;
        this.page = 1;
        this.useAlternateTube = false;
    }

    public loadNext(): void
    {
        if (this.loadType === LoadType.PRODUCT || this.loadType === LoadType.SEARCH)
        {
            this.loadNextProduct();
        }
        else if (this.loadType === LoadType.TUBE)
        {
            this.loadNextTube();
        }
    }

    public reload(): void
    {
        if (this.loadType === LoadType.PRODUCT || this.loadType === LoadType.SEARCH)
        {
            this.reloadProduct();
        }
        else if (this.loadType === LoadType.TUBE)
        {
            this.reloadTube();
        }
    }

    private reloadProduct(): void
    {
        if (this.currentLoadProducts)
        {
            this.currentLoadProducts.unsubscribe();
        }

        this.isLoading = true;

        this.clearProducts();
        this.currentLoadProducts = this.loadProducts().subscribe({ 
            next: (cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            },
            error: (err) =>
            {
                if (err.status === 307)
                {
                    this.navigatorService.navigateToPageRegionNotOpen();
                }
            }
        });
    }

    private loadNextProduct(): void
    {
        if (!this.isLoading)
        {
            this.isLoading = true;
            this.page++;
            
            this.loadProducts().subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
                
                if (this.useAlternateTube && this.indexTubeCard < this.tubeCardsLoaded.length)
                {
                    this.cardsLoaded = this.cardsLoaded.concat(this.tubeCardsLoaded[this.indexTubeCard]);
                    this.indexTubeCard++;
                }
            });
        }
    }

    private reloadTube(): void
    {
        if (!this.isLoading)
        {
            this.isLoading = true;
    
            this.clearProducts();
            this.loadTubes().subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    private loadNextTube(): void
    {
        if (!this.isLoading)
        {
            this.isLoading = true;
            this.page++;
            
            this.loadTubes().subscribe((cardsDef: ResultsDefinition) =>
            {
                if (this.useAlternateTube && this.indexTubeCard < this.tubeCardsLoaded.length)
                {
                    this.cardsLoaded = this.cardsLoaded.concat(this.tubeCardsLoaded[this.indexTubeCard]);
                    this.indexTubeCard++;
                }

                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadNextMyBrands(): void
    {
        if (!this.isLoading && this.profileService.FollowBrands && this.profileService.FollowBrands.length > 0)
        {
            this.isLoading = true;
            this.page++;
            
            this.apiService.selectBrands(this._L3.Id === "all" ? this.profileService.FollowBrands : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadMyBrands(): void
    {
        if (!this.isLoading && this.profileService.FollowBrands && this.profileService.FollowBrands.length > 0)
        {
            this.isLoading = true;

            this.clearProducts();
            this.apiService.selectBrands(this._L3.Id === "all" ? this.profileService.FollowBrands : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadNextMyMarketplaces(): void
    {
        if (!this.isLoading && this.profileService.FollowMarketPlaces && this.profileService.FollowMarketPlaces.length > 0)
        {
            this.isLoading = true;
            this.page++;
            
            this.apiService.selectMarketPlaces(this._L3.Id === "all" ? this.profileService.FollowMarketPlaces : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadMyMarketplaces(): void
    {
        if (!this.isLoading && this.profileService.FollowMarketPlaces && this.profileService.FollowMarketPlaces.length > 0)
        {
            this.isLoading = true;

            this.clearProducts();
            this.apiService.selectMarketPlaces(this._L3.Id === "all" ? this.profileService.FollowMarketPlaces : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadNextMyTags(): void
    {
        if (!this.isLoading && this.profileService.FollowTags && this.profileService.FollowTags.length > 0)
        {
            this.isLoading = true;
            this.page++;
            
            this.apiService.selectTags(this._L3.Id === "all" ? this.profileService.FollowTags : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadMyTags(): void
    {
        if (!this.isLoading && this.profileService.FollowTags && this.profileService.FollowTags.length > 0)
        {
            this.isLoading = true;

            this.clearProducts();
            this.apiService.selectTags(this._L3.Id === "all" ? this.profileService.FollowTags : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadMyChannelsNext(): void
    {
        if (!this.isLoading && this.profileService.FollowChannels && this.profileService.FollowChannels.length > 0)
        {
            this.isLoading = true;
            this.page++;
            
            this.apiService.selectSpecificChannels(this._L3.Id === "all" ? this.profileService.FollowChannels : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadMyChannels(): void
    {
        if (!this.isLoading && this.profileService.FollowChannels && this.profileService.FollowChannels.length > 0)
        {
            this.isLoading = true;

            this.clearProducts();
            this.apiService.selectSpecificChannels(this._L3.Id === "all" ? this.profileService.FollowChannels : [this._L3.Id], this.page).subscribe((cardsDef: ResultsDefinition) =>
            {
                this.isLoading = false;
                this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
            });
        }
    }

    public loadMyWatchs(L0: string = "all", L3: string = "all"): void
    {
        if (!this.isLoading)
        {
            this.isLoading = true;

            this.clearProducts();
            let cardsId: WatchDefinition[] | undefined = this.profileService.getCardIdsWatched(L0);
            if (cardsId && cardsId.length > 0)
            {
                if (L3 !== "all")   cardsId = cardsId.filter(c => c.L3 === L3);
                this.apiService.refresh(cardsId.map(wtc => wtc.cardId)).subscribe((cardsDef: ResultsDefinition) =>
                {
                    this.isLoading = false;
                    this.cardsLoaded = this.cardsLoaded.concat(cardsDef.results);
                });
            }
            else
            {
                this.isLoading = false;
            }
        }
    }

    private loadProducts(): Observable<ResultsDefinition>
    {
        //console.log("L0: ", this._L0.Id, " :::L1: ", this._L1.Id, " :::L3: ", this._L3.Id);
        return this.apiService.select(this._L0.Id, this._L1.Id, this._L3.Id, this.keyword, this.view, this.discount, this.requestTopDeals, this.sortType, this.page);
    }

    private loadTubes(): Observable<ResultsDefinition>
    {
        return this.apiService.selectChannels(this._L0.Id, this.view, this.page);
    }

    private clearProducts(): void
    {
        this.page = 1;
        this.cardsLoaded = new Array<ResultDefinition>();
    }

    private clearTubes(): void
    {
        this.tubeCardsLoaded = new Array<ResultDefinition>();
    }
}