<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
    <!-- SECTION HERO + SUBNAV -->
    <hero></hero>
    <subnav [isTitleMode]="true" title="{{ 'wap1_c_report_thanks' | translate }}"></subnav>

    <div class="container-fluid px-4">
        <!-- SECTION HEADER -->
        <div class="row my-5 justify-content-center">
            <div class="col-12 col-md-10 text-center">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_pricing_section1' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5 justify-content-center">
            <div class="col-12 col-md-10 text-center" style="min-height: 400px; max-width: 1080px;">
                <video class="w-100 mx-auto rounded darker-shadow" controls 
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_0_en.jpg')">
                    <source src="../../../external/videos/prems_currency_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <!-- SECTION HEADER -->
        <div class="row my-5 justify-content-center">
            <div class="col-12 col-md-10 text-center">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_pricing_section2' | translate | safeMark">
                </div>
            </div>
        </div>

        <!-- SECTION HEADER -->
        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_1_en.jpg')" />
            </div>

            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>

            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_2_en.jpg')" />
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_3_en.jpg')" />
            </div>

            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>

            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_4_en.jpg')" />
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_5_en.jpg')" />
            </div>

            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>
    </div>

    <!-- SECTION HEADER -->
    <div class="row my-5 justify-content-center">
        <div class="col-12 col-md-10">
            <div class="markdown-container p-3" [innerHTML]="'wap1_p_pricing_section3' | translate | safeMark">
            </div>
        </div>
    </div>

    <hr>
    <footer></footer>
</div>