
import { L0_Definition, L1_Definition, L3_Definition } from '../../interfaces/Serialization/PagesDefinition';
import { ResultDefinition, ResultsDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ApiService } from '../../services/api.service';
import { CardService } from '../../services/card.service';
import { Component, DoCheck, HostListener, IterableDiffer, IterableDiffers, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { LoadType } from '../../interfaces/loadType';
import { LDJsonService } from '../../services/ldjson.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-deals',
    templateUrl: './deals.component.html',
    styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements DoCheck, OnInit, OnDestroy
{
    L0_Array: L0_Definition[] | undefined;
    L1_Array: L1_Definition[] | undefined;

    titleBanner: string = '';
    iterableDiffer: IterableDiffer<ResultDefinition>;

    private profileSubscription: Subscription | undefined;
    
    @HostListener('scroll', ['$event'])
    onScroll(event: any)
    {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)
        {
            this.productService.loadNext();
        }
    }

    constructor(private iterableDiffers: IterableDiffers, private renderer2: Renderer2, private ldJson: LDJsonService,
                private apiService: ApiService, private activatedRoute: ActivatedRoute, private authService: AuthService,
                public productService: ProductService, public cardService: CardService, private modalService: ModalService)
    {
        this.iterableDiffer = iterableDiffers.find([]).create();
    }

    ngOnInit(): void 
    {
        this.profileSubscription = this.authService.onProfileChange().subscribe(() => {
            this.loadProducts();
        });
    }
    ngOnDestroy(): void
    {
        if (this.profileSubscription) this.profileSubscription.unsubscribe();
    }

    loadProducts(): void
    {
        const review: string | null = this.activatedRoute.snapshot.paramMap.get("review");  // Review
        const search: string | null = this.activatedRoute.snapshot.paramMap.get("search");  // Search
        
        const L0: string | null = this.activatedRoute.snapshot.paramMap.get("L0");  // Category & SimilarProduct
        const L1: string | null = this.activatedRoute.snapshot.paramMap.get("L1");  // Category & SimilarProduct
        const L3: string | null = this.activatedRoute.snapshot.paramMap.get("L3");  // Category & SimilarProduct

        const productId: string | null = this.activatedRoute.snapshot.paramMap.get("product");  // Share

        if (search)
        {
            this.productService.initialize(LoadType.SEARCH);

            this.productService.Keyword = search;
            this.productService.UseAlternateTube = true;
            this.productService.reload();
        }
        else if (productId)
        {
            this.L0_Array = this.apiService.PagesDefinition?.Pages.find(board => board.Id === "Deals")?.L0;
            if (this.L0_Array)
            {
                this.productService.initialize(LoadType.PRODUCT);

                this.apiService.refresh([productId]).subscribe((cards: ResultsDefinition) =>
                {
                    const cardToOpen : ResultDefinition = cards.results[0];
                    
                    if (this.L0_Array)
                    {
                        let idL0Card: number = this.L0_Array.findIndex(c => c.Id === cardToOpen.Product.Tags.L0);
                        if (idL0Card === -1) idL0Card = 0;
                        this.L1_Array = this.L0_Array[idL0Card].L1;
                    }
                    
                    this.productService.L0 = new L0_Definition(cardToOpen.Product.Tags.L0 === undefined ? "all" : cardToOpen.Product.Tags.L0);
                    this.productService.L1 = new L1_Definition(cardToOpen.Product.Tags.L1 === undefined ? "all" : cardToOpen.Product.Tags.L1);
                    this.productService.UseAlternateTube = true;
                    this.productService.reload();

                    this.modalService.openProductModal(cardToOpen);
                });
            }
        }
        else if (L0 && L1 && L3)
        {
            // En théorie, il y a forcément un L0 et L1 si il y a un L3
            this.L0_Array = this.apiService.PagesDefinition?.Pages.find(board => board.Id === "Deals")?.L0;
            if (this.L0_Array)
            {
                this.productService.initialize(LoadType.PRODUCT);

                this.L1_Array = this.L0_Array?.find(arr => arr.Id === L0)?.L1;
                this.productService.L0 = new L0_Definition(L0);
                this.productService.L1 = new L1_Definition(L1);
                this.productService.L3 = new L3_Definition(L3);
                //this.productService.UseAlternateTube = true;
                this.productService.TopDealsOnly = false;
                this.productService.reload();
            }
        }
        else
        {
            this.L0_Array = this.apiService.PagesDefinition?.Pages.find(board => board.Id === "Deals")?.L0;
            if (this.L0_Array)
            {
                this.productService.initialize(LoadType.PRODUCT);

                this.L1_Array = this.L0_Array[0].L1;
                this.productService.L0 = new L0_Definition("all");
                this.productService.L1 = new L1_Definition("all");
                this.productService.UseAlternateTube = true;
                this.productService.reload();
            }
        }

        // Open the review panel
        if (review)
        {
            this.modalService.openReviewModal(parseInt(review));
        }
    }

    ngDoCheck(): void
    {
        let changes = this.iterableDiffer.diff(this.productService.cardsLoaded);
        if (changes)
        {
            this.ldJson.setLdJsonCards(this.renderer2, this.productService.cardsLoaded);
        }
    }
}