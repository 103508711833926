import { Component, OnInit } from '@angular/core';
import { DarkModeService } from '../../services/dark-mode.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  constructor(private darkModeService: DarkModeService) {}

  ngOnInit() {
    // Apply the dark mode class if dark mode is enabled
    if (this.darkModeService.isDarkMode()) {
      document.body.classList.add('dark-mode');
    }
  }
}