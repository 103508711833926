import { Component, Input } from '@angular/core';
import { ResultDefinition } from 'src/app/interfaces/Serialization/ResultsDefinition';

@Component({
  selector: 'foundby',
  templateUrl: './foundby.component.html',
  styleUrls: ['./foundby.component.scss']
})
export class FoundbyComponent 
{
  @Input() card: ResultDefinition | null = null;
}
