import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private darkModeEnabled: boolean;

  constructor() {
    // Check system preference on service initialization
    this.darkModeEnabled = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    // Listen for system preference changes
    if (window.matchMedia) {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      darkModeMediaQuery.addEventListener('change', (event) => {
        this.darkModeEnabled = event.matches;
        document.body.classList.toggle('dark-mode', this.darkModeEnabled);
      });
    }
  }

  isDarkMode(): boolean {
    return this.darkModeEnabled;
  }

  toggleDarkMode(): void {
    this.darkModeEnabled = !this.darkModeEnabled;
    document.body.classList.toggle('dark-mode', this.darkModeEnabled);
  }
}
