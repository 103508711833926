import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { I18nService } from '../../services/i18n.service';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/auth.service';
import { NavigatorService } from '../../services/navigator.service';
import { ApiService } from 'src/app/services/api.service';

declare const LemonSqueezy: any;

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    get editform() { return this.editProfileForm.controls; }
    get notifform() { return this.notificationForm; }

    editProfileForm: FormGroup;
    notificationForm: FormGroup;

    submittedForm: boolean = false;
    submittedEditForm: boolean = false;
    requireRecentLogin: boolean = false;
    passwordChangedSuccess: boolean = false;
    wrongpassword: boolean = false;
    userNotFound: boolean = false;

    isGoingPlan: boolean = false;
    isEditingProfile: boolean = false;

    constructor(public authService: AuthService, public profileService: ProfileService, private formBuilder: FormBuilder, 
                private apiService: ApiService, private i18nService: I18nService, private navigatorService: NavigatorService) 
    {
        this.editProfileForm = this.formBuilder.group(
            {
                password: [null, [Validators.required, Validators.minLength(6)]],
                confirmpassword: [null, [Validators.required, Validators.minLength(6)]],
            }, { validator: this.checkPasswords });

        this.notificationForm = this.formBuilder.group(
            {
                alert: [this.profileService.UseAlerts],
                news: [this.profileService.UseNews],
            });
        this.notificationForm.valueChanges.subscribe(() => {
            this.profileService.setAlertNews(this.notificationForm.value.alert, this.notificationForm.value.news);
        });
    }

    ngOnInit(): void {
    }

    goElite() {
        this.isGoingPlan = true;

        const plan = 'Elite';
        this.apiService.getPaymentCheckout(plan).subscribe((response: { result: string }) =>
        {
            let redirectUrl = response.result;
            const aff_ref = LemonSqueezy.Affiliate.GetId() || '';
            if (aff_ref) {
                redirectUrl = response.result + '&aff_ref=' + aff_ref
            }
            window.open(response.result, "_self");
        });
    }

    goPrime() {
        this.isGoingPlan = true;

        const plan = 'Prime';
        this.apiService.getPaymentCheckout(plan).subscribe((response: { result: string }) =>
        {
            let redirectUrl = response.result;
            const aff_ref = LemonSqueezy.Affiliate.GetId() || '';
            if (aff_ref) {
                redirectUrl = response.result + '&aff_ref=' + aff_ref
            }
            window.open(redirectUrl, "_self");
        });
    }

    goManageSubscription() {
        this.isGoingPlan = true;

        this.apiService.getPaymentBilling().subscribe((response: { result: string }) => 
        {
            window.open(response.result, "_blank");
        });
    }

    onEditProfile(): void {
        this.isEditingProfile = true;
    }

    onChangePassword(): void {
        this.submittedEditForm = true;
        this.requireRecentLogin = false;
        this.passwordChangedSuccess = false;

        if (this.editProfileForm.valid)
        {
            this.authService.changePassword(this.editProfileForm.controls['password'].value).then(() =>
            {
                this.passwordChangedSuccess = true;
                this.isEditingProfile = false;

                this.editProfileForm.reset();
            }).catch((err: any) => {
                if (err.code === "auth/requires-recent-login") {
                    this.requireRecentLogin = true;
                }
            });
        }
    }

    onDeleteAccount(): void {
        if (confirm(this.i18nService.getText("wap1_c_profile_changeAccountDelete"))) {
            this.authService.deleteAccount().then(() => {
                // Supprime les produits en localstorage
                localStorage.removeItem(environment.cookiename);

                window.alert(this.i18nService.getText("wap1_c_profile_deleteAccountSuccess"));
                this.navigatorService.navigateToHome();
            }).catch((err: any) => {
                if (err.code === "auth/requires-recent-login") {
                    this.requireRecentLogin = true;
                }
            });
        }
    }

    checkPasswords(group: FormGroup) {
        const pass = group.controls['password'].value;
        const confirmPass = group.controls['confirmpassword'].value;

        return pass === confirmPass ? null : { notSame: true };
    }
}
