<div *ngIf="card" class="btn-track chip d-inline-block px-2 py-1" (click)="onWatchClick()">
    <div class="d-flex flex-row align-items-center">
        <img *ngIf="watchState === 0" class="picto-medium" src="../../../assets/images/231116/pictos/Watch.svg" />
        <img *ngIf="watchState === 1" class="picto-medium" src="../../../assets/images/231116/pictos/WatchOn.svg" />
        <img *ngIf="watchState === 2" class="picto-medium" src="../../../assets/images/231116/pictos/PriceDrop.svg" />
        <div *ngIf="cardService.getNumberOfWatch(card) == undefined && watchState === 0" class="typo-chip text-nowrap px-2">{{ 'wap1_c_watch_watch' | translate }}</div>
        <div *ngIf="cardService.getNumberOfWatch(card) == undefined && watchState === 1" class="typo-chip text-nowrap px-2">{{ 'wap1_c_watch_watchOn' | translate }}</div>
        <div *ngIf="cardService.getNumberOfWatch(card) == undefined && watchState === 2" class="typo-chip text-nowrap px-2">{{ 'wap1_c_watch_priceDropAlerts' | translate }}</div>
        <div *ngIf="cardService.getNumberOfWatch(card) as nbWatch" class="typo-chip ms-1">{{ nbWatch }}</div>
    </div>
</div>