import { LX_Definition } from '../../interfaces/Serialization/PagesDefinition';
import { ProductService } from '../../services/product.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'chip-LX',
  templateUrl: './chip-LX.component.html',
  styleUrls: ['./chip-LX.component.scss']
})
export class ChipLXComponent implements OnInit
{
    @Input() isL0: boolean = false;
    @Input() LX: LX_Definition | undefined;
    @Output() onChipLXClicked = new EventEmitter<LX_Definition>();
    
    constructor(public productService: ProductService)
    {
    }

    ngOnInit(): void
    {
    }

    onDeckClicked(): void
    {
        this.onChipLXClicked.emit(this.LX);
    }
}