<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
    <!-- SECTION HERO + SUBNAV -->
    <hero></hero>
    <subnav [isTitleMode]="true" title="{{ 'wap1_p_affiliates_cta' | translate }}"></subnav>

    <div class="container px-4">

        <!-- SECTION HEADER -->
        <div class="row my-5">
            <div class="col-12">
                <div id="featuresList" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_affiliates_headline' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-8">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_affiliates_promise' | translate | safeMark">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div>
                    <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                        src="../../../assets/images/231116/static/reward_2.png" />
                </div>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onAffiliateClick()">{{ 'wap1_p_affiliates_cta' | translate}}</btn-standard>
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-6">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_0_en.jpg')" />
            </div>
            <div class="col-12 col-md-6">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_affiliates_aboutus' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-7">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_affiliates_howto' | translate | safeMark">
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div>
                    <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    src="../../../assets/images/231116/static/LemonSqueezy.jpg" />
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onAffiliateClick()">{{ 'wap1_p_affiliates_cta' | translate}}</btn-standard>
                </div>
            </div>
        </div>

        <div class="row my-5 justify-content-center">
            <div class="col-12">
                <div class="markdown-container p-3" [innerHTML]="'wap1_p_affiliates_faq' | translate | safeMark">
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onAffiliateClick()">{{ 'wap1_p_affiliates_cta' | translate}}</btn-standard>
                </div>
                <br>
            </div>
        </div>


        <!-- hereForYou -->
        <div class="row my-5 justify-content-center">
            <div class="col-12">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>

        <hr>
        <footer></footer>
    </div>
</div>