// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


export const environment =
{
    API_URL: "https://https://api-dev.prems.ai/a1/",
    API_SIGNATURE: "?signature=AQQRICTBTT9XUHSC9AC9SROHYODENNJCUHEQIXLJIGIFIBIUYNAPIWXIRZDXAELCYA9WSNKGMFQVRCVQX",
    API_BOARD: "board/",
    API_CARD: "card/",
    API_EVENT: "event/",
    API_VERSION: "&version=v4.1",

    API_ACTION_PRELOAD: "preload",
    API_ACTION_PROFILE: "profile",
    API_ACTION_CHECKOUT: "checkout",
    API_ACTION_BILLING: "billing",
    API_ACTION_COUNT: "count",
    API_ACTION_ACTION: "action",
    API_ACTION_GETPROFILE: "getProfile",
    API_ACTION_REFRESH: "refresh",
    API_ACTION_PRICEHISTORY: "pricehistory",
    API_ACTION_LOAD: "load",
    API_ACTION_SELECT: "select",
    API_ACTION_TUBE: "tube",
    API_ACTION_SCAN: "scan",

    API_PARAM_ID: "&id=",
    API_PARAM_BARCODE: "&barcode=",
    API_PARAM_LOCALE: "&locale=",
    API_PARAM_PLAN: "&plan=",
    API_PARAM_LOGOUT: "&logout=true",

    hostname: "https://dev.prems.ai/",
    cookiename: "prems_watches",

    firebase: {
        projectId: 'secretbeautydeals',
        appId: '1:19373658835:web:7f2e26a421132684447a26',
        storageBucket: 'secretbeautydeals.appspot.com',
        locationId: 'northamerica-northeast1',
        apiKey: 'AIzaSyC_6q4vmxz5u-qW2VZJgTn2nQ9Fjz-_SXc',
        authDomain: 'secretbeautydeals.firebaseapp.com',
        messagingSenderId: '19373658835',
        measurementId: 'G-8ND2J3GYFY',
    },

    production: false,
    env_type: "dev",

    web_store_url:{
        chrome: "https://chromewebstore.google.com/detail/pibpmngalpkpbcjipmogpjlcegcbpcjf",
        edge: "https://chromewebstore.google.com/detail/pibpmngalpkpbcjipmogpjlcegcbpcjf"
    },

    languages: ['en', 'es', 'fr', 'pt', 'de', 'it', 'ja'],
    regions:['us', 'ca', 'mx', 'br', 'eu', 'gb', 'fr', 'de', 'es', 'it', 'in', 'jp'],
    regionMenu:['us', 'ca', 'mx', 'br', 'eu', 'gb', 'in', 'jp'],
};
