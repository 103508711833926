import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/i18n.service';


@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit 
{
  public Locale: string = 'en-us';

  constructor(public i18nService: I18nService) { }

  ngOnInit(): void 
  {
    this.Locale = this.i18nService.Locale;
  }
  
  getLocale(): string {
    return this.i18nService.Locale
  }
}
