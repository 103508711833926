import { LoadType } from '../../interfaces/loadType';
import { L0_Definition } from '../../interfaces/Serialization/PagesDefinition';
import { ApiService } from '../../services/api.service';
import { CardService } from '../../services/card.service';
import { ModalService } from '../../services/modal.service';
import { ProductService } from '../../services/product.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.scss']
})
export class TvComponent implements OnInit
{
    L0_Array: L0_Definition[] | undefined;

    titleBanner: string = '';

    useLuxuryFilter: boolean = false;
    
    @HostListener('scroll', ['$event'])
    onScroll(event: any)
    {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)
        {
            this.productService.loadNext();
        }
    }

    constructor(private apiService: ApiService, private activedRoute: ActivatedRoute, public productService: ProductService, public cardService: CardService, private modalService: ModalService)
    {
        this.activedRoute.paramMap.subscribe(params =>
        {
            this.ngOnInit();
        });
    }

    ngOnInit(): void
    {
        this.productService.initialize(LoadType.TUBE);

        this.L0_Array = this.apiService.PagesDefinition?.Pages.find(board => board.Id === "Deals")?.L0;
        if (this.L0_Array)
        {
            this.productService.reload();
        }
    }
}
