
import { Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../services/i18n.service';

@Pipe({
    name: 'dateLocal'
})

export class DateLocalPipe implements PipeTransform 
{
    constructor(private i18n: I18nService) {}

    transform(dateStr: string)
    {
        const date: Date = new Date(dateStr);
        return date.toLocaleDateString(this.i18n.Locale, 
        {
            //weekday: "long",
            //year: "numeric",
            month: "short",
            day: "numeric",
        });
    }
}