
import { HttpEvent, HttpErrorResponse,HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../services/modal.service';

const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    constructor(private auth: AuthService, private modalService: ModalService)
    {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        let authReq = req;
        if (this.auth.token !== null)
        {
            authReq = this.addTokenHeader(req, this.auth.token);
        }
        return next.handle(authReq).pipe(catchError(err =>
        {
            if (this.auth.token !== null && err instanceof HttpErrorResponse && err.status === 401 && (err.statusText === "Unauthorized" || err.statusText === "Not authorize"))
            {
                this.auth.signOut();
                this.modalService.openAuthModal(true);
            }
            return throwError(err);
        }));
    }

    addTokenHeader(request: HttpRequest<any>, token: string)
    {
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
}