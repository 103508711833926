import { ResultDefinition, OfferDefinition } from '../interfaces/Serialization/ResultsDefinition';
import { Injectable } from '@angular/core';
import { I18nService } from './i18n.service';
import { LogEventService } from './logevent.service';
import { ApiService } from './api.service';
import { PriceHistoryDefinition } from '../interfaces/Serialization/PriceHistoryDefinition';

@Injectable({
  providedIn: 'root'
})
export class CardService
{
    constructor(private i18nService: I18nService, private logEvent: LogEventService, private apiService: ApiService)
    {
    }

    public getBestPrice(card: ResultDefinition): number | undefined
    {
        if (card.MarketOffers.length > 0)
        {
            return card.MarketOffers[0].Price;
        }
        return undefined;
    }
    public getHighestPrice(card: ResultDefinition): number | undefined
    {
        if (card.MarketOffers.length > 0)
        {
            return card.MarketOffers[0].Price;
        }
        return undefined;
    }
    public isFlagGreenOrBlue(card: ResultDefinition): boolean
    {
        return card.Product.PriceDropLowestOfferFlag == 'blue' || card.Product.PriceDropLowestOfferFlag == 'green';
    }
    public getPriceDropLowestOffer(card: ResultDefinition): number
    {
        if (card.Product.PriceDropLowestOffer) {
            return card.Product.PriceDropLowestOffer;
        } 
        return 0;
        
    }
    public getPriceDropLowestEver(card: ResultDefinition): number
    {
        if (card.Product.PriceDropLowestEver) {
            return card.Product.PriceDropLowestEver;
        } 
        return 0;
        
    }
    public getPriceDropAverage(card: ResultDefinition): number
    {
        if (card.Product.PriceDropAverage) {
            return card.Product.PriceDropAverage;
        } 
        return 0;
    }
    public getPriceDropHighestEver(card: ResultDefinition): number | undefined
    {
        var bestPrice = this.getBestPrice(card);
        if (bestPrice && card.Product.PriceDropHighestEver > bestPrice)
        {
            return card.Product.PriceDropHighestEver;
        }
        return undefined;
    }
    public getPriceRedThreshold(card: ResultDefinition): number
    {
        if (card.Product.PriceDropAverage) {
            return card.Product.PriceDropAverage;
        } 
        return 0;
    }

    public getBestMarketstore(card: ResultDefinition): string
    {
        if (card.MarketOffers.length > 0)
        {
            return card.MarketOffers[0].MarketPlaceName;
        }
        return '';
    }

    public getBestMarketstoreLogo(card: ResultDefinition): string
    {
        if (card.MarketOffers.length > 0 && card.MarketOffers[0].Logo)
        {
            return card.MarketOffers[0].Logo;
        }
        return '../../../assets/images/231116/bg_logo.png';
    }

    public getMarketstoreLogo(offer: OfferDefinition): string
    {
        return offer.Logo ? offer.Logo : '../../../assets/images/231116/bg_logo.png';
    }

    public getLowestOffer(card: ResultDefinition): number | undefined
    {
        if (card.MarketOffers.length > 0 && card.MarketOffers[0].Price)
        {
            return card.MarketOffers[0].Price;
        }
        return undefined;
    }

    public openBestOffer(card: ResultDefinition): void
    {
        if (card.MarketOffers.length > 0)
        {
            let offerUrl = card.MarketOffers[0].OfferUrls.find(off => off.Id_Language === this.i18nService.Language);
            if (!offerUrl) offerUrl = card.MarketOffers[0].OfferUrls.find(off => off.Id_Language === "en");
            if (!offerUrl && card.MarketOffers[0].OfferUrls.length > 0) offerUrl = card.MarketOffers[0].OfferUrls[0];
            if (offerUrl)
            {
                this.logEvent.sendEventBuy(card.MarketOffers[0].MarketPlaceName)
                window.open(offerUrl.Url, "_blank");
            }
        }
    }

    public openOfferUrl(offer: OfferDefinition): void
    {
        let offerUrl = offer.OfferUrls.find(off => off.Id_Language === this.i18nService.Language);
        if (!offerUrl) offerUrl = offer.OfferUrls.find(off => off.Id_Language === "en");
        if (!offerUrl && offer.OfferUrls.length > 0) offerUrl = offer.OfferUrls[0];
        if (offerUrl) {
            this.logEvent.sendEventBuy(offer.MarketPlaceName);
            window.open(offerUrl.Url, "_blank");
        }
    }

    public getNumberOfView(card: ResultDefinition): string | undefined
    {
        if (card.Product.Counters && card.Product.Counters.view && card.Product.Counters.view > 0)
        {
            return this.roundToKorM(card.Product.Counters.view);
        }
        return undefined;
    }
    public getNumberOfWatch(card: ResultDefinition): string | undefined
    {
        if (card.Product.Counters && card.Product.Counters.watch && card.Product.Counters.watch > 0)
        {
            return this.roundToKorM(card.Product.Counters.watch);
        }
        return undefined;
    }
    public getNumberOfShare(card: ResultDefinition): string | undefined
    {
        if (card.Product.Counters && card.Product.Counters.share && card.Product.Counters.share > 0)
        {
            return this.roundToKorM(card.Product.Counters.share);
        }
        return undefined;
    }
    public getNumberOfLike(card: ResultDefinition): string | undefined
    {
        if (card.Product.Counters && card.Product.Counters.like && card.Product.Counters.like > 0)
        {
            return this.roundToKorM(card.Product.Counters.like);
        }
        return undefined;
    }

    public getPrimeImageUrl(card: ResultDefinition): string | undefined
    {
        if (card.MarketOffers.length > 0)
        {
            if (card.MarketOffers[0].Images && card.MarketOffers[0].Images.length > 0)
            {
                return card.MarketOffers[0].Images[0].ref;
            }
        }
        return "";
    }

    public getPriceHistory(card: ResultDefinition): Promise<string>
    {
        return new Promise((resolve, reject) =>
        {
            if (!card.Product._DataPriceHistory)
            {
                // Not history, try to download them
                this.apiService.getPriceHistory(card.Product.Id).subscribe((history: PriceHistoryDefinition) =>
                {
                    if (history.DataPriceHistory && history.DataPriceHistory.length > 0)
                    {
                        // Data downloaded, cached in _...
                        card.Product._LabelsPriceHistory = history.LabelsPriceHistory;
                        card.Product._DataPriceHistory = history.DataPriceHistory;
                        card.Product._MarketPriceHistory = history.MarketPriceHistory;
                        card.Product._DataPricePredict = history.DataPricePredict;
                        card.Product._CurrencySymbol = history.CurrencySymbol;
                        card.Product._Wstd = history.Wstd;
                        card.Product._Wstdp = history.Wstdp;

                        resolve("downloaded");
                    }

                    // No data available
                    reject("NoDataFounded");
                });
            }
            else
            {
                // Data already downloaded
                resolve("cached");
            }
        });
    }

    public isCardForPrime(card: ResultDefinition): boolean {
        const timeDifference = new Date().getTime() - new Date(card.Product.LastUpdate).getTime();
        const hoursDifference: number = timeDifference / (1000 * 60 * 60);
        //console.log("HoursDiff: ", card.Product.LastUpdate);
        return hoursDifference < 24;
    }

    private roundToKorM(count: number): string
    {
        if (count >= 1000000)
        {
            return Math.floor(count / 1000000) + "M";
        }
        else if (count >= 1000)
        {
            return Math.floor(count / 1000) + "K";
        }
        else
        {
            return count.toString();
        }
    }
}
