import { ResultDefinition } from "./Serialization/ResultsDefinition";

export class ShareModal
{
    isModalOpen: boolean;
    card: ResultDefinition | null;

    constructor(opn: boolean, cd: ResultDefinition | null)
    {
        this.isModalOpen = opn;
        this.card = cd;
    }
}

export class ProductModal
{
    isModalOpen: boolean;
    card: ResultDefinition | null;

    constructor(opn: boolean, cd: ResultDefinition | null)
    {
        this.isModalOpen = opn;
        this.card = cd;
    }
}

export class BarCodeModal
{
    isModalOpen: boolean;

    constructor(opn: boolean)
    {
        this.isModalOpen = opn;
    }
}

export class SearchModal
{
    isModalOpen: boolean;

    constructor(opn: boolean)
    {
        this.isModalOpen = opn;
    }
}

export class AuthModal
{
    isModalOpen: boolean;
    isSessionExpired: boolean;

    constructor(opn: boolean, exp: boolean)
    {
        this.isModalOpen = opn;
        this.isSessionExpired = exp;
    }
}

export class InfoModal
{
    isModalOpen: boolean;
    title: string;
    description: string;
    usePrimeBtn: boolean;
    
    constructor(opn: boolean, tlt: string, desc: string, usePrime: boolean)
    {
        this.isModalOpen = opn;
        this.title = tlt;
        this.description = desc;
        this.usePrimeBtn = usePrime;
    }
}

export class ReviewModal
{
    isModalOpen: boolean;
    idRate: number;
    
    constructor(opn: boolean, rate: number)
    {
        this.isModalOpen = opn;
        this.idRate = rate;
    }
}

export class ContactModal
{
    isModalOpen: boolean;
    
    constructor(opn: boolean)
    {
        this.isModalOpen = opn;
    }
}