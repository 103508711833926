
import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { CardService } from '../../services/card.service';
import { LogEventService } from '../../services/logevent.service';
import { ModalService } from '../../services/modal.service';
import { NavigatorService } from '../../services/navigator.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit
{
    @Input() card: ResultDefinition | null = null;
    
    constructor(public authService: AuthService, public cardService: CardService, private modalService: ModalService, 
                private apiService: ApiService, private navigatorService: NavigatorService, private logEvent: LogEventService)
    {
    }

    ngOnInit(): void
    {
    }

    openCard(card: ResultDefinition)
    {
        this.logEvent.sendEventView();
        this.apiService.view(card).subscribe(result => 
        {
            this.card!.Product.Counters = result.Counters;
        });

        this.modalService.openProductModal(card);
    }

    onUpgrade(): void
    {
        this.navigatorService.navigateToProfile();
    }

    onMarketPlaceClick(): void
    {
        if (this.card)
        {
            this.cardService.getPriceHistory(this.card).then((resolve) =>
            {
                if (this.card)
                {
                    this.card.Product._ToggleHistory = !this.card.Product._ToggleHistory;
                }
            }, onreject => {
                console.error(onreject);
            });
        }
    }
}
