import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReviewModal } from '../../interfaces/modal';
import { ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'modal-review',
    templateUrl: './modal-review.component.html',
    styleUrls: ['./modal-review.component.scss']
})
export class ModalReviewComponent implements OnInit, OnDestroy
{
    get reviewform() { return this.reviewForm.controls; }
    reviewForm: FormGroup;

    private modalSubscription: Subscription | undefined;

    reviewModal: ReviewModal | undefined;
    
    reviewSubmittedForm: boolean = false;
    reviewThankYou: boolean = false;

    constructor(private modalService: ModalService, private formBuilder : FormBuilder, private apiService: ApiService)
    {
        this.reviewForm = this.formBuilder.group(
        {
            review: ['', [Validators.required, Validators.minLength(10)]],
        });
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onReviewModalChange().subscribe((modalData: any) =>
        {
            this.reviewModal = modalData;
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    onCloseClick(): void {
        this.modalService.closeReviewModal();
    }

    onInsideClick(e: Event) {
        e.stopPropagation();
    }

    onReviewSubmit(): void
    {
        this.reviewSubmittedForm = true;

        if (this.reviewForm.valid && this.reviewModal)
        {
            this.apiService.createReview(this.reviewModal.idRate, this.reviewForm.value.review).subscribe(() =>
            {
                this.reviewThankYou = true;
            });
        }
    }
}
