import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactModal } from '../../interfaces/modal';
import { ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'modal-contact',
    templateUrl: './modal-contact.component.html',
    styleUrls: ['./modal-contact.component.scss']
})
export class ModalContactComponent implements OnInit, OnDestroy
{
    get contactform() { return this.contactForm.controls; }
    contactForm: FormGroup;

    private modalSubscription: Subscription | undefined;

    contactModal: ContactModal | undefined;
    
    contactSubmittedForm: boolean = false;
    contactThankYou: boolean = false;

    constructor(private modalService: ModalService, private formBuilder : FormBuilder, private apiService: ApiService, private authService: AuthService)
    {
        this.contactForm = this.formBuilder.group(
        {
            contact: ['', [Validators.required, Validators.minLength(10)]],
        });
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onContactModalChange().subscribe(modalData =>
        {
            this.contactModal = modalData;
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    onCloseClick(): void {
        this.modalService.closeContactModal();
    }

    onInsideClick(e: Event) {
        e.stopPropagation();
    }

    onContactSubmit(): void
    {
        this.contactSubmittedForm = true;

        if (this.contactForm.valid && this.contactModal)
        {
            let userEmail: string = "anonymous";
            if (this.authService.isLogin && this.authService.user)
            {
                userEmail = this.authService.user.email || "anonymous";
            }
            this.apiService.createContact(userEmail, this.contactForm.value.contact).subscribe(() =>
            {
                this.contactThankYou = true;
            });
        }
    }
}
