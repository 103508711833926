import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MarkdownModule } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { BtnStandardComponent } from './components/btn-standard/btn-standard.component';
import { CardComponent } from './components/card/card.component';
import { ChipBarcodeRequestComponent } from './components/chip-barcode-request/chip-barcode-request.component';
import { ChipBarcodeComponent } from './components/chip-barcode/chip-barcode.component';
import { ChipFollowComponent } from './components/chip-follow/chip-follow.component';
import { ChipLikeComponent } from './components/chip-like/chip-like.component';
import { ChipLXComponent } from './components/chip-LX/chip-LX.component';
import { ChipMarketplaceComponent } from './components/chip-marketplace/chip-marketplace.component';
import { ChipReportComponent } from './components/chip-report/chip-report.component';
import { ChipShareComponent } from './components/chip-share/chip-share.component';
import { ChipTubeComponent } from './components/chip-tube/chip-tube.component';
import { ChipWatchComponent } from './components/chip-watch/chip-watch.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MarketLevelComponent } from './components/market-level/market-level.component';
import { ModalAuthComponent } from './components/modal-auth/modal-auth.component';
import { ModalProductComponent } from './components/modal-product/modal-product.component';
import { ModalSearchComponent } from './components/modal-search/modal-search.component';
import { ModalShareComponent } from './components/modal-share/modal-share.component';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { SubnavComponent } from './components/subnav/subnav.component';
import { ViewsComponent } from './components/views/views.component';
import { AboutComponent } from './pages/about/about.component';
import { CategoryComponent } from './pages/category/category.component';
import { DealsComponent } from './pages/deals/deals.component';
import { MainComponent } from './pages/main/main.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ThankYouComponent } from './pages/thanks/thanks.component';
import { PlanSuccessComponent } from './pages/plan-success/plan-success.component';
import { ProductComponent } from './pages/product/product.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TvComponent } from './pages/tv/tv.component';
import { TranslatePipe } from './pipe/translate.pipe';
import { environment } from '../environments/environment';
import { ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { ApiService } from './services/api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AngularFireModule } from '@angular/fire/compat';
import { FirebaseService } from './services/firebase.service';
import { DarkModeService } from './services/dark-mode.service';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { ModalReviewComponent } from './components/modal-review/modal-review.component';
import { ModalContactComponent } from './components/modal-contact/modal-contact.component';
import { RatingComponent } from './components/rating/rating.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { RegionNotOpenComponent } from './pages/region-not-open/region-not-open.component';
import { HeroComponent } from './components/hero/hero.component';

import { TermsComponent } from './pages/terms/terms.component';
import { CookieComponent } from './pages/cookie/cookie.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { SafeMarkPipe } from './pipe/safeMark.pipe';
import { HistoricComponent } from './components/historic/historic.component';
import { DemoComponent } from './pages/demo/demo.component';
import { HelpComponent } from './pages/help/help.component';
import { InfoComponent } from './pages/info/info.component';
import { FeaturesComponent } from './pages/features/features.component';
import { B2bComponent } from './pages/b2b/b2b.component';
import { ContactComponent } from './pages/contact/contact.component';
import { SeeyouComponent } from './pages/seeyou/seeyou.component';
import { AppRouteReuseStrategy } from './app-routing.strategy';
import { RouteReuseStrategy } from '@angular/router';
import { I18nPricePipe } from './pipe/i18nPrice.pipe';
import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { FoundbyComponent } from './components/foundby/foundby.component';
import { DateLocalPipe } from './pipe/dateLocal.pipe';
import { CardTubeComponent } from './components/card-tube/card-tube.component';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        TranslatePipe,
        SafeMarkPipe,
        I18nPricePipe,
        DateLocalPipe,
        
        MainComponent,
        HeaderComponent,
        BtnStandardComponent,
        DealsComponent,
        MessagesComponent,
        ProfileComponent,
        ProductComponent,
        ModalProductComponent,
        FiltersComponent,
        ChipWatchComponent,
        ChipFollowComponent,
        ChipLikeComponent,
        AboutComponent,
        SubnavComponent,
        ChipLXComponent,
        ViewsComponent,
        CardComponent,
        ChipShareComponent,
        ModalShareComponent,
        FooterComponent,
        ChipReportComponent,
        TvComponent,
        ChipTubeComponent,
        PlanSuccessComponent,
        PageNotFoundComponent,
        ChipBarcodeComponent,
        ChipBarcodeRequestComponent,
        ChipMarketplaceComponent,
        ModalSearchComponent,
        ModalAuthComponent,
        MarketLevelComponent,
        NavigatorComponent,
        CategoryComponent,
        ModalInfoComponent,
        RegionNotOpenComponent,
        HeroComponent,
        ThankYouComponent,
        TermsComponent,
        CookieComponent,
        PrivacyComponent,
        RatingComponent,
        ModalReviewComponent,
        ModalContactComponent,
        HistoricComponent,
        DemoComponent,
        PricingComponent,
        HelpComponent,
        InfoComponent,
        FeaturesComponent,
        B2bComponent,
        ContactComponent,
        SeeyouComponent,
        AffiliateComponent,
        FoundbyComponent,
        CardTubeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,

        ShareButtonsModule,
        ShareIconsModule,
        
        BarcodeScannerLivestreamModule,
        
        AngularFireModule.initializeApp(environment.firebase),

        MarkdownModule.forRoot(),
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: (firebaseService: FirebaseService) => () => firebaseService.initializeApp(), deps: [FirebaseService], multi: true },
        { provide: APP_INITIALIZER, useFactory: (apiService: ApiService) => () => apiService.loadConfig(), deps: [ApiService], multi: true },
        { provide: LOCALE_ID, useValue: "fr-FR" },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
        ScreenTrackingService,
        UserTrackingService,
        DarkModeService
    ],
    bootstrap: [
        MainComponent
    ]
})
export class AppModule { }
