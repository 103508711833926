
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../services/modal.service';
import { NavigatorService } from '../services/navigator.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Route } from '../interfaces/route';

@Injectable({ providedIn: 'root' })
export class VerifyUserLoginMandatoryGuard 
{
    constructor(private authService: AuthService, private modalService: ModalService, private navigatorService: NavigatorService)
    {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return new Observable<boolean>((observer) =>
        {
            // Refresh le token prime
            const refreshToken: boolean = route.url.toString() === Route.PlanSuccess;

            // Attente que les données de l'utilisateur soit chargées pour récupérer l'état connecté / prime / le profile, ...
            this.authService.getCurrentUserWithProfile(refreshToken).then(() =>
            {
                if (this.authService.isLogin)
                {
                    observer.next(true);
                    observer.complete();
                }
                else
                {
                    this.navigatorService.navigateToHome();
                    
                    this.modalService.openAuthModal();
                    observer.next(false);
                    observer.complete();
                }
            });
        });
    }
}