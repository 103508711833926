
<div class="subnav position-relative">

    <div class="container-fluid px-4">
        <div class="row flex-column-reverse flex-lg-row">

            <div class="col">

                <div class="d-flex flex-column justify-content-evenly py-2">

                    <div class="" style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                        <ol class="breadcrumb typo-breadcrumb">
                          <li class="breadcrumb-item" [ngClass]="{ 'active': productService.L0.Id !== 'all' }" (click)="onHomeClicked()">{{ origin | translate }}</li>
                          <li *ngIf="isSearchMode" class="breadcrumb-item">{{ 'wap1_c_subnav_searchResult' | translate }} {{ searchInputUser }}</li>
                          <li *ngIf="isTitleMode" class="breadcrumb-item">{{ title | translate }}</li>
                          <li *ngIf="!isTitleMode && productService.L0.Id !== 'all'" [ngClass]="{ 'active': productService.L1.Id !== 'all' }" class="breadcrumb-item" (click)="onL0Clicked(productService.L0)">{{ productService.L0.Id | translate }}</li>
                          <li *ngIf="!isTitleMode && productService.L1.Id !== 'all'" class="breadcrumb-item" (click)="onLXClicked(productService.L1)">{{ productService.L1.Id | translate }}</li>
                          <li *ngIf="!isTitleMode && productService.L3.Id !== 'all'" class="breadcrumb-item" (click)="onLXClicked(productService.L3)">{{ productService.L3.Id | translate }}</li>
                        </ol>
                    </div>
                    
                    <div *ngIf="!isSearchMode && !isTitleMode">
                        <div *ngIf="useL0Chip" class="d-flex flex-row align-items-center overflow-x-auto pb-1">
                            <ng-container *ngFor="let L0 of L0_Array; let first = first; let last = last">
                                <chip-LX [ngClass]="{ 'me-1' : first, 'ms-1': last, 'mx-1': (!first && !last) }" [LX]="L0" [isL0]="true" (onChipLXClicked)="onL0Clicked($event)"></chip-LX>
                            </ng-container>
                        </div>
                        
                        <div *ngIf="useLXChip" class="d-flex flex-row align-items-center overflow-x-auto pb-1">                        
                            <ng-container *ngFor="let LX of LX_Array; let first = first; let last = last">
                                <chip-LX [ngClass]="{ 'me-1' : first, 'ms-1': last, 'mx-1': (!first && !last) }" [LX]="LX" [isL0]="false" (onChipLXClicked)="onLXClicked($event)"></chip-LX>
                            </ng-container>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>

</div>