import { Component } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {


  constructor(public i18nService: I18nService, private router: Router) { }

  onPricingClick(): void {
    const { Locale } = this.i18nService;
    this.router.navigateByUrl(`/${Locale}/checkout`);
  }
}
