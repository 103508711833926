<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
	<!-- SECTION HERO + SUBNAV -->
	<subnav [isTitleMode]="true" title="{{ 'wap1_c_header_profile' | translate }}"></subnav>

	<div class="container-fluid px-4">
		<div class="row my-5">
			<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
			<div class="col-12 col-lg-8 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_p_pricing_section1' | translate | safeMark">
					</div>
					<br>
					<div class="d-flex justify-content-center">
						<btn-standard (click)="onPricingClick()">{{ 'wap1_p_pricing_buyNow' | translate}}</btn-standard>
					</div>
				</div>
			</div>
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
		</div>

		<div class="row my-5">
			<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
			<div class="col-12 col-lg-8 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_p_pricing_section2' | translate | safeMark">
					</div>
					<br>
					<div class="d-flex justify-content-center">
						<btn-standard (click)="onPricingClick()">{{ 'wap1_p_pricing_buyNow' | translate}}</btn-standard>
					</div>
				</div>
			</div>
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>

		</div>



		<div class="row my-5">
			<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
			<div class="col-12 col-lg-8 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_p_pricing_section3' | translate | safeMark">
					</div>
					<br>
					<div class="d-flex justify-content-center">
						<btn-standard (click)="onPricingClick()">{{ 'wap1_p_pricing_buyNow' | translate}}</btn-standard>
					</div>

				</div>
			</div>
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
		</div>

		<div class="row my-5">
			<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
			<div class="col-12 col-lg-8 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
					</div>
				</div>
			</div>
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
		</div>
	</div>
	
	<hr>
	<footer></footer>
</div>