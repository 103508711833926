import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '../../services/i18n.service';
import { ExtensionService } from '../../services/extension.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent {
  locale: string;

  useOurExtension: boolean = false;

  browserData: { name: string, url: string } | undefined;

  constructor(public i18nService: I18nService, private router: Router, private extensionService: ExtensionService) {
    // Extract the locale from the URL
    const urlSegments = this.router.url.split('/');
    if (urlSegments && urlSegments.length > 1) {
      this.locale = urlSegments[1];
    } else {
      this.locale = 'en-us';  // default locale
    }

    this.extensionService.isPluginInstalled().then(() => {
      this.useOurExtension = true;
    }).catch(err => {});
    if (environment.env_type === "dev" || environment.env_type === "dev_local") {
      //this.useOurExtension = true;
    }
    this.browserData = this.getBrowserData();
  }

  getImagePath(originalLink: string): string {
    // Extract the file extension
    const fileExtension = originalLink.split('.').pop();

    // Extract the base part of the link without the _en suffix and file extension
    const baseLinkWithoutLocale = originalLink.substring(0, originalLink.lastIndexOf('_en'));

    // Determine the locale suffix
    let imageLocale = 'en';  // default locale
    const detectedLocale = this.locale.substring(0, 2).toLowerCase(); // get the first two characters and make them uppercase
    if (environment.languages.includes(detectedLocale)) {
      imageLocale = detectedLocale;
    }

    // Construct the locale-specific link with original file extension
    const localeSpecificLink = `${baseLinkWithoutLocale}_${imageLocale}.${fileExtension}`;

    return localeSpecificLink;
  }

  onJoinClick(): void {
    const { Locale } = this.i18nService;
    this.router.navigateByUrl(`/${Locale}/deals;auth=true`);
  }
  onPricingClick(): void {
    const { Locale } = this.i18nService;
    this.router.navigateByUrl(`/${Locale}/pricing`);
  }

  onRatingClick(): void {
    window.open(environment.web_store_url["chrome"], "_blank");
  }

  getBrowserData(): { name: string, url: string } | undefined {
    let userAgent = navigator.userAgent;

    if (userAgent.match(/opr\//i)) {
      //return { name: "opera", url: environment.web_store_url["opera"] };
      return undefined;
    }
    else if (userAgent.match(/firefox/i)) {
      //return { name: "firefox", url: environment.web_store_url["firefox"] };
      return undefined;
    }
    else if (userAgent.match(/edg/i)) {
      return { name: "edge", url: environment.web_store_url["edge"] };
    }
    else if (userAgent.match(/chrome/i)) {
      return { name: "chrome", url: environment.web_store_url["chrome"] };
    }
    // Keep Safari at the end ! It is everywhere.
    else if (userAgent.match(/safari/i)) {
      //return { name: "safari", url: environment.web_store_url["safari"] };
      return undefined;
    }
    else {
      return undefined;
    }
  }
}