
import { Injectable } from '@angular/core';
import { GoogleAuthProvider, getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService
{
    constructor()
    {
    }

    public initializeApp(): Promise<void>
    {
        // Appelé pour attendre l'authentification de l'utilisateur afin d'avoir l'intercepteur avec le token 
        return new Promise((resolve, reject) => {
            getAuth().onAuthStateChanged((user) => {
                resolve();
            });
        });
    }
}
