<ng-container *ngIf="card && card.Product">

    <div class="product d-flex flex-column" [ngClass]="{ 'prime': cardService.isCardForPrime(card) }">
        
        <!-- Found by -->
        <div class="d-flex flex-row justify-content-between align-items-center p-2">
            <foundby [card]="card"></foundby>
            <chip-follow [card]="card" [followType]="3"></chip-follow>
        </div>

        <!-- Images -->
        <div class="position-relative" (click)="openCard(card)">
            <div class="aspect-ratio-container cursor-pointer" [ngClass]="{ 'bg-color-lavender': card.Product.Tags.Selfridges }">
                <img [ngClass]="{'fadded': card.Product._ToggleHistory }" [src]="cardService.getPrimeImageUrl(card)" />
            </div>            
        </div>
                
        <!-- Title -->
        <div class="px-3 d-flex flex-row align-items-center mt-2">
            <div class="typo-regular-black h-limit">{{ card.Product.DescMedia }}</div>
        </div>
        
        <!-- Infos product -->
        <div class="d-flex flex-column justify-content-between h-100">
            <div class="d-flex flex-row justify-content-between align-items-center mt-2 px-3">
                <div *ngIf="cardService.getBestPrice(card)" class="d-flex flex-row align-items-center">
                    <img class="picto-medium me-2" [src]="cardService.getBestMarketstoreLogo(card)" />
                    <div class="typo-marketplace">{{ cardService.getBestMarketstore(card) }}</div>
                </div>
                <chip-marketplace *ngIf="cardService.getBestPrice(card)" [card]="card"></chip-marketplace>
            </div>
        </div>
        
        <div class="d-flex flex-row justify-content-between align-items-center p-3">
            <chip-share [card]="card"></chip-share>
            <chip-like class="mx-2" [card]="card"></chip-like>
            <chip-watch class="ms-2" [card]="card"></chip-watch>
        </div>

    </div>

</ng-container>