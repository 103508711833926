import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ApiService } from '../../services/api.service';
import { I18nService } from '../../services/i18n.service';
import { ModalService } from '../../services/modal.service';
import { ProfileService } from '../../services/profile.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip-report',
  templateUrl: './chip-report.component.html',
  styleUrls: ['./chip-report.component.scss']
})
export class ChipReportComponent implements OnInit 
{
    @Input() card: ResultDefinition | null = null;

    hasBeenReport: boolean = false;
    
    constructor(private i18Service: I18nService,private apiService: ApiService, private profileService: ProfileService, private modalService: ModalService)
    {
    }

    ngOnInit(): void
    {
    }

    onReportClick(): void
    {
        if (this.card && this.profileService.HasProfileConnected)
        {
            this.hasBeenReport = true;
            this.apiService.report(this.card);
        }
        else
        {
            this.modalService.openAuthModal();
        }
    }
}
