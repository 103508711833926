<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
	<subnav [isTitleMode]="true" title="{{ 'wap1_c_header_cookie' | translate }}"></subnav>

	<div class="container-fluid px-4">
		<div class="row my-5">
			<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
			<div class="col-12 col-lg-8 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_p_legal_cookieStatement' | translate | safeMark" ></div>
				</div>
			</div>
			<div class="col-2 d-none d-lg-block d-flex align-items-center"></div>
		</div>
	</div>

	<hr>
	<footer></footer>	
</div>

