import { L0_Definition, L1_Definition, L3_Definition } from '../../interfaces/Serialization/PagesDefinition';
import { CardService } from '../../services/card.service';
import { ProductService } from '../../services/product.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { I18nService } from '../../services/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
    L0_Array: L0_Definition[] = [];
    L3_Array: L3_Definition[] = [];

    titleBanner: string = '';

    locale: string;

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            if (this.productService.L0.Id === "brands") {
                this.productService.loadNextMyBrands();
            }
            else if (this.productService.L0.Id === "stores") {
                this.productService.loadNextMyMarketplaces();
            }
            else if (this.productService.L0.Id === "tags") {
                this.productService.loadNextMyTags();
            }
            else if (this.productService.L0.Id === "channels") {
                this.productService.loadMyChannelsNext();
            }
        }
    }

    constructor(public i18nService: I18nService, private activatedRoute: ActivatedRoute, public productService: ProductService, public cardService: CardService, private profileService: ProfileService, private router: Router) {
        this.locale = this.i18nService.Locale;
    }

    ngOnInit(): void {

        const _L0: string | null = this.activatedRoute.snapshot.paramMap.get("L0");
        const _L3: string | null = this.activatedRoute.snapshot.paramMap.get("L3");

        let L0 = _L0 ? new L0_Definition(_L0) : new L0_Definition("all");
        let L3 = _L3 ? new L3_Definition(_L3) : new L3_Definition("all");

        this.productService.L0 = L0;
        this.productService.L3 = L3;
        
        this.L0_Array = new Array<L0_Definition>();
        this.L3_Array = new Array<L3_Definition>();

        // Watches
        if (this.profileService.Watches && this.profileService.Watches.length > 0) {
            this.profileService.Watches.forEach(watch => {
                if (watch && watch.L0 !== '' && !this.L0_Array.map(l0 => l0.Id).includes(watch.L0)) {
                    let watchDef = new L0_Definition(watch.L0);
                    this.L0_Array.push(watchDef);
                }
            });
        }

        // Marketplace
        if (this.profileService.FollowMarketPlaces && this.profileService.FollowMarketPlaces.length > 0) {
            let marketPlaceL0 = new L0_Definition("stores");
            this.L0_Array.push(marketPlaceL0);
        }

        // Brands
        if (this.profileService.FollowBrands && this.profileService.FollowBrands.length > 0) {
            let brandL0 = new L0_Definition("brands");
            this.L0_Array.push(brandL0);
        }

        // Tags
        if (this.profileService.FollowTags && this.profileService.FollowTags.length > 0) {
            let tagL0 = new L0_Definition("tags");
            this.L0_Array.push(tagL0);
        }

        // Channel
        if (this.profileService.FollowChannels && this.profileService.FollowChannels.length > 0) {
            let channelL0 = new L0_Definition("channels");
            this.L0_Array.push(channelL0);
        }

        if (this.productService.L0.Id === "brands") {
            this.profileService.FollowBrands?.forEach(brand => {
                this.L3_Array.push(new L3_Definition(brand));
            });
            this.productService.loadMyBrands();
        }
        else if (this.productService.L0.Id === "stores") {
            this.profileService.FollowMarketPlaces?.forEach(market => {
                this.L3_Array.push(new L3_Definition(market));
            });
            this.productService.loadMyMarketplaces();
        }
        else if (this.productService.L0.Id === "tags") {
            this.profileService.FollowTags?.forEach(tag => {
                this.L3_Array.push(new L3_Definition(tag));
            });
            this.productService.loadMyTags();
        }
        else if (this.productService.L0.Id === "channels") {
            this.profileService.FollowChannels?.forEach(channel => {
                this.L3_Array.push(new L3_Definition(channel));
            });
            this.productService.loadMyChannels();
        }
        else {
            this.profileService.Watches?.forEach(watch => {
                if (watch && watch.L0 && watch.L0 === L0.Id)
                {
                    if (watch && watch.L3 !== '' && !this.L3_Array.map(l3 => l3.Id).includes(watch.L3)) {
                        this.L3_Array.push(new L3_Definition(watch.L3));
                    }
                }
            });
            this.productService.loadMyWatchs(this.productService.L0.Id, this.productService.L1.Id);
        }
    }

    onJoinClick(): void {
        const { Locale } = this.i18nService;
        this.router.navigateByUrl(`/${Locale}/deals;auth=true`);
    }

    getImagePath(originalLink: string): string {
        // Extract the file extension
        const fileExtension = originalLink.split('.').pop();

        // Extract the base part of the link without the _en suffix and file extension
        const baseLinkWithoutLocale = originalLink.substring(0, originalLink.lastIndexOf('_en'));

        // Determine the locale suffix
        let imageLocale = 'en';  // default locale
        const detectedLocale = this.locale.substring(0, 2).toUpperCase(); // get the first two characters and make them uppercase
        if (environment.languages.includes(detectedLocale)) {
            imageLocale = detectedLocale;
        }

        // Construct the locale-specific link with original file extension
        const localeSpecificLink = `${baseLinkWithoutLocale}_${imageLocale}.${fileExtension}`;
        return localeSpecificLink;
    }
}