import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ApiService } from '../../services/api.service';
import { CardService } from '../../services/card.service';
import { LogEventService } from '../../services/logevent.service';
import { ModalService } from '../../services/modal.service';
import { ProfileService } from '../../services/profile.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chip-watch',
  templateUrl: './chip-watch.component.html',
  styleUrls: ['./chip-watch.component.scss']
})
export class ChipWatchComponent implements OnInit
{
    @Input() card: ResultDefinition | null = null;

    watchState: number = 0;

    constructor(private apiService: ApiService, public cardService: CardService, 
                private profileService: ProfileService, private logEvent: LogEventService, private modalService: ModalService)
    {
    }

    ngOnInit(): void
    {
        if (this.card && this.profileService.HasProfileConnected)
        {
            this.watchState = this.profileService.isCardWatched(this.card);
        }
    }
    
    onWatchClick(): void
    {
        if (this.card && this.profileService.HasProfileConnected)
        {
            this.logEvent.sendEventWatch();

            if (!this.profileService.isCardWatched(this.card))
            {
                this.apiService.watch(this.card).subscribe(result =>
                {
                    this.card!.Product.Counters = result.Counters;
                });
            }
            else
            {
                this.apiService.unwatch(this.card).subscribe(result =>
                {
                    this.card!.Product.Counters = result.Counters;
                });
            }
            
            this.watchState = this.profileService.toggleCardWatch(this.card);
        }
        else
        {
            this.modalService.openAuthModal();
        }
    }
}
