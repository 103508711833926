import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({ providedIn: 'root' })
export class LogEventService
{
    constructor(private analytics: AngularFireAnalytics)
    {
    }

    public sendEventBuy(mk: string): void
    {
        this.analytics.logEvent("buy", { marketplace: mk } );
    }

    public sendEventLike(): void
    {
        this.analytics.logEvent("like");
    }

    public sendEventWatch(): void
    {
        this.analytics.logEvent("watch");
    }

    public sendEventFollow(): void
    {
        this.analytics.logEvent("follow");
    }

    public sendEventShare(): void
    {
        this.analytics.logEvent("share");
    }

    public sendEventView(): void
    {
        this.analytics.logEvent("view");
    }

    public sendEventGoElite(): void
    {
        this.analytics.logEvent("goElite");
    }

    public sendEventGoPrime(): void
    {
        this.analytics.logEvent("goPrime");
    }

    public sendEventManageSubscription(): void
    {
        this.analytics.logEvent("manageSubscription");
    }

    public sendEventSearch(kw: string): void
    {
        this.analytics.logEvent("search", { keyword : kw });
    }
    
    public sendEventBrowse(bw: string): void
    {
        this.analytics.logEvent("browse", { browse: bw });
    }

    public sendEventMyBrands(): void
    {
        this.analytics.logEvent("myBrands");
    }

    public sendEventMyMarketplace(): void
    {
        this.analytics.logEvent("myMarketplace");
    }

    public sendEventMyTags(): void
    {
        this.analytics.logEvent("myTags");
    }

    public sendEventMyChannels(): void
    {
        this.analytics.logEvent("myChannels");
    }
}