import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, forkJoin } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { LanguageDefinition, LanguageTextsDefinition, ResultLanguageTextsDefinition, TextDefinition } from '../interfaces/Serialization/LanguageDefinition';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class I18nService
{
    public readonly FALLBACK_LOCALE: string = "en-us";

    public get Locale(): string {
        return this.locale.value;
    }
    public get Language(): string {
        return this.locale.value.split('-')[0];
    }
    public get Region(): string {
        return this.locale.value.split('-')[1];
    }
    public onLocaleChange(): BehaviorSubject<string>
    {
        return this.locale;
    }
    
    languagesAvailable: LanguageDefinition[] = [];

    textsDef: LanguageTextsDefinition | undefined;

    private locale: BehaviorSubject<string> = new BehaviorSubject(this.FALLBACK_LOCALE);

    constructor(private http: HttpClient) {
    }

    public initialize(lgs: LanguageDefinition[]) {
        this.languagesAvailable = lgs;
    }

    public loadLanguage(locale: string | null): Observable<boolean> {

        const selectedLocale = locale || this.FALLBACK_LOCALE;
        this.locale.next(selectedLocale);
        let selectedLanguage: LanguageDefinition | undefined = this.languagesAvailable.find(lg => lg.Id === selectedLocale.split('-')[0]);
        
        if (!selectedLanguage) {
            console.warn("i18nService::loadLanguage: Language not found, using default 'en'.");
            selectedLanguage = this.languagesAvailable.find(lg => lg.Id === this.FALLBACK_LOCALE);
        }
        
        if (!selectedLanguage) {
            console.error("i18nService::loadLanguage: Default language not found.");
            return of(false);
        }
        
        const requestUrl: string = environment.API_URL + environment.API_BOARD + environment.API_ACTION_LOAD + environment.API_SIGNATURE + environment.API_PARAM_ID + selectedLanguage.Id_Lang;
    
        return this.http.get<ResultLanguageTextsDefinition>(requestUrl).pipe(
            mergeMap((languageTxtDef: ResultLanguageTextsDefinition) => {
                this.textsDef = languageTxtDef.result;
                return this.http.get<TextDefinition[]>('../assets/i18n/' + this.Language + '.json');
            }),
            tap((additionalTextsFromJson: TextDefinition[]) => {
                if (this.textsDef) {
                    this.textsDef.TextsApplication.push(...additionalTextsFromJson);
                }
            }),
            map(() => true),
            catchError(error => {
                console.error("Error fetching language texts:", error);
                if (selectedLanguage?.Id !== this.FALLBACK_LOCALE.split('-')[0]) {
                    console.warn("Fallback to default language.");
                    return this.loadLanguage(this.FALLBACK_LOCALE); // Recursive fallback to default language
                } else {
                    console.error("Error fetching default language texts:", error);
                    return of(false);
                }
            })            
        );
    }

    public getText(textIdName: string): string {
        if (this.textsDef) {
            const text: TextDefinition | undefined = this.textsDef.TextsApplication.find(x => x.Id === textIdName);
            if (text) {
                return text.Text;
            }
        }
        return textIdName;
    }

    public isLanguageAvailable(idLang: string): boolean {
        const lgFound: LanguageDefinition | undefined = this.languagesAvailable.find(l => l.Id === idLang);
        return lgFound !== undefined;
    }

    public isRegionAvailable(idRegi: string): boolean {
        const rgFound: string | undefined = environment.regions.find(r => r === idRegi);
        return rgFound !== undefined;
    }
}
