import { I18nService } from '../../services/i18n.service';
import { LDJsonService } from '../../services/ldjson.service';
import { NavigatorService } from '../../services/navigator.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {
  private readonly wap1_c_navigator_headName: string = "wap1_c_navigator_headName";
  private readonly head_keywords: string = "head_keywords";
  private readonly wap1_c_navigator_headDescription: string = "wap1_c_navigator_headDescription";
  private readonly wap1_c_navigator_headTitle: string = "wap1_c_navigator_headTitle";

  constructor(@Inject(DOCUMENT) private document: Document, private renderer2: Renderer2, private ldJsonService: LDJsonService, private titleService: Title,
    private metaService: Meta, private i18nService: I18nService, private router: Router, private activatedRoute: ActivatedRoute, private navigatorService: NavigatorService) {
  }

  ngOnInit(): void {
    const lgcn: string | null = this.activatedRoute.snapshot.paramMap.get('lgcn');
    if (!lgcn) {
      const lowerCaseLanguage: string = navigator.language.toLowerCase();
      this.navigatorService.navigateToLanguage(lowerCaseLanguage);
    }
    else {
      const lang: string = lgcn.split("-")[0];
      const region: string = lgcn.split("-")[1];
      const langAvailable: boolean = this.i18nService.isLanguageAvailable(lang);
      const regionAvailable: boolean= this.i18nService.isRegionAvailable(region);
      if (langAvailable && !regionAvailable)
      {
        this.navigatorService.navigateToLanguage(lang + "-us");
      }
      else if (!langAvailable && regionAvailable)
      {
        this.navigatorService.navigateToLanguage("en-" + region);
      }
      else if (!langAvailable && !regionAvailable)
      {
        this.navigatorService.navigateToLanguage("en-us");
      }
    }
    
    let linkSearch: HTMLLinkElement = this.document.createElement('link');
    let linkCanonical: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateXDef: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateEnUs: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateEsUs: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateEnCa: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateFrCa: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateEsMx: HTMLLinkElement = this.document.createElement('link');
    let linkAlternatePtBr: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateEnGb: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateFrFr: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateDeDe: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateEsEs: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateItIt: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateEnIn: HTMLLinkElement = this.document.createElement('link');
    let linkAlternateJaJp: HTMLLinkElement = this.document.createElement('link');

    this.document.getElementById("linkSearch")?.remove();
    this.document.getElementById("linkCanonical")?.remove();
    this.document.getElementById("linkAlternateXDef")?.remove();
    this.document.getElementById("linkAlternateEnUs")?.remove();
    this.document.getElementById("linkAlternateEsUs")?.remove();
    this.document.getElementById("linkAlternateEnCa")?.remove();
    this.document.getElementById("linkAlternateFrCa")?.remove();
    this.document.getElementById("linkAlternateEsMx")?.remove();
    this.document.getElementById("linkAlternatePtBr")?.remove();
    this.document.getElementById("linkAlternateEnGb")?.remove();
    this.document.getElementById("linkAlternateFrFr")?.remove();
    this.document.getElementById("linkAlternateDeDe")?.remove();
    this.document.getElementById("linkAlternateEsEs")?.remove();
    this.document.getElementById("linkAlternateItIt")?.remove();
    this.document.getElementById("linkAlternateEnIn")?.remove();
    this.document.getElementById("linkAlternateJaJp")?.remove();

    this.document.head.appendChild(linkSearch);
    this.document.head.appendChild(linkCanonical);
    this.document.head.appendChild(linkAlternateXDef);
    this.document.head.appendChild(linkAlternateEnUs);
    this.document.head.appendChild(linkAlternateEsUs);
    this.document.head.appendChild(linkAlternateFrCa);
    this.document.head.appendChild(linkAlternateEsMx);
    this.document.head.appendChild(linkAlternatePtBr);
    this.document.head.appendChild(linkAlternateEnGb);
    this.document.head.appendChild(linkAlternateFrFr);
    this.document.head.appendChild(linkAlternateDeDe);
    this.document.head.appendChild(linkAlternateEsEs);
    this.document.head.appendChild(linkAlternateItIt);
    this.document.head.appendChild(linkAlternateEnIn);
    this.document.head.appendChild(linkAlternateJaJp);

    // Title
    this.titleService.setTitle(this.i18nService.getText(this.wap1_c_navigator_headTitle));

    // Links
    linkSearch.setAttribute('id', 'linkSearch');
    linkSearch.setAttribute('rel', 'search');
    linkSearch.setAttribute('type', 'application/opensearchdescription+xml');
    linkSearch.setAttribute('href', '/osd.xml');
    linkSearch.setAttribute('title', this.i18nService.getText(this.wap1_c_navigator_headName));

    linkCanonical.setAttribute('id', 'linkCanonical');
    linkCanonical.setAttribute('rel', 'canonical');

    linkAlternateXDef.setAttribute('id', 'linkAlternateXDef');
    linkAlternateXDef.setAttribute('hreflang', 'x-default');
    linkAlternateEnCa.setAttribute('id', 'linkAlternateEnUs');
    linkAlternateEnCa.setAttribute('hreflang', 'en-us');
    linkAlternateFrCa.setAttribute('id', 'linkAlternateEsUs');
    linkAlternateFrCa.setAttribute('hreflang', 'es-us');
    linkAlternateEnCa.setAttribute('id', 'linkAlternateEnCa');
    linkAlternateEnCa.setAttribute('hreflang', 'en-ca');
    linkAlternateFrCa.setAttribute('id', 'linkAlternateFrCa');
    linkAlternateFrCa.setAttribute('hreflang', 'fr-ca');
    linkAlternateEnCa.setAttribute('id', 'linkAlternateEsMx');
    linkAlternateEnCa.setAttribute('hreflang', 'es-mx');
    linkAlternateFrCa.setAttribute('id', 'linkAlternatePtBr');
    linkAlternateFrCa.setAttribute('hreflang', 'pt-br');
    linkAlternateEnCa.setAttribute('id', 'linkAlternateEnGb');
    linkAlternateEnCa.setAttribute('hreflang', 'en-gb');
    linkAlternateFrCa.setAttribute('id', 'linkAlternateFrFr');
    linkAlternateFrCa.setAttribute('hreflang', 'fr-fr');
    linkAlternateEnCa.setAttribute('id', 'linkAlternateEsEs');
    linkAlternateEnCa.setAttribute('hreflang', 'es-es');
    linkAlternateFrCa.setAttribute('id', 'linkAlternateDeDe');
    linkAlternateFrCa.setAttribute('hreflang', 'de-de');
    linkAlternateEnCa.setAttribute('id', 'linkAlternateItIt');
    linkAlternateEnCa.setAttribute('hreflang', 'it-it');
    linkAlternateFrCa.setAttribute('id', 'linkAlternateEnIn');
    linkAlternateFrCa.setAttribute('hreflang', 'en-in');
    linkAlternateEnCa.setAttribute('id', 'linkAlternateJaJp');
    linkAlternateEnCa.setAttribute('hreflang', 'ja-jp');

    linkCanonical.setAttribute('href', environment.hostname + "/" + lgcn);
    linkAlternateXDef.setAttribute('href', environment.hostname + "/" + lgcn);
    linkAlternateEnUs.setAttribute('href', environment.hostname + "/en-us");
    linkAlternateEsUs.setAttribute('href', environment.hostname + "/es-us");
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/en-ca");
    linkAlternateFrCa.setAttribute('href', environment.hostname + "/fr-ca");
    linkAlternateEsMx.setAttribute('href', environment.hostname + "/es-mx");
    linkAlternatePtBr.setAttribute('href', environment.hostname + "/pt-br");
    linkAlternateEnGb.setAttribute('href', environment.hostname + "/en-gb");
    linkAlternateFrFr.setAttribute('href', environment.hostname + "/fr-fr");
    linkAlternateEsEs.setAttribute('href', environment.hostname + "/es-es");
    linkAlternateDeDe.setAttribute('href', environment.hostname + "/de-de");
    linkAlternateItIt.setAttribute('href', environment.hostname + "/it-it");
    linkAlternateEnIn.setAttribute('href', environment.hostname + "/en-in");
    linkAlternateJaJp.setAttribute('href', environment.hostname + "/ja-jp");

    this.metaService.updateTag({ property: "og:url", content: environment.hostname + "/" + lgcn });

    // Metas
    this.metaService.updateTag({ property: "og:title", content: this.i18nService.getText(this.wap1_c_navigator_headName) });
    this.metaService.updateTag({ property: "og:site_name", content: this.i18nService.getText(this.wap1_c_navigator_headName) });
    this.metaService.updateTag({ name: "application-name", content: this.i18nService.getText(this.wap1_c_navigator_headName) });
    this.metaService.updateTag({ name: "apple-mobile-web-app-title", content: this.i18nService.getText(this.wap1_c_navigator_headName) });

    this.metaService.updateTag({ name: "keywords", content: this.i18nService.getText(this.head_keywords) });

    this.metaService.updateTag({ property: "og:description", content: this.i18nService.getText(this.wap1_c_navigator_headDescription) });
    this.metaService.updateTag({ name: "description", content: this.i18nService.getText(this.wap1_c_navigator_headDescription) });
    this.metaService.updateTag({ name: "twitter:description", content: this.i18nService.getText(this.wap1_c_navigator_headDescription) });

    this.metaService.updateTag({ property: "og:title", content: this.i18nService.getText(this.wap1_c_navigator_headTitle) });
    this.metaService.updateTag({ property: "og:image:alt", content: this.i18nService.getText(this.wap1_c_navigator_headTitle) });
    this.metaService.updateTag({ name: "twitter:title", content: this.i18nService.getText(this.wap1_c_navigator_headTitle) });
    this.metaService.updateTag({ name: "twitter:image:alt", content: this.i18nService.getText(this.wap1_c_navigator_headTitle) });

    // Meta / Link change by route
    let updatedUrl: string = "";

    let url = this.router.url;
    url = url.replace("en-us/", "");
    url = url.replace("es-us/", "");
    url = url.replace("en-ca/", "");
    url = url.replace("fr-ca/", "");
    url = url.replace("es-mx/", "");
    url = url.replace("pt-br/", "");
    url = url.replace("en-gb/", "");
    url = url.replace("fr-fr/", "");
    url = url.replace("es-es/", "");
    url = url.replace("de-de/", "");
    url = url.replace("it-it/", "");
    url = url.replace("en-in/", "");
    url = url.replace("ja-jp/", "");
    if (url.includes("L0") && url.includes("L1") && url.includes("L3")) {
      var categories = url.split(";");
      var L0 = categories[1].replace("L0=", "");
      var L1 = categories[2].replace("L1=", "");
      var L3 = categories[3].replace("L3=", "");

      updatedUrl = "/c/" + L0 + "/" + L1 + "/" + L3 + "/";
    }
    else if (url.includes(";product=")) {
      var productId = url.split(";")[1];

      updatedUrl = "/p//" + productId + "/";
    }
    else {
      updatedUrl = url;
    }

    this.metaService.updateTag({ property: "og:url", content: environment.hostname + "/" + lgcn + updatedUrl });
    linkCanonical.setAttribute('href', environment.hostname + "/" + lgcn + updatedUrl);
    linkAlternateXDef.setAttribute('href', environment.hostname + "/" + lgcn + updatedUrl);
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/en-us" + updatedUrl);
    linkAlternateFrCa.setAttribute('href', environment.hostname + "/fr-us" + updatedUrl);
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/en-ca" + updatedUrl);
    linkAlternateFrCa.setAttribute('href', environment.hostname + "/fr-ca" + updatedUrl);
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/es-mx" + updatedUrl);
    linkAlternateFrCa.setAttribute('href', environment.hostname + "/pt-br" + updatedUrl);
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/en-gb" + updatedUrl);
    linkAlternateFrCa.setAttribute('href', environment.hostname + "/fr-fr" + updatedUrl);
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/es-es" + updatedUrl);
    linkAlternateFrCa.setAttribute('href', environment.hostname + "/de-de" + updatedUrl);
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/it-it" + updatedUrl);
    linkAlternateFrCa.setAttribute('href', environment.hostname + "/en-in" + updatedUrl);
    linkAlternateEnCa.setAttribute('href', environment.hostname + "/ja-jp" + updatedUrl);


    // LD-Json
    const baseLdJson =
      [
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "@id": "https://prems.ai/#Organization",
          "brand": {
            "@type": "Brand",
            "logo": {
              "@type": "ImageObject",
              "@id": "https://prems.ai/#Logo"
            },
            "slogan": this.i18nService.getText(this.wap1_c_navigator_headTitle),
            "alternateName": this.i18nService.getText(this.wap1_c_navigator_headName),
            "description": this.i18nService.getText(this.wap1_c_navigator_headDescription),
            "name": this.i18nService.getText(this.wap1_c_navigator_headTitle)
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "areaServed": [
              "CA"
            ],
            "availableLanguage": [
              "en",
              "fr",
              "es",
              "it",
              "de",
              "ja",
              "pt"
            ],
            "contactType": "customer service",
            "email": "contact@prems.ai"
          },
          "email": "contact@prems.ai",
          "keywords": [
            "prems",
            "price drop alerts",
            "in stock alerts"
          ],
          "location": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Montreal",
              "addressRegion": "Canada"
            }
          },
          "logo": {
            "@type": "ImageObject",
            "@id": "https://prems.ai/#Logo",
            "url": "https://prems.ai/assets/images/231116/icons-512.png",
            "contentUrl": "https://prems.ai/assets/images/231116/icons-512.png",
            "caption": "prems",
            "inLanguage": "en-CA",
            "width": "512",
            "height": "512"
          },
          "alternateName": this.i18nService.getText(this.wap1_c_navigator_headName),
          "description": this.i18nService.getText(this.wap1_c_navigator_headDescription),
          "image": {
            "@type": "ImageObject",
            "@id": "https://prems.ai/#SocialImage",
            "url": "https://prems.ai/assets/images/231116/social.jpg",
            "contentUrl": "https://prems.ai/assets/images/231116/social.jpg",
            "caption": this.i18nService.getText(this.wap1_c_navigator_headTitle),
            "inLanguage": "en-CA",
            "width": "1024",
            "height": "1024"
          },
          "name": this.i18nService.getText(this.wap1_c_navigator_headTitle),
          "sameAs": [
            "https://prems.ai/"
          ],
          "url": "https://prems.ai/"
        },
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "@id": "https://prems.ai/#WebSite",
          "about": {
            "@type": "Organization",
            "@id": "https://prems.ai/#Organization"
          },
          "audience": {
            "@type": "Audience",
            "audienceType": "Mothers",
            "geographicArea": {
              "@type": "AdministrativeArea",
              "name": "US"
            }
          },
          "contentLocation": {
            "@type": "AdministrativeArea",
            "name": "Canada"
          },
          "headline": this.i18nService.getText(this.wap1_c_navigator_headDescription),
          "inLanguage": {
            "@type": "Language",
            "name": "English",
            "alternateName": "en"
          },
          "keywords": [
            "prems",
            "price drop alert",
            "in stock alert"
          ],
          "publisher": {
            "@type": "Organization",
            "@id": "https://prems.ai/#Organization"
          },
          "alternateName": this.i18nService.getText(this.wap1_c_navigator_headName),
          "description": this.i18nService.getText(this.wap1_c_navigator_headDescription),
          "image": {
            "@type": "ImageObject",
            "@id": "https://prems.ai/#SocialImage"
          },
          "name": this.i18nService.getText(this.wap1_c_navigator_headTitle),
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://prems.ai/en-us/deals;search={search_term_string}",
            "query-input": {
              "@type": "PropertyValueSpecification",
              "valueRequired": true,
              "valueMaxlength": 40,
              "valueName": "search_term_string"
            }
          },
          "sameAs": [
            "https://prems.ai/"
          ],
          "url": "https://prems.ai/"
        },
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": "https://prems.ai/#WebPage",
          "breadcrumb": {
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "item": {
                  "@type": "WebPage",
                  "@id": "https://prems.ai/",
                  "name": "Deal"
                }
              }
            ]
          },
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "@id": "https://prems.ai/#SocialImage"
          },
          "specialty": "Price drop alerts",
          "about": {
            "@type": "Organization",
            "@id": "https://prems.ai/#Organization"
          },
          "inLanguage": {
            "@type": "Language",
            "name": "English",
            "alternateName": "en"
          },
          "alternateName": this.i18nService.getText(this.wap1_c_navigator_headName),
          "description": this.i18nService.getText(this.wap1_c_navigator_headDescription),
          "image": {
            "@type": "ImageObject",
            "@id": "https://prems.ai/#SocialImage"
          },
          "name": this.i18nService.getText(this.wap1_c_navigator_headTitle),
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://prems.ai/en-us/deals;search={search_term_string}",
            "query-input": {
              "@type": "PropertyValueSpecification",
              "valueRequired": true,
              "valueMaxlength": 40,
              "valueName": "search_term_string"
            }
          },
          "sameAs": [
            "https://prems.ai/"
          ],
          "url": "https://prems.ai/"
        }
      ];

    // LD Json
    this.ldJsonService.setLdJsonBase(this.renderer2, baseLdJson);
  }
}