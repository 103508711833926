<div class="bg-color-white">
    <nav class="container-fluid px-4 header navbar-light">

        <!-- Bandeau header -->
        <div class="d-flex flex-row justify-content-between position-relative">

            <div class="d-flex flex-row align-items-center">
                <img class="btn-track mx-2"
                    [src]="isHeaderMobileOpen ? '../../../assets/images/231116/pictos/Cancel.svg' : '../../../assets/images/231116/pictos/Menu.svg'"
                    (click)="toggleMenu()" style="width:24px; height:24px;" />


                <img class="btn-track picto-medium mx-2" src="../../../assets/images/231116/pictos/SearchB.svg"
                    (click)="onSearchClick()" style="width:24px; height:24px;" />
            </div>

            <div class="d-flex flex-row align-items-center cursor-pointer">
                <img class="logo-size ms-2" src="../../../assets/images/231116/Favicon.png" (click)="onDealsClick()" />

                <div class="d-none d-lg-flex flex-column ms-2">
                    <div class="typo-bold-black">{{ 'wap1_c_header_headerTitle' | translate }}</div>
                    <div class="typo-regular-black pre-line">{{ 'wap1_c_header_headerSubTitle' | translate }}</div>
                </div>

                <div class="d-flex d-lg-none flex-column ms-2">
                    <div class="typo-title">{{ 'wap1_c_header_subt1' | translate }}</div>
                    <div class="typo-title pre-line">{{ 'wap1_c_header_subt2' | translate }}</div>
                    <div class="typo-title pre-line">{{ 'wap1_c_header_subt3' | translate }}</div>
                </div>
            </div>

            <div class="d-flex flex-row align-items-center">

                <!--
                <div class="">
                    <div *ngIf="useOurExtension">Extension On !</div>
                    <div *ngIf="!useOurExtension">Extension Off :(</div>
                </div>
                -->

                <div
                    class="btn-track button-square d-flex flex-column justify-content-center align-items-center ms-2 me-4 position-relative">
                    <div *ngIf="profileService.getNbCardsWatched() == undefined || profileService.getNbCardsWatched() == 0"
                        class=" d-flex align-items-center text-center typo-disclaimer">
                        <img class="picto-medium" src="../../../assets/images/231116/pictos/Messages.svg"
                            (click)="onNotificationClick()" />
                    </div>
                    <div *ngIf="profileService.getNbCardsWatched() !== undefined && profileService.getNbCardsWatched() != 0"
                        class=" d-flex align-items-center text-center typo-disclaimer">
                        <img class="picto-medium" src="../../../assets/images/231116/pictos/Notification.svg"
                            (click)="onNotificationClick()" />
                        ({{ profileService.getNbCardsWatched() }})
                    </div>
                </div>

                <div class="position-relative">
                    <btn-standard *ngIf="!authService.isLogin" (click)="onAuthClick()">
                        {{ 'wap1_c_header_join' | translate }}
                    </btn-standard>

                    <img *ngIf="authService.isLogin" class="btn-track picto-xl icon-profile-mask"
                        src="{{ getPhotoProfile() }}" id="navbarDropdown" data-bs-toggle="dropdown"
                        aria-expanded="false" />

                    <svg *ngIf="authService.isLogin && profileService.Plan === 'Prime'" class="prime-ring" version="1.1"
                        viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m600 0c329.73 0 600 270.27 600 600s-270.27 600-600 600-600-270.27-600-600 270.27-600 600-600zm0 59.461c297.3 0 540.54 243.24 540.54 540.54s-243.24 540.54-540.54 540.54-540.54-243.24-540.54-540.54 243.24-540.54 540.54-540.54zm0 16.215c-286.49 0-524.32 237.84-524.32 524.32s237.84 524.32 524.32 524.32 524.32-237.84 524.32-524.32-237.84-524.32-524.32-524.32zm0 32.434c270.27 0 491.89 221.62 491.89 491.89s-221.62 491.89-491.89 491.89-491.89-221.62-491.89-491.89 221.62-491.89 491.89-491.89z"
                            fill="#5135FE" fill-rule="evenodd" />
                    </svg>

                    <ul class="btn-track" *ngIf="authService.isLogin" class="dropdown-menu"
                        aria-labelledby="navbarDropdown">
                        <li><a class="cursor-pointer dropdown-item" (click)="onProfileClick()">{{
                                'wap1_c_header_profile' | translate }}</a></li>
                        <li><a class="cursor-pointer dropdown-item" (click)="logout()">{{ 'wap1_c_header_logOut' |
                                translate }}</a></li>
                    </ul>
                </div>
            </div>

            <!-- Menu Absolute-->
            <div #menuOpenView *ngIf="isHeaderMobileOpen" class="position-absolute menu bg-color-lavender px-4 py-3">

                <div class="route pb-1" (click)="onDealsClick()" [ngClass]="{ 'active': activeRouteUrl === '/Deals' }">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/Today.svg" />
                    <div class="ms-2">{{ 'wap1_c_header_today' | translate }}</div>
                </div>

                <div class="route py-1" (click)="onMessagesClick()"
                    [ngClass]="{ 'active': activeRouteUrl === '/Messages' }">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/Notification.svg" />
                    <div class="ms-2">{{ 'wap1_c_header_messages' | translate }} <span
                            *ngIf="profileService.getNbCardsWatched() !== undefined">({{
                            profileService.getNbCardsWatched() }})</span></div>
                </div>

                <div class="d-flex flex-row align-items-center">
                    <hr class="dropdown-divider w-100">
                </div>

                <div class="route py-1 position-relative categories" (click)="onCategoriesClick()">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/Categories.svg" />
                    <div class="ms-2">{{ 'wap1_c_header_category' | translate }}</div>
                    <img class="ms-auto picto-small" src="../../../assets/images/231116/pictos/Go.svg" />
                </div>
                <ng-container *ngIf="isCategoriesExpanded">
                    <div class="route py-1 sub-item" *ngFor="let L0 of L0_Array; let first = first; let last = last"
                        (click)="onCategoryClick(L0)">
                        <img class="ms-3 picto-medium"
                            [src]="'../../../assets/images/231116/pictos/' + L0.Id + '.svg'" />
                        <div class="ms-2">{{ L0.Id | translate }}</div>
                    </div>
                </ng-container>

                <div class="route py-1 position-relative categories" (click)="onLanguagesClick()">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/Languages.svg" />
                    <div class="ms-2">{{ 'wap1_c_header_languages' | translate }}</div>
                    <img class="ms-auto picto-small" src="../../../assets/images/231116/pictos/Go.svg" />
                </div>
                <ng-container *ngIf="isLanguagesExpanded">
                    <div class="route py-1 sub-item" *ngFor="let lang of lang_Array; let first = first; let last = last"
                        (click)="onLanguageClick(lang)">
                        <img class="ms-3 picto-medium"
                            [src]="'../../../assets/images/231116/pictos/' + lang + '.svg'" />
                        <div class="ms-2">{{ lang | translate }}</div>
                    </div>
                </ng-container>

                <div class="route py-1 position-relative categories" (click)="onRegionsClick()">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/Regions.svg" />
                    <div class="ms-2">{{ 'wap1_c_header_regions' | translate }}</div>
                    <img class="ms-auto picto-small" src="../../../assets/images/231116/pictos/Go.svg" />
                </div>
                <ng-container *ngIf="isRegionsExpanded">
                    <div class="route py-1 sub-item"
                        *ngFor="let region of region_Array; let first = first; let last = last"
                        (click)="onRegionClick(region)">
                        <img class="ms-3 picto-medium"
                            [src]="'../../../assets/images/231116/pictos/' + region + '_.svg'" />
                        <div class="ms-2">{{ region + '_' | translate }}</div>
                    </div>
                </ng-container>

                <div class="route py-1" (click)="onInfoAboutUsClick()">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/about.svg" />
                    <div class="ms-2">{{ 'wap1_c_header_about' | translate }}</div>
                    <img class="ms-auto picto-small" src="../../../assets/images/231116/pictos/Go.svg" />
                </div>
                <ng-container *ngIf="isInfoAboutUsExpanded">
                    <div class="route py-1 sub-item" (click)="onInfoClick()">
                        <div class="ms-2">{{ 'wap1_p_info_title' | translate }}</div>
                    </div>
                    <div class="route py-1 sub-item" (click)="onFeaturesClick()">
                        <div class="ms-2">{{ 'wap1_p_features_title' | translate }}</div>
                    </div>
                    <div class="route py-1 sub-item" (click)="onPricingClick()">
                        <div class="ms-2">{{ 'wap1_c_header_pricing' | translate }}</div>
                    </div>
                    <div class="route py-1 sub-item" (click)="onHelpClick()">
                        <div class="ms-2">{{ 'wap1_p_help_title' | translate }}</div>
                    </div>
                    <div class="route py-1 sub-item" (click)="onContactClick()">
                        <div class="ms-2">{{ 'wap1_c_header_contact' | translate }}</div>
                    </div>
                </ng-container>

                <div class="route py-1" (click)="isInfoLegalClick()">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/legal.svg" />
                    <div class="ms-2">{{ 'wap1_c_header_legal' | translate }}</div>
                    <img class="ms-auto picto-small" src="../../../assets/images/231116/pictos/Go.svg" />
                </div>
                <ng-container *ngIf="isInfoLegalExpanded">
                    <div class="route py-1 sub-item" (click)="onTermClick()">
                        <div class="ms-2">{{ 'wap1_c_header_terms' | translate }}</div>
                    </div>
                    <div class="route py-1 sub-item" (click)="onCookieClick()">
                        <div class="ms-2">{{ 'wap1_c_header_cookie' | translate }}</div>
                    </div>
                    <div class="route py-1 sub-item" (click)="onPrivacyClick()">
                        <div class="ms-2">{{ 'wap1_c_header_privacy' | translate }}</div>
                    </div>
                </ng-container>
                <div class="route py-1" (click)="onRoadmapClick()">
                    <div class="ms-2">🚀 - {{ 'wap1_c_productLift_roadmap' | translate }}</div>
                </div>
                <div class="route py-1">
                    <a href="#" data-productlift-sidebar="5ac47059-1cd9-4e64-a8bb-b35ee9071015"
                        style="text-decoration: none; color: inherit;">
                        ✏️ - {{ 'wap1_c_productLift_feedbackButton' | translate }}
                    </a>
                </div>
                <div class="route py-1" (click)="onAffiliatesClick()">
                    <div class="ms-2">💵 - {{ 'wap1_p_affiliates_cta' | translate }}</div>
                </div>

                <br>
                <div class="typo-disclaimer" [innerHTML]="'wap1_m_product_affiliateDisclaimer' | translate | safeMark">
                </div>
                <div class="d-flex flex-row align-items-center typo-disclaimer">Copyright ©2024 Prems Ai. All rights
                    reserved.
                    <br>All other trademarks are the property of their respective owners.
                </div>
                <div class="d-flex flex-row align-items-center py-1">
                    <img class="picto-big" src="../../../assets/images/231116/pictos/Canada.svg" />
                    <div class="ms-2 typo-disclaimer">{{ 'wap1_c_header_canada' | translate }}</div>
                </div>
                <rating>{{ 'all1_c_rating_pleaseRate' | translate }}</rating>
            </div>
        </div>
    </nav>
</div>