import { NavigatorService } from '../../services/navigator.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent
{
    constructor(private actRoute: ActivatedRoute, private navigatorService: NavigatorService)
    {
        const productname: string | null = this.actRoute.snapshot.paramMap.get("productname");
        const productid: string | null = this.actRoute.snapshot.paramMap.get("productid");
        if (productid)
        {
            this.navigatorService.navigateToProduct(productid);
        }
    }
}
