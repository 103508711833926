
import { ResultDefinition, OfferDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { CardService } from '../../services/card.service';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'chip-marketplace',
    templateUrl: './chip-marketplace.component.html',
    styleUrls: ['./chip-marketplace.component.scss']
})
export class ChipMarketplaceComponent implements OnInit {
    @Input() offer: OfferDefinition | undefined;
    @Input() card: ResultDefinition | null = null;

    constructor(public cardService: CardService, private apiService: ApiService) {
    }

    ngOnInit(): void {
    }

    openMarketPlace(): void {
        if (this.card) {
            if (this.offer) {
                this.apiService.visit(this.card, this.offer);
                this.cardService.openOfferUrl(this.offer);
            } else {
                this.apiService.visit(this.card);
                this.cardService.openBestOffer(this.card);
            }
        }        
    }
}
