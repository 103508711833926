<div class="hero" *ngIf="!useOurExtension && browserData">
    <div class="container-fluid px-4">

        <div class="d-flex flex-row align-items-center p-3">
            <!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
            <div class="d-none d-lg-block d-flex justify-content-center align-items-center text-center">
                <img class="img-hero mx-auto" src="../../../assets/images/231116/hero.png" alt="Hero Image" />
            </div>
            <div class="d-flex align-items-center ps-0 ps-lg-5">
                <div class="w-100">
                    <h1 class="color-white pre-line">{{ 'wap1_c_hero_heroTitle' | translate }}</h1>
                    <h2 class="color-white mb-2 pre-line">{{ 'wap1_c_hero_heroDescription' | translate }}</h2>

                    <a [href]="browserData.url" target="_blank">
                        <btn-standard>{{ 'wap1_c_hero_heroAddTo_' + (browserData.name) | translate }}</btn-standard>
                    </a>
                </div>
            </div>
        </div>        
    </div>
</div>
