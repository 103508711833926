<div *ngIf="authModal" (click)="onCloseClick()"
    class="modal position-absolute w-100 h-100 align-items-center justify-content-center"
    [ngClass]="{ 'active': authModal.isModalOpen }">
    <div (click)="onInsideClick($event)" class="mod-dialog">

        <!-- Header-->
        <div class="mod-header bg-color-14 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="typo-modal-header"></div>
            <img class="mod-close mx-1" src="assets/images/231116/pictos/Close.svg" (click)="onCloseClick()" />
        </div>

        <!-- Body -->
        <div class="mod-body pb-2 px-2">

            <div class="row">
                <div class="col-12 col-lg-6 mb-3 mb-lg-0">

                    <div *ngIf="authModal.isSessionExpired"
                        class="border-around p-3 d-flex flex-column justify-content-center align-items-center">
                        <img class="logo-size ms-2" src="../../../assets/images/231116/Favicon.png" />
                        <div class="typo-regular-black text-center my-3 pre-line">{{ 'wap1_m_auth_sessionExpire' | translate }}
                        </div>
                    </div>

                    <div *ngIf="!authModal.isSessionExpired"
                        class="border-around p-3 d-flex flex-column justify-content-center align-items-center">
                        <img class="logo-size ms-2" src="../../../assets/images/231116/Favicon.png" />
                        <div class="typo-bold-black text-center my-3 pre-line">{{ 'wap1_c_header_headerTitle' | translate }}</div>
                        <div class="typo-regular-black text-center pre-line">{{ 'wap1_c_header_contentDesc' | translate }}</div>
                    </div>

                </div>

                <div class="col-12 col-lg-6">

                    <div class="border-around">
                        <div class="d-flex flex-row">
                            <div class="typo-subtitle tab text-center py-3"
                                [ngClass]="{ 'text-color-gold': authType === AuthType.SignIn || authType === AuthType.ForgotPass, 'tabs': authType !== AuthType.SignIn && authType !== AuthType.ForgotPass }"
                                (click)="onTabSignInClick()">{{ 'wap1_m_auth_logInBtn' | translate }}</div>
                            <div class="vert-separator"></div>
                            <div class="typo-subtitle tab text-center py-3"
                                [ngClass]="{ 'text-color-gold': authType === AuthType.SignUp, 'tabs': authType !== AuthType.SignUp }"
                                (click)="onTabSignUpClick()">{{ 'wap1_m_auth_signInTab' | translate }}</div>
                        </div>

                        <!-- Sign In / S'identifier -->
                        <div *ngIf="authType === AuthType.SignIn" class="p-3">

                            <div class="d-flex flex-column align-items-stretch">
                                <div class="d-flex flex-row justify-content-center align-items-center btn-auth py-2 mb-3"
                                    (click)="onLoginGoogle()">
                                    <img class="picto-medium mx-2" src="../../../assets/images/231116/pictos/Google.svg" />
                                    <div class="typo-subtitle mx-2">{{ 'wap1_m_auth_connect-viagoogle-btn' | translate }}</div>
                                </div>

                                <div class="d-flex flex-row justify-content-center align-items-center btn-auth py-2 mb-3"
                                    (click)="onLoginMicrosoft()">
                                    <img class="picto-medium mx-2" src="../../../assets/images/231116/pictos/Microsoft.svg" />
                                    <div class="typo-subtitle mx-2">{{ 'wap1_m_auth_connect-viamicrosoft-btn' | translate }}</div>
                                </div>

                                <hr class="mb-3" />
                            </div>

                            <form [formGroup]="signInForm">
                                <div class="">
                                    <div class="typo-standard-white">{{ 'wap1_m_auth_myEmail' | translate }}</div>
                                    <input class="form-control" formControlName="email">
                                    <div class="color-error"
                                        *ngIf="signInSubmittedForm && signinform['email'].errors && signinform['email'].errors['required']">
                                        {{ 'wap1_m_auth_missingEmail' | translate }}</div>
                                    <div class="color-error"
                                        *ngIf="signInSubmittedForm && signinform['email'].errors && signinform['email'].errors['email']">
                                        {{ 'wap1_m_auth_invalidEmail' | translate }}</div>
                                    <div class="color-error" *ngIf="userNotFound">{{ 'wap1_m_auth_userNotFound' | translate }}</div>
                                </div>
                                <div class="mt-3">
                                    <div class="typo-standard-white">{{ 'wap1_m_auth_myPassword' | translate }}</div>
                                    <input class="form-control" type="password" autoComplete="true" formControlName="password">
                                    <div class="color-error"
                                        *ngIf="signInSubmittedForm && signinform['password'].errors && signinform['password'].errors['required']">
                                        {{ 'wap1_m_auth_missingPassword' | translate }}</div>
                                    <div class="color-error"
                                        *ngIf="signInSubmittedForm && signinform['password'].errors && signinform['password'].errors['minlength']">
                                        {{ 'wap1_m_auth_weakPassword' | translate }}</div>
                                    <div class="color-error" *ngIf="wrongpassword">{{ 'wap1_m_auth_wrongPassword' | translate }}
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <btn-standard class="mt-3" (click)="onLoginStandard()">{{ 'wap1_m_auth_logInBtn' |
                                        translate }}</btn-standard>
                                </div>
                                <div class="mt-3">
                                    <a class="forgotPass" (click)="onForgotPassClick()">{{ 'wap1_m_auth_forgotPasswordLabel' |
                                        translate }}</a>
                                </div>
                            </form>
                        </div>

                        <!-- Sign Up / Inscription -->
                        <div *ngIf="authType === AuthType.SignUp" class="p-3">

                            <form [formGroup]="signUpForm">

                                <div class="container">

                                    <div class="row">

                                        <div class="col">
                                            <div class="typo-standard-white my-2">{{ 'wap1_m_auth_myEmail' | translate }}</div>
                                            <input class="form-control" id="email" formControlName="email">
                                            <div class="color-error"
                                                *ngIf="signUpSubmittedForm && signupform['email'].errors && signupform['email'].errors['required']">
                                                {{ 'wap1_m_auth_missingEmail' | translate }}</div>
                                            <div class="color-error"
                                                *ngIf="signUpSubmittedForm && signupform['email'].errors && signupform['email'].errors['email']">
                                                {{ 'wap1_m_auth_invalidEmail' | translate }}</div>
                                            <div class="color-error" *ngIf="errorEmailAlreadyExist">{{
                                                'wap1_c_header_emailAlreadyInUse' | translate }}</div>
                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="col">
                                            <div class="typo-standard-white my-2">{{ 'wap1_m_auth_myPassword' | translate }}
                                            </div>
                                            <input class="form-control" type="password" autoComplete="true" id="password" formControlName="password">
                                            <div class="color-error"
                                                *ngIf="signUpSubmittedForm && signupform['password'].errors && signupform['password'].errors['required']">
                                                {{ 'wap1_m_auth_missingPassword' | translate }}</div>
                                            <div class="color-error"
                                                *ngIf="signUpSubmittedForm && signupform['password'].errors && signupform['password'].errors['minlength']">
                                                {{ 'wap1_m_auth_weakPassword' | translate }}</div>
                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="col">
                                            <div class="typo-standard-white my-2">{{ 'wap1_c_profile_confirmPassword' | translate
                                                }}</div>
                                            <input class="form-control" type="password" autoComplete="true" formControlName="confirmpassword">
                                            <div class="color-error"
                                                *ngIf="signUpSubmittedForm && signupform['confirmpassword'].errors && signupform['confirmpassword'].errors['required']">
                                                {{ 'wap1_m_auth_missingPassword' | translate }}</div>
                                            <!--
                                            <div class="color-error" *ngIf="signUpSubmittedForm && signupform.hasError('notSame')">{{ 'wap1_m_auth_notIdenticalPassword' | translate }}</div>
                                            -->
                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="col">
                                            <div class="typo-standard-white my-2">{{ 'wap1_m_auth_myUsername' | translate }}
                                            </div>
                                            <input class="form-control" id="username" formControlName="username">
                                            <div class="color-error"
                                                *ngIf="signUpSubmittedForm && signupform['username'].errors && signupform['username'].errors['required']">
                                                {{ 'wap1_m_auth_missingUsername' | translate }}</div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="d-flex flex-row align-items-center typo-disclaimer mt-4 pre-line">{{
                                            'wap1_m_auth_agreeTerms' | translate }}</div>
                                        <btn-standard class="mt-2" (click)="onSignUp()">{{ 'wap1_m_auth_signInBtn' | translate
                                            }}</btn-standard>

                                    </div>

                                </div>

                            </form>

                        </div>

                        <!-- Forgot Pass -->
                        <div *ngIf="authType === AuthType.ForgotPass" class="p-3">
                            <div class="typo-subtitle mb-3">{{ 'wap1_m_auth_forgotPasswordLabel' | translate }}</div>

                            <form *ngIf="!sendForgotPassWithSuccess" [formGroup]="forgotPassForm">

                                <div class="typo-standard-white my-2">{{ 'wap1_m_auth_myEmail' | translate }}</div>
                                <input class="form-control mb-3" id="email" formControlName="email">
                                <div class="color-error" *ngIf="userNotFound">{{ 'wap1_c_header_emailNotFound' | translate }}</div>
                                <div class="color-error"
                                    *ngIf="forgotSubmittedForm && forgotpassform['email'].errors && forgotpassform['email'].errors['required']">
                                    {{ 'wap1_m_auth_missingEmail' | translate }}</div>
                                <div class="color-error"
                                    *ngIf="forgotSubmittedForm && forgotpassform['email'].errors && forgotpassform['email'].errors['email']">
                                    {{ 'wap1_m_auth_invalidEmail' | translate }}</div>
                                <btn-standard (click)="onForgotPass()">{{ 'wap1_m_auth_feedbackButton' | translate }}</btn-standard>

                            </form>

                            <div *ngIf="sendForgotPassWithSuccess" class="mt-3">{{ 'wap1_m_auth_recoverPasswordMailSent' | translate
                                }}</div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>