
<div *ngIf="card" class="d-flex flex-row align-items-center">
    <img class="picto-big picto-circle" [src]="card.Product.DisplayPic ? card.Product.DisplayPic : '../../../assets/images/231116/pictos/Profile.svg'" />
    <div class="ps-2 small">
        <div>
            <span class="typo-chip">{{ 'wap1_c_card_foundBy' | translate }} </span>
            <span class="typo-regular-royal">{{ card.Product.DisplayName }}</span>
        </div>        
        <div class="typo-chip mt-1">{{ 'wap1_c_card_foundOn' | translate }} {{ card.Product.LastUpdate | dateLocal }}</div>
    </div>
</div>