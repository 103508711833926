import { NavigatorService } from '../../services/navigator.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent
{
    constructor(private actRoute: ActivatedRoute, private navigatorService: NavigatorService)
    {
        const L0: string | null = this.actRoute.snapshot.paramMap.get("L0");
        const L1: string | null = this.actRoute.snapshot.paramMap.get("L1");
        const L3: string | null = this.actRoute.snapshot.paramMap.get("L3");
        if (L0 && L1 && L3)
        {
            this.navigatorService.navigateToHome_ByCategories(L0, L1, L3);
        }
    }
}
