
<div *ngIf="productModal && card" (click)="onCloseClick()" class="modal position-absolute w-100 h-100 align-items-center justify-content-center" [ngClass]="{ 'active': productModal.isModalOpen }">
    <div (click)="onInsideClick($event)" class="mod-dialog bg-color-lavender">

        <!-- Header-->
        <div class="mod-header bg-color-14 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <img class="mod-close mx-1" src="assets/images/231116/pictos/Close.svg" (click)="onCloseClick()"/>
        </div>
        
        <!-- Body -->
        <div #scrollable class="mod-body">

            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">

                        <!-- Product -->
                        <div class="d-flex flex-column border-around mb-3">

                            <div class="position-relative overflow-hidden">
                            
                                <div class="p-3">
                                    
                                    <div class="d-flex flex-row align-items-center flex-wrap">
                                        <chip-follow class="mx-1" [card]="card" [followType]="0"></chip-follow>
                                        <chip-follow class="mx-1" [card]="card" [followType]="1" *ngIf="!isTubeCard"></chip-follow>
                                        <chip-follow class="mx-1" [card]="card" [followType]="2"></chip-follow>
                                        <chip-follow class="mx-1" [card]="card" [followType]="3" *ngIf="isTubeCard"></chip-follow>
                                    </div>
                                    <div class="typo-regular-black mb-2 overflow-ellipsis mt-2">{{ card.Product.Title }}</div>
                                </div>
                                <div class="bg-img ratio ratio-16x9" [ngClass]="{ 'bg-color-black': isTubeCard, 'bg-color-lavender': card.Product.Tags.Selfridges }" [ngStyle]="{ 'background-image': 'url(' + this.cardService.getPrimeImageUrl(card) + ')' }"></div>
                                
                                <div *ngIf="card.Product.Tags.TopRight !== undefined" class="position-absolute new-true">
                                    <img class="picto-xl" [src]="'../../../assets/images/231116/pictos/' + card.Product.Tags.TopRight + '.svg'" />
                                </div>

                                <!--
                                <div class="position-absolute luxury-true">
                                    <img class="picto-big" src="../../../assets/images/231116/pictos/LuxuryTrue.svg" />
                                </div>
                                -->
    
                                <div class="position-absolute bottom-0 start-0">
                                    <div *ngIf="!isTubeCard && card.Product.HasPriceHistory && card.Product.PriceDropLowestEver === cardService.getBestPrice(card)" class="d-flex flex-row align-items-center lowest-ever p-2"> 
                                        <img class="picto-medium" src="'../../../assets/images/231116/pictos/LowestEver.svg" />
                                        <div class="typo-lowest-ever px-2">{{ 'wap1_m_product_lowestEver' | translate }}</div>
                                    </div>
                                </div>
                            </div>
    
                            <hr class="dropdown-divider">
    
                            <div class="d-flex justify-content-center justify-content-lg-end pt-1 pb-2">
                                <chip-share [card]="card"></chip-share>
                                <chip-like class="mx-2" [card]="card"></chip-like>
                                <chip-watch class="me-0 me-lg-3" [card]="card"></chip-watch>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6">
                        
                        <!-- Price History-->
                        <div *ngIf="card.Product.HasPriceHistory && card.Product._DataPriceHistory && card.Product._DataPriceHistory.length > 0 &&
                            profileService.hasPlanRights(card.Product.PriceDropPlan) && !isTubeCard" class="border-around my-lg-0 mb-3 p-3">
                            <div class="typo-subtitle mb-3">{{ 'wap1_m_product_priceStats' | translate }}</div>

                            <historic   [labelsPriceHistory]="card.Product._LabelsPriceHistory" 
                                        [dataPriceHistory]="card.Product._DataPriceHistory"
                                        [dataPricePredict]="card.Product._DataPricePredict"
                                        [currencySymbol]="card.Product._CurrencySymbol"
                                        [wstd]="card.Product._Wstd"
                                        [wstdp]="card.Product._Wstdp"
                                        [marketPriceHistory]="card.Product._MarketPriceHistory">
                            </historic>
                            
                            <div *ngIf="cardService.getBestPrice(card)" class="square-around my-2 p-3">
                                <div class="d-flex flex-row justify-content-between mb-2">
                                    <div class="typo-regular-black">{{ 'wap1_c_header_current' | translate }}</div>
                                    <div *ngIf="cardService.getBestPrice(card)" class="typo-disclaimer">{{ 'wap1_m_product_since' | translate }} {{ card.Product.PriceDropLowestOfferDate | dateLocal }}</div>
                                </div>
                                <div class="w-75">
                                    <market-level [card]="card"></market-level>
                                </div>
                                <div class="d-flex flex-row justify-content-between align-items-center mt-2">
                                    <div class="d-flex flex-row align-items-center">
                                        <img class="picto-medium me-2" [src]="cardService.getBestMarketstoreLogo(card)" />
                                        <div class="typo-marketplace me-2">{{ cardService.getBestMarketstore(card) }}</div>
                                    </div>
                                    <chip-marketplace class="" [card]="card"></chip-marketplace>
                                </div>
                            </div>
                            
                            <div class="square-around my-2 p-2">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="typo-regular-black">{{ 'wap1_m_product_lowestEver' | translate }}</div>
                                    <div class="typo-disclaimer">{{ 'wap1_m_product_since' | translate }} {{ card.Product.PriceDropLowestEverDate | dateLocal }}</div>
                                </div>
                                <div class="mt-2 typo-price">{{ cardService.getPriceDropLowestEver(card) | i18nPrice }} {{ card.Product.CurrencySymbol }}</div>
                            </div>

                            <div class="square-around my-2 p-2">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="typo-regular-black">{{ 'wap1_m_product_average' | translate }}</div>
                                    <div class="typo-disclaimer">{{ card.Product.PriceDropAverageTrend | translate }}</div>
                                </div>
                                <div class="mt-2 typo-price">{{ cardService.getPriceDropAverage(card) | i18nPrice }} {{ card.Product.CurrencySymbol }}</div>
                            </div>
                            
                            <div *ngIf="cardService.getPriceDropHighestEver(card) as PriceDropHE" class="square-around my-2 p-2">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="typo-regular-black">{{ 'wap1_m_product_highestEver' | translate }}</div>
                                    <div class="typo-disclaimer">{{ 'wap1_m_product_since' | translate }} {{ card.Product.PriceDropHighestEverDate | dateLocal }}</div>
                                </div>
                                <div class="mt-2 typo-price">{{ PriceDropHE | i18nPrice }} {{ card.Product.CurrencySymbol }}</div>
                            </div>

                        </div>
                        
                        <!-- Price comparison -->
                        <div *ngIf="profileService.hasPlanRights(card.Product.PriceDropPlan) && !isTubeCard" class="border-around my-3 p-3">
                            <div class="typo-subtitle mb-3">{{ 'wap1_m_product_priceComparison' | translate }}</div>
                            <div class="square-around my-2 p-3" [ngClass]="{'notavailable': marketOffer.Availability !== 1 }" *ngFor="let marketOffer of card.MarketOffers; let i=index">
                                <div class="w-75">
                                    <market-level *ngIf="i == 0" [card]="card"></market-level>
                                    <div class="typo-price-2" *ngIf="i != 0 && marketOffer.Price">{{ marketOffer.Price }} {{ card.Product.CurrencySymbol }}</div>
                                </div>
                                <div class="d-flex flex-row justify-content-between align-items-center mt-2">

                                    
                                    <div class="d-flex flex-row align-items-center">
                                        <img class="picto-medium me-2" [src]="cardService.getMarketstoreLogo(marketOffer)" />
                                        <div class="typo-marketplace me-2">{{ marketOffer.MarketPlaceName }}</div>
                                    </div>

                                    <chip-marketplace class="" [card]="card" [offer]="marketOffer"></chip-marketplace>
                                </div>
                            </div>
                            <div class="typo-disclaimer" [innerHTML]="'wap1_m_product_disclaimer' | translate | safeMark"></div>
                            <div class="typo-disclaimer" [innerHTML]="'wap1_m_product_affiliateDisclaimer' | translate | safeMark"></div>
                        </div>
                        
                        <!-- Social Media -->
                        <div *ngIf="isTubeCard" class="border-around mb-lg-0 mb-3 p-3">
                            <div class="typo-subtitle mb-3">{{ 'wap1_m_product_socialMedia' | translate }}</div>
                            <div class="d-flex flex-row justify-content-center align-items-center my-2" *ngFor="let marketOffer of card.MarketOffers; let i=index">
                                <btn-standard [fixedSize]="true" [useBlack]="i !== 0" (click)="onOpenOffer(marketOffer)">{{ marketOffer.MarketPlaceName }}</btn-standard>
                            </div>
                        </div>

                        <!-- Account upgrate panel -->
                        <div *ngIf="!profileService.hasPlanRights(card.Product.PriceDropPlan)" class="border-around mb-lg-0 mb-3 p-3 bg-color-gold-wa">
                            <div class="">{{ 'wap1_m_product_upgradeTitle' | translate }}</div>

                            <div class="d-flex justify-content-center my-4">
                                <btn-standard *ngIf="!authService.isLogin" (click)="goProfile()">{{ 'wap1_m_product_upgradeLogIn' | translate }}</btn-standard>
                                <btn-standard *ngIf="authService.isLogin && card.Product.PriceDropPlan === 'Elite'" class="mx-2" (click)="goElite()">{{ 'wap1_c_profile_go-elite' | translate }}</btn-standard>
                                <btn-standard *ngIf="authService.isLogin && card.Product.PriceDropPlan === 'Prime'" class="mx-2" (click)="goPrime()">{{ 'wap1_c_profile_go-prime' | translate }}</btn-standard>
                            </div>

                            <div class="typo-title pre-line">{{ 'wap1_m_product_upgradeBody' | translate }}</div>
                        </div>
                        <div *ngIf="profileService.hasPlanRights(card.Product.PriceDropPlan)" class="d-flex justify-content-end align-items-end mt-1 mb-2">
                            <chip-report [card]="card"></chip-report>
                        </div>

                    </div>
                </div>

                <div *ngIf="!isTubeCard" class="row mt-3">
                    <div class="col-12" [ngClass]="{ 'col-md-6': similarCards && similarCards.length !== 0 }">

                        <!-- Similar Products -->
                        <div *ngIf="similarCards && similarCards.length !== 0" class="border-around mb-3 mb-lg-0 p-3">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <div class="typo-subtitle">{{ 'wap1_m_product_similarProducts' | translate }}</div>
                                <div class="typo-see-all" (click)="onSeeAllSimilar()">{{ 'wap1_m_product_seeAll' | translate }}</div>
                            </div>
    
                            <div class="mb-2" *ngFor="let card of similarCards">    
                                <card [card]="card"></card>
                            </div>
                        </div>

                    </div>

                    <div class="col-12 col-lg-6">
                        
                        <!-- YouMayAlsoLike Products -->
                        <div *ngIf="mayLikeCards && mayLikeCards.length !== 0" class="border-around mb-3 mb-lg-0 p-3">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <div class="typo-subtitle">{{ 'wap1_m_product_youMayLikeAlso' | translate }}</div>
                                <!--<div class="" (click)="onSeeAllYouMayLike()">{{ 'wap1_m_product_seeAll' | translate }}</div>-->
                            </div>
    
                            <div class="mb-2" *ngFor="let card of mayLikeCards">    
                                <card [card]="card"></card>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div *ngIf="isTubeCard && card.Product.DescMedia" class="row mb-3">
                    <div class="col-12">
                        
                        <!-- Social Media: Description -->
                        <div class="border-around mb-lg-0 mt-3 mb-3 p-3">
                            <div class="typo-subtitle mb-3">{{ 'description' | translate }}</div>
                            <div class="typo-youtube-description pre-line">{{ card.Product.DescMedia }}</div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>
</div>