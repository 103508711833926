import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ExtensionService
{
    constructor()
    {
    }

    public isPluginInstalled(): Promise<void>
    {
        return new Promise((resolve, reject) => {
            try {
                chrome.runtime.sendMessage("kjkofnpkkadbgmhliadolhlkaodcanmo", { message: "HelloExtensionFromWebsite" });
                resolve();
            }
            catch
            {
                reject();
            };
        });
    }
}