<div *ngIf="searchModal" class="modal position-absolute w-100 h-100 align-items-center justify-content-center"
    [ngClass]="{ 'active': searchModal.isModalOpen }">
    <div (click)="onInsideClick($event)" class="mod-dialog">

        <!-- Header-->
        <div class="mod-header bg-color-14 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="typo-modal-header"></div>
            <img class="mod-close mx-1" src="assets/images/231116/pictos/Close.svg" (click)="onCloseClick()" />
        </div>

        <!-- Body -->
        <div class="mod-body px-3 pb-3">

            <div class="border-around">
                <div class="d-flex flex-row">
                    <div class="typo-subtitle tab text-center py-3"
                        [ngClass]="{ 'text-color-gold': searchMode, 'tabs': !searchMode }" (click)="onTabSearchClick()">
                        {{ 'wap1_m_search_search' | translate }}</div>
                    <div class="vert-separator"></div>
                    <div class="typo-subtitle tab text-center py-3"
                        [ngClass]="{ 'text-color-gold': !searchMode, 'tabs': searchMode }"
                        (click)="onTabBarcodeClick()">{{ 'wap1_m_search_scan' | translate }}</div>
                </div>

                <div class="p-3">

                    <div *ngIf="searchMode" class="d-flex flex-row align-items-center">

                        <!--<ng-container *ngIf="authService.isPremium">-->
                        <input #searchinput [(ngModel)]="search" class="input-search px-2" type="text"
                            placeholder="{{ 'wap1_m_search_searchText' | translate }}" (keydown.enter)="startSearch()" />
                        <div class="bg-color-gold button-search d-flex justify-content-center align-items-center">
                            <img class="picto-small" src="../../../assets/images/231116/pictos/Search.svg"
                                (click)="startSearch()" />
                        </div>
                        <!--</ng-container>-->

                        <!--
                        <ng-container *ngIf="!authService.isPremium">
                            <div class="pre-line">{{ 'searchPrimeAlert' | translate }}</div>
                        </ng-container>
                        -->

                    </div>

                    <div [hidden]="searchMode" class="">
                        <div class="stretchable-area" [ngClass]="{ 'useTypeMode': useTypeMode || barcodeNotFound }">

                            <div *ngIf="!useTypeMode" class="typo-modal-header">{{ 'wap1_m_search_scanTitle' | translate }}</div>

                            <!-- Loading du scanner -->
                            <div *ngIf="!useTypeMode && !barcodeScanner.isStarted"
                                class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            <!-- Scan via camera -->
                            <barcode-scanner-livestream [hidden]="useTypeMode || barcodeNotFound"
                                [type]="['upc', 'ean']"
                                (valueChanges)="onValueChanges($event)"></barcode-scanner-livestream>

                            <!-- Search via EAN -->
                            <form *ngIf="useTypeMode && !barcodeNotFound" [formGroup]="typedBarcodeForm"
                                class="d-flex flex-column justify-content-center h-100">
                                <div class="d-flex align-items-center justify-content-center text-center">
                                    <input formControlName="barcode" class="input-search px-2" type="text"
                                        placeholder="{{ 'wap1_m_search_scanTypeTitle' | translate }}"
                                        (keydown.enter)="onSearchBarcode()" />
                                    <div
                                        class="bg-color-gold button-search d-flex justify-content-center align-items-center">
                                        <img *ngIf="!isLoading" class="picto-small"
                                            src="../../../assets/images/231116/pictos/Search.svg"
                                            (click)="onSearchBarcode()" />
                                        <div *ngIf="isLoading" class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex flex-column typo-description text-center mt-1">
                                    <div class="my-1 ms-3 color-error"
                                        *ngIf="submittedForm && form['barcode'].errors && (form['barcode'].errors['minlength'] || form['barcode'].errors['maxlength'] || form['barcode'].errors['required'])">
                                        {{ 'wap1_c_search_minMaxLengthBarcode' | translate }}</div>
                                    <div class="mt-1 ms-3 color-error"
                                        *ngIf="submittedForm && form['barcode'].errors && form['barcode'].errors['pattern']">
                                        {{ 'wap1_m_search_numericOnlyBarcode' | translate }}</div>
                                </div>
                            </form>

                            <!-- Produit non trouvé -->
                            <div *ngIf="barcodeNotFound"
                                class="d-flex flex-column align-items-center justify-content-center text-center h-100 typo-description">
                                <div>{{ 'wap1_m_search_noProductBarcode' | translate }}<br><b>{{ typedBarcodeForm.value.barcode }}</b>
                                </div>
                                <div class="mt-2">{{ 'wap1_m_search_noProductRequest' | translate }}</div>
                            </div>

                        </div>

                        <div class="d-flex align-items-center justify-content-start mt-2">
                            <chip-barcode-request *ngIf="barcodeNotFound" [ean]="typedBarcodeForm.value.barcode"
                                class="me-3"></chip-barcode-request>
                            <chip-barcode (click)="onSwitchMode()"></chip-barcode>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>
</div>