
import { SearchModal } from '../../interfaces/modal';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { BarcodeScannerLivestreamComponent } from "ngx-barcode-scanner";
import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { NavigatorService } from '../../services/navigator.service';

@Component({
    selector: 'modal-search',
    templateUrl: './modal-search.component.html',
    styleUrls: ['./modal-search.component.scss']
})
export class ModalSearchComponent implements OnInit {
    // Général
    searchModal: SearchModal | undefined;
    private modalSubscription: Subscription | undefined;
    searchMode: boolean = true;

    // Search
    search = '';

    // Barcode
    get form() { return this.typedBarcodeForm.controls; }

    @ViewChild(BarcodeScannerLivestreamComponent)
    barcodeScanner: BarcodeScannerLivestreamComponent;

    card: ResultDefinition | null = null;

    submittedForm: boolean = false;

    useTypeMode: boolean = true;
    barcodeNotFound: boolean = false;

    typedBarcode: string = '';
    typedBarcodeForm: FormGroup;

    isLoading: boolean = false;

    constructor(private modalService: ModalService, public authService: AuthService, private navigatorService: NavigatorService, private formBuilder: FormBuilder, private apiService: ApiService) {
        this.barcodeScanner = new BarcodeScannerLivestreamComponent();
        this.typedBarcodeForm = this.formBuilder.group(
            {
                barcode: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern("^[0-9]*$")]],
            });
    }

    ngOnInit(): void {
        this.modalSubscription = this.modalService.onSearchModalChange().subscribe(modalData => {
            this.searchModal = modalData;

            this.barcodeNotFound = false;
            this.useTypeMode = false;
        });
    }

    ngOnDestroy(): void {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    onCloseClick(): void {
        this.stopScanner();

        this.modalService.closeSearchModal();
    }

    onInsideClick(e: Event) {
        e.stopPropagation();
    }

    // Tabs
    onTabSearchClick(): void {
        this.barcodeNotFound = false;
        this.searchMode = true;

        this.stopScanner();
    }

    onTabBarcodeClick(): void {
        this.barcodeNotFound = false;
        this.searchMode = false;

        this.startScanner();
    }

    // Search
    startSearch(): void {
        if (this.search !== '') {
            this.modalService.closeSearchModal();

            this.navigatorService.navigateToSearch(this.search);
            this.search = '';
        }
    }

    // Barcode
    onValueChanges(result: any) {
        this.typedBarcodeForm.value.barcode = result.codeResult.code;
        this.startSearchBarcode();
    }

    onSearchBarcode(): void {
        this.submittedForm = true;

        if (this.typedBarcodeForm.valid) {
            this.startSearchBarcode();
        }
    }

    startSearchBarcode(): void {
        this.isLoading = true;

        this.apiService.scanBarcode(this.typedBarcodeForm.value.barcode.toString()).subscribe((cardDef => {
            this.isLoading = false;

            if (cardDef.results.length == 0) {
                this.barcodeNotFound = true;
            }
            else {
                const firstCard = cardDef.results[0];
                this.navigatorService.navigateToProduct(firstCard.Product.Id);
                this.modalService.closeSearchModal();
            }
        }));
    }

    onSwitchMode(): void {
        this.barcodeNotFound = false;
        this.useTypeMode = !this.useTypeMode;

        if (this.useTypeMode) {
            this.stopScanner();
        }
        else {
            this.startScanner();
        }
    }

    startScanner(): void {
        this.barcodeScanner.start();
    }

    stopScanner(): void {
        this.barcodeScanner.stop();
    }
}
