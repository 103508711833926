
import { AuthService } from '../../services/auth.service';
import { NavigatorService } from '../../services/navigator.service';
import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plan-success',
  templateUrl: './plan-success.component.html',
  styleUrls: ['./plan-success.component.scss']
})
export class PlanSuccessComponent implements OnInit
{
    constructor(public i18nService: I18nService, private router: Router, private navigatorService: NavigatorService, private authService: AuthService)
    {
    }

    ngOnInit(): void
    {
    }

    onSignIn(): void
    {
        this.navigatorService.navigateToProfile();
    }

    onPricingClick(): void {
      const { Locale } = this.i18nService;
      this.router.navigateByUrl(`/${Locale}/checkout`);
    }
}
