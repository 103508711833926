import { L0_Definition, L1_Definition, L3_Definition } from "./PagesDefinition";

export class WatchDefinition
{
    cardId: string = '';
    L0: string = '';
    L3: string = '';
    lowestOffer: number = -1;
}

export class NotificationDefinition
{
    alert: boolean = true;
    news: boolean = true;
}

export class SetupDefinition
{
    locale: string = 'en-us';
    notification: NotificationDefinition = new NotificationDefinition();
}

export class FollowBrandDefinition
{
    brandName: string = '';
}

export class FollowMarketPlaceDefinition
{
    marketPlaceName: string = '';
}

export class FollowTagsDefinition
{
    L3: L3_Definition = new L3_Definition('');
}
export class FollowChannelDefinition
{
    channel: string = '';
}

export class LikeDefinition
{
    cardId: string = '';
}

export class ProfileDefinition
{
    setup: SetupDefinition = new SetupDefinition();
    like: LikeDefinition[] = new Array<LikeDefinition>();
    watch: WatchDefinition[] = new Array<WatchDefinition>();
    followBrands: FollowBrandDefinition[] = new Array<FollowBrandDefinition>();
    followMarketPlaces: FollowMarketPlaceDefinition[] = new Array<FollowMarketPlaceDefinition>();
    followTags: FollowTagsDefinition[] = new Array<FollowTagsDefinition>();
    followChannels: FollowChannelDefinition[] = new Array<FollowChannelDefinition>();
}

export class TmpProfileDefinition
{
    profile: ProfileDefinition = new ProfileDefinition();
}

export class ResultProfileDefinition
{
    status: string = '';
    plan: string = "Free";
    result: ProfileDefinition = new ProfileDefinition();
    token: string = '';
    //result: TmpProfileDefinition = new TmpProfileDefinition();
}