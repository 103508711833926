
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../services/modal.service';
import { NavigatorService } from '../services/navigator.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class VerifyUserLoginOptionalGuard 
{
    constructor(private router: Router, private authService: AuthService)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return new Observable<boolean>((observer) =>
        {
            // Attente que les données de l'utilisateur soit chargées pour récupérer l'état connecté / prime / le profile, ...
            this.authService.getCurrentUserWithProfile().then(() =>
            {
                observer.next(true);
                observer.complete();
            });
        });
    }
}