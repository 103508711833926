<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
    <!-- SECTION HERO + SUBNAV -->
    <hero></hero>
    <subnav [isTitleMode]="true" title="wap1_p_features_title"></subnav>

    <div class="container px-4">

        <!-- SECTION HEADER -->
        <div class="row my-5">
            <div class="col-12">
                <div id="featuresList" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_featureTitle' | translate | safeMark">
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div id="feature1" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature1' | translate | safeMark">
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onPricingClick()">{{ 'wap1_p_fearures_onPricing' | translate}}</btn-standard>
                </div>
                <br>
            </div>

            <div class="col-12 col-md-7">
                <video class="w-100 rounded darker-shadow" controls
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_3_en.jpg')">
                    <source src="../../../external/videos/feature1_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div id="feature2" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature2' | translate | safeMark">
                </div>
            </div>

            <div class="col-12 col-md-7">
                <video class="w-100 rounded darker-shadow" controls
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_5_en.jpg')">
                    <source src="../../../external/videos/feature2_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div id="feature3" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature3' | translate | safeMark">
                </div>
                <br>
                <div *ngIf="!useOurExtension && browserData" class="d-flex justify-content-center">
                    <a [href]="browserData.url" target="_blank">
                        <btn-standard>{{ 'wap1_c_hero_heroAddTo_' + (browserData.name) | translate }}</btn-standard>
                    </a>
                </div>
                <br>
            </div>

            <div class="col-12 col-md-7">
                <video class="w-100 rounded darker-shadow" controls
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_2_en.jpg')">
                    <source src="../../../external/videos/feature3_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div id="feature4" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature4' | translate | safeMark">
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onPricingClick()">{{ 'wap1_p_fearures_onPricing' | translate}}</btn-standard>
                </div>
                <br>
            </div>

            <div class="col-12 col-md-7">
                <video class="w-100 rounded darker-shadow" controls
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_1_en.jpg')">
                    <source src="../../../external/videos/feature4_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div id="feature5" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature5' | translate | safeMark">
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onPricingClick()">{{ 'wap1_p_fearures_onPricing' | translate}}</btn-standard>
                </div>
                <br>
            </div>

            <div class="col-12 col-md-7">
                <video class="w-100 rounded darker-shadow" controls
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_4_en.jpg')">
                    <source src="../../../external/videos/feature5_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div id="feature6" class="markdown-container p-3"
                    [innerHTML]="'wap1_p_info_feature6' | translate | safeMark">
                </div>
                <br>
                <div class="d-flex justify-content-center">
                    <btn-standard (click)="onRatingClick()">{{ 'all1_c_rating_pleaseRate' | translate}}</btn-standard>
                </div>
                <br>
            </div>

            <div class="col-12 col-md-7">
                <video class="w-100 rounded darker-shadow" controls
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_0_en.jpg')">
                    <source src="../../../external/videos/feature6_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <!-- hereForYou -->
        <div class="row my-5 justify-content-center">
            <div class="col-12">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>

        <hr>
        <footer></footer>
    </div>
</div>