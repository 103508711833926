<div *ngIf="contactModal" (click)="onCloseClick()"
    class="modal position-fixed w-100 h-100 align-items-center justify-content-center"
    [ngClass]="{ 'active': contactModal.isModalOpen }">
    <div (click)="onInsideClick($event)" class="mod-dialog">

        <div class="mod-header bg-color-14 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2">
                <img src="assets/images/231116/Logo.png" alt="Prems AI Logo" class="my-logo">
            </div>
            <div class="typo-modal-header" [innerHTML]="'wap1_c_header_contact' | translate | safeMark"></div>
            <img class="mod-close mx-1" src="assets/images/231116/pictos/Close.svg" (click)="onCloseClick()" />
        </div>
        
        <div class="mod-body d-flex flex-column justify-content-center align-items-center p-2">

            <ng-container *ngIf="!contactThankYou">

                <form class="w-100" [formGroup]="contactForm">
                    <div class="rateText centered-content" [innerHtml]="'wap1_m_contact_title' | translate | safeMark"></div>
                    <textarea class="form-control" type="text" formControlName="contact"
                        [placeholder]="'wap1_m_contact_formGuide' | translate"></textarea>
                    <div class="color-error"
                        *ngIf="contactSubmittedForm && contactform['contact'].errors && contactform['contact'].errors['required']">
                        {{ 'wap1_m_review_formLimit' | translate }}</div>
                    <div class="color-error"
                        *ngIf="contactSubmittedForm && contactform['contact'].errors && contactform['contact'].errors['minlength']">
                        {{ 'wap1_m_review_formLimit' | translate }}</div>

                    <div class="d-flex flex-row align-items-end justify-content-end mt-3">
                        <btn-standard (click)="onContactSubmit()">{{ 'wap1_m_contact_formSubmit' | translate }}</btn-standard>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="contactThankYou">
                <div class="rateText centered-content" [innerHtml]="'wap1_m_review_formThanks' | translate | safeMark"></div>
            </ng-container>

        </div>

    </div>
</div>