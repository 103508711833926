import { ShareModal } from '../../interfaces/modal';
import { ResultDefinition } from '../../interfaces/Serialization/ResultsDefinition';
import { ModalService } from '../../services/modal.service';
import { NavigatorService } from '../../services/navigator.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'modal-share',
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss']
})
export class ModalShareComponent implements OnInit
{
    private modalSubscription: Subscription | undefined;

    shareModal: ShareModal | undefined;
    card: ResultDefinition | null = null;

    newUrl: string = environment.hostname;

    constructor(private modalService: ModalService, private navigatorService: NavigatorService)
    {
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onShareModalChange().subscribe(modalData =>
        {
            this.shareModal = modalData;
            this.card = modalData.card;

            if (this.card)
            {
                this.newUrl = this.navigatorService.getUrlFromShare(this.card.Product.BrandName, this.card.Product.Id);
            }
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }
    
    onCloseClick(): void
    {
        this.modalService.closeShareModal();
    }
    
    onInsideClick(e: Event)
    {
        e.stopPropagation();
    }
}
