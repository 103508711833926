<!-- SECTION HEADER -->
<header></header>

<div *ngIf="authService.user as user" class="overflow-y-scroll">
	<!-- SECTION HERO + SUBNAV -->
	<hero></hero>
	<subnav [isTitleMode]="true" title="{{ 'wap1_c_header_profile' | translate }}"></subnav>
	
	<div class="container g-0" >
		<div class="row mt-3 my-lg-3">
			<!-- Information personnelle -->
			<div class="col-12 col-lg-6 mb-3 mb-lg-0">
				<div class="border-around p-3">
					<div class="typo-subtitle mb-3">{{ 'wap1_c_profile_personalInfo' | translate }}</div>
					<div class="form-group row">
						<label for="form-group-displayname" class="col-sm-4">{{ 'wap1_m_auth_myUsername' | translate }}
							:</label>
						<div class="col-sm-8" id="form-group-displayname">{{ user.displayName ? user.displayName : '-'
							}}</div>
					</div>
					<div class="form-group row mt-1">
						<label for="form-group-email" class="col-sm-4">{{ 'wap1_m_auth_myEmail' | translate }}
							:
						</label>
						<div class="col-sm-8" id="form-group-email">{{ user.email }}</div>
					</div>
				</div>
			</div>
			<!-- Abonnement -->
			<div class="col-12 col-lg-6 mb-3 mb-lg-0">
				<div class="border-around p-3">
					<div class="typo-subtitle mb-3">{{ 'wap1_c_profile_membership' | translate }}</div>
					<div class="my-2 pre-line" *ngIf="profileService.Plan === 'Prime'">{{ 'wap1_c_profile_memberPrime' | translate }}</div>
					<div class="my-2 pre-line" *ngIf="profileService.Plan === 'Elite'">{{ 'wap1_c_profile_memberElite' | translate }}</div>
					<div class="my-2 pre-line" *ngIf="profileService.Plan === 'Free'">{{ 'wap1_c_profile_memberFree' | translate }}</div>
					<!--
						<btn-standard class="mx-2" *ngIf="!isGoingPlan && !authService.isPremium && !authService.isElite" (click)="goElite()">{{ 'wap1_c_profile_go-elite' | translate }}</btn-standard>
					-->
					<btn-standard class="mx-2" *ngIf="!isGoingPlan && profileService.Plan === 'Free'" (click)="goPrime()">{{ 'wap1_c_profile_go-prime' | translate }}</btn-standard>
					<btn-standard *ngIf="!isGoingPlan && profileService.Plan !== 'Free'" (click)="goManageSubscription()">{{ 'wap1_c_profile_manageSubscription' | translate }}</btn-standard>
					<div *ngIf="isGoingPlan" class="spinner d-flex justify-content-center align-items-center p-3">
						<div class="spinner-border" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<!-- Compte / Changer MDP / Supprimer Compte-->
			<div class="col-12 col-lg-6 mb-3 mb-lg-0">
				<div class="border-around p-3">
					<div class="typo-subtitle mb-3">{{ 'wap1_c_profile_loginSecurity' | translate }}</div>
					<div class="">
						<btn-standard *ngIf="!isEditingProfile && !passwordChangedSuccess" [useBlack]="true"
							(click)="onEditProfile()">{{ 'wap1_c_profile_changePassBtn' | translate }}</btn-standard>
						<div *ngIf="!isEditingProfile && passwordChangedSuccess">{{
							'wap1_c_profile_changePasswordSuccess' | translate }}</div>
						<div class="mt-2"></div>
						<btn-standard *ngIf="!isEditingProfile" [useBlack]="true" (click)="onDeleteAccount()">{{
							'wap1_c_profile_deleteAccountBtn' | translate }}</btn-standard>
						<form *ngIf="isEditingProfile" [formGroup]="editProfileForm">
							<div class="mt-3">
								<div class="typo-standard-white">{{ 'wap1_m_auth_myPassword' | translate }}</div>
								<input class="form-control" type="password" formControlName="password">
								<div class="color-error"
									*ngIf="submittedEditForm && editform['password'].errors && editform['password'].errors['required']">
									{{ 'wap1_m_auth_missingPassword' | translate }}</div>
								<div class="color-error"
									*ngIf="submittedEditForm && editform['password'].errors && editform['password'].errors['minlength']">
									{{ 'wap1_m_auth_weakPassword' | translate }}</div>
								<div class="typo-standard-white">{{ 'wap1_c_profile_confirmPassword' | translate }}
								</div>
								<input class="form-control" type="password" formControlName="confirmpassword">
								<div class="color-error"
									*ngIf="submittedEditForm && editform['confirmpassword'].errors && editform['confirmpassword'].errors['required']">
									{{ 'wap1_m_auth_missingPassword' | translate }}</div>
								<div class="color-error" *ngIf="submittedEditForm && editProfileForm.hasError('notSame')">{{ 'wap1_m_auth_notIdenticalPassword' | translate }}</div>
								<btn-standard class="mt-2" (click)="onChangePassword()">{{ 'wap1_c_profile_changePassBtn' | translate }}</btn-standard>
							</div>
						</form>
						<div class="color-error" *ngIf="requireRecentLogin">{{ 'wap1_c_profile_requiresRecentLogin' |
							translate }}</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 mb-3 mb-lg-0">
				<div class="border-around p-3">
					<div class="typo-subtitle mb-3">{{ 'wap1_c_profile_notification' | translate }}</div>
					<form [formGroup]="notificationForm">
						<div class="form-check form-switch">
							<label class="form-check-label" for="pda">{{ 'wap1_c_watch_priceDropAlerts' | translate	}}</label>
							<input class="form-check-input cursor-pointer" formControlName="alert" type="checkbox" role="switch" id="pda" />
						</div>
						<div class="form-check form-switch mt-2">
							<label class="form-check-label" for="wdf">{{ 'wap1_c_profile_weeklyDealFlyer' | translate }}</label>
							<input class="form-check-input cursor-pointer" formControlName="news" type="checkbox" role="switch" id="wdf" />
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

	<div class="container">
		<div class="row my-5">
			<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
			<div class="col-12 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
					</div>
				</div>
			</div>
		</div>
	</div>

	<hr>
	<footer></footer>
</div>

