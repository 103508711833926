import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MarkdownService } from 'ngx-markdown';

@Pipe({
  name: 'safeMark'
})
export class SafeMarkPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
    private markdownService: MarkdownService
  ) { }

  transform(value: any, args?: any[]): SafeHtml {
    if (value && value.length > 0) {
      try {
        const html = this.markdownService.parse(value || '');
        return this.sanitizer.bypassSecurityTrustHtml(html);
      } catch (error) {
        console.error("Error processing markdown:", error);
      }
    }
    return value;
  }
}