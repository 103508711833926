import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThankYouComponent implements OnInit {
  locale: string;

  constructor(public i18nService: I18nService, private router: Router) {
    // Extract the locale from the URL
    const urlSegments = this.router.url.split('/');
    if (urlSegments && urlSegments.length > 1) {
      this.locale = urlSegments[1];
    } else {
      this.locale = 'en-us';  // default locale
    }
  }

  ngOnInit(): void {
  }

  goBack() {
    this.router.navigate(['/']); //, { queryParams: { refresh: new Date().getTime() } }); // Assuming '/' is your home route
  }

  getImagePath(originalLink: string): string {
    // Extract the file extension
    const fileExtension = originalLink.split('.').pop();

    // Extract the base part of the link without the _en suffix and file extension
    const baseLinkWithoutLocale = originalLink.substring(0, originalLink.lastIndexOf('_en'));

    // Determine the locale suffix
    let imageLocale = 'en';  // default locale
    const detectedLocale = this.locale.substring(0, 2).toUpperCase(); // get the first two characters and make them uppercase
    if (environment.languages.includes(detectedLocale)) {
      imageLocale = detectedLocale;
    }

    // Construct the locale-specific link with original file extension
    const localeSpecificLink = `${baseLinkWithoutLocale}_${imageLocale}.${fileExtension}`;

    return localeSpecificLink;
  }

  onJoinClick(): void {
    const { Locale } = this.i18nService;
    this.router.navigateByUrl(`/${Locale}/deals;auth=true`);
  }
  
  onFeatureClick(featureID: string): void {
    const { Locale } = this.i18nService;
    this.router.navigateByUrl(`/${Locale}/features#${featureID}`);
  }
}