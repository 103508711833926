import { FiltersUserDefinition } from '../../interfaces/Serialization/FiltersDefinition';
import { Component, ElementRef, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit
{
    @Input() isOpen: boolean = true;
    @Output() onFilter = new EventEmitter<FiltersUserDefinition>();
    
    filtersForm: FormGroup;

    filters: FiltersUserDefinition;

    @HostListener('document:click', ['$event'])
    clickout(event: any)
    {
        // Clique en dehors du composant ferme le composant
        //if (!this.eRef.nativeElement.contains(event.target)) this.isOpen = false;
    }

    constructor(private formBuilder : FormBuilder, private eRef: ElementRef)
    {
        this.filters = {
            budgetMin: 0,
            budgetMax: 10000,
            priceSave: 0,
            sortType: 0
        }
        
        this.filtersForm = this.formBuilder.group(
        {
            budgetMin: [0],
            budgetMax: [10000],
            priceSave: [0],
            sortType: [0],
        });
    }

    ngOnInit(): void
    {
    }

    onFilterClick(): void
    {
        if (this.filtersForm.valid)
        {
            this.filters.budgetMin = this.filtersForm.value.budgetMin;
            this.filters.budgetMax = this.filtersForm.value.budgetMax;
            this.filters.priceSave = this.filtersForm.value.priceSave;
            this.filters.sortType = this.filtersForm.value.sortType;

            this.isOpen = false;
            this.onFilter.emit(this.filters);
        }
    }
}
