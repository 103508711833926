import { FollowType } from '../interfaces/followType';
import { ResultDefinition } from '../interfaces/Serialization/ResultsDefinition';
import { L1_Definition, L3_Definition } from '../interfaces/Serialization/PagesDefinition';
import { FollowBrandDefinition, FollowChannelDefinition, FollowMarketPlaceDefinition, FollowTagsDefinition, LikeDefinition, ProfileDefinition, WatchDefinition } from '../interfaces/Serialization/ProfileDefinition';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CardService } from './card.service';
import { I18nService } from './i18n.service';
import { environment } from '../../environments/environment';
import { ModalService } from './modal.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private readonly NB_WATCH_MAX_FREE: number = 10;
    private readonly NB_WATCH_MAX_PRIME: number = 50;

    public onPlanChanged(): BehaviorSubject<string> {
        return this.planSubject;
    }

    public get Plan(): string {
        return this.currentPlan;
    }
    public get UseAlerts(): boolean | undefined {
        if (this.currentProfile) return this.currentProfile.setup.notification.alert;
        return undefined;
    }
    public get UseNews(): boolean | undefined {
        if (this.currentProfile) return this.currentProfile.setup.notification.news;
        return undefined;
    }
    public get HasProfileConnected(): boolean {
        return this.currentProfile !== null;
    }
    public get Watches(): WatchDefinition[] | undefined {
        if (this.currentProfile) return this.currentProfile.watch;
        return undefined;
    }
    public get FollowBrands(): string[] | undefined {
        if (this.currentProfile) return this.currentProfile.followBrands.map((fb: FollowBrandDefinition) => fb.brandName);
        return undefined;
    }
    public get FollowMarketPlaces(): string[] | undefined {
        if (this.currentProfile) return this.currentProfile.followMarketPlaces.map((mp: FollowMarketPlaceDefinition) => mp.marketPlaceName);
        return undefined;
    }
    public get FollowTags(): string[] | undefined {
        if (this.currentProfile) return this.currentProfile.followTags.map((tg: FollowTagsDefinition) => tg.L3.Id);
        return undefined;
    }
    public get FollowChannels(): string[] | undefined {
        if (this.currentProfile) return this.currentProfile.followChannels.map((fc: FollowChannelDefinition) => fc.channel);
        return undefined;
    }

    private currentProfile: ProfileDefinition | null = null;
    private currentPlan: string = "Free";
    private plToken: string = "";
    
    planSubject = new BehaviorSubject<string>(this.currentPlan);

    constructor(private apiService: ApiService, private cardService: CardService, private i18nService: I18nService, private modalService: ModalService) {
        this.i18nService.onLocaleChange().subscribe(() => {
            if (this.currentProfile) {
                this.currentProfile.setup.locale = this.i18nService.Locale;
                this.apiService.createProfile(this.currentProfile).subscribe();
            }
        });
    }

    public createProfile(): Promise<ProfileDefinition> {
        this.currentProfile = new ProfileDefinition();
        this.currentProfile.setup.locale = this.i18nService.Locale;

        return firstValueFrom(this.apiService.createProfile(this.currentProfile));
    }

    public async connectProfile(): Promise<boolean> {
        try {
            const resultProfile = await this.apiService.getProfile().toPromise();
    
            if (!resultProfile) {
                throw new Error('Profile retrieval failed.');
            }
    
            switch (resultProfile.status) {
                case "retry":
                    return false;
            
                case "success":
                case "profileNotFound":
                    // Set the plan, defaulting to 'Free' if not provided
                    this.currentPlan = resultProfile.status === "success" && resultProfile.plan ? resultProfile.plan : 'Free';
            
                    // Set the profile, defaulting to a new ProfileDefinition if not provided
                    this.currentProfile = resultProfile.status === "success" && resultProfile.result ? resultProfile.result : new ProfileDefinition();
            
                    this.plToken = resultProfile.status === "success" && resultProfile.token ? resultProfile.token : '';

                    this.planSubject.next(this.currentPlan);

                    this.updateCookies();
                    return true;
            
                default:
                    throw new Error(`Unexpected status: ${resultProfile.status}`);
            }
            
        } catch (error) {
            console.error('Error in connectProfile:', error);
            throw error; // or handle the error as appropriate
        }
    }

    public disconnectProfile(): void {
        if (this.currentProfile) {
            this.apiService.createProfile(this.currentProfile, true).subscribe();
        }
        this.currentProfile = null;
        this.currentPlan = "Free";
        this.planSubject.next(this.currentPlan);
    }

    public deleteProfile(): Promise<void> {
        return this.apiService.deleteProfile().toPromise();
    }

    public hasPlanRights(priceDropPlan: string): boolean {
        const bool: boolean = (priceDropPlan === "Elite" && this.currentPlan === "Elite") ||
            (priceDropPlan === "Prime" && (this.currentPlan === "Prime" || this.currentPlan === "Elite") ||
                (priceDropPlan === "Free"));
        return bool;
    }

    public isCardLiked(cardDef: ResultDefinition): boolean {
        if (this.currentProfile) {
            return this.currentProfile.like.find((lk: any) => lk.cardId === cardDef.Product.Id) !== undefined;
        }
        return false;
    }

    public isCardWatched(cardDef: ResultDefinition): number {
        if (this.currentProfile) {
            const cardWatched: WatchDefinition | undefined = this.currentProfile.watch.find((wtc: any) => wtc.cardId === cardDef.Product.Id);
            const cardActualBestPrice: number | undefined = this.cardService.getBestPrice(cardDef);
            const isFlagGreenOrBlue: boolean = this.cardService.isFlagGreenOrBlue(cardDef);
            if (cardWatched) {
                if (cardActualBestPrice && cardActualBestPrice < cardWatched.lowestOffer && isFlagGreenOrBlue) {
                    return 2;
                }
                return 1;
            }
        }
        return 0;
    }

    public getNbCardsWatched(): number | undefined {
        if (this.currentProfile) {
            return this.currentProfile.watch.length;
        }
        return undefined;
    }

    public getCardIdsWatched(tag: string): WatchDefinition[] | undefined {
        if (this.currentProfile) {
            if (tag === "all") {
                return this.currentProfile.watch;
            }
            else {
                const watchList: WatchDefinition[] = this.currentProfile.watch.filter((wtc: any) => (wtc.L0 !== undefined && wtc.L0 === tag));
                return watchList;
            }
        }
        return undefined;
    }

    public toggleCardLike(cardDef: ResultDefinition): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.currentProfile) {
                if (!this.isCardLiked(cardDef)) {
                    const newLikeDef: LikeDefinition = { cardId: cardDef.Product.Id };

                    this.currentProfile.like.push(newLikeDef);
                    this.apiService.createProfile(this.currentProfile).subscribe();
                    resolve(true);
                }
                // Temporaire ? On ne peut pas se désabonner
                else {
                    this.currentProfile.like = this.currentProfile.like.filter(lk => lk.cardId !== cardDef.Product.Id);
                    this.apiService.createProfile(this.currentProfile).subscribe();
                    resolve(false);
                }
            }
        });
    }

    public toggleCardWatch(cardDef: ResultDefinition): number {
        if (this.currentProfile) {
            if (!this.isCardWatched(cardDef)) {
                if (this.currentPlan === "Free" && this.currentProfile.watch.length + 1 > this.NB_WATCH_MAX_FREE) {
                    const title = this.i18nService.getText("watchesTitle");
                    const message = this.i18nService.getText("wap1_s_profile_watchesReachFree");
                    this.modalService.openInfoModal(title, message, true);
                }
                else if (this.currentPlan === "Prime" && this.currentProfile.watch.length + 1 > this.NB_WATCH_MAX_PRIME) {
                    const title = this.i18nService.getText("watchesTitle");
                    const message = this.i18nService.getText("wap1_s_profile_watchesReachPrime");
                    this.modalService.openInfoModal(title, message, true);
                }
                else {
                    const bestPrice: number | undefined = this.cardService.getBestPrice(cardDef);
                    const newWatchDef: WatchDefinition = {
                        cardId: cardDef.Product.Id,
                        L0: cardDef.Product.Tags.L0,
                        L3: cardDef.Product.Tags.L3,
                        lowestOffer: bestPrice ? bestPrice : -1
                    };

                    this.currentProfile.watch.push(newWatchDef);
                    this.apiService.createProfile(this.currentProfile).subscribe();

                    this.updateCookies();

                    return 1;
                }

                this.updateCookies();

                return 0;
            }
            else {
                this.currentProfile.watch = this.currentProfile.watch.filter((wtc: any) => wtc.cardId !== cardDef.Product.Id);
                this.apiService.createProfile(this.currentProfile).subscribe();

                this.updateCookies();

                return 0;
            }
        }
        return 0;
    }

    public isCardFollow(cardDef: ResultDefinition, followType: FollowType): boolean {
        if (this.currentProfile) {
            if (followType === FollowType.Brands) {
                return this.currentProfile.followBrands.find((fb: any) => fb.brandName === cardDef.Product.BrandName) !== undefined;
            }
            else if (followType === FollowType.MarketPlaces) {
                return this.currentProfile.followMarketPlaces.find((fb: any) => fb.marketPlaceName === cardDef.Product.PriceDropMarketPlaceName) !== undefined;
            }
            else if (followType === FollowType.Tags) {
                return this.currentProfile.followTags.find((fb: any) => fb.L3.Id === cardDef.Product.Tags.L3) !== undefined;
            }
            else if (followType === FollowType.Channel) {
                return this.currentProfile.followChannels.find((fc: any) => fc.channel === cardDef.Product.CreatorName) !== undefined;
            }
        }
        return false;
    }

    public toggleCardFollow(cardDef: ResultDefinition, followType: FollowType): boolean {
        if (this.currentProfile) {
            if (!this.isCardFollow(cardDef, followType)) {
                if (followType === FollowType.Brands) {
                    const def: FollowBrandDefinition = { brandName: cardDef.Product.BrandName };
                    this.currentProfile.followBrands.push(def);
                }
                else if (followType === FollowType.MarketPlaces) {
                    const def: FollowMarketPlaceDefinition = { marketPlaceName: cardDef.Product.PriceDropMarketPlaceName };
                    this.currentProfile.followMarketPlaces.push(def);
                }
                else if (followType === FollowType.Tags) {
                    const def: FollowTagsDefinition = { L3: new L3_Definition(cardDef.Product.Tags.L3) };
                    this.currentProfile.followTags.push(def);
                }
                else if (followType === FollowType.Channel) {
                    const def: FollowChannelDefinition = { channel: cardDef.Product.CreatorName };
                    this.currentProfile.followChannels.push(def);
                }

                this.apiService.createProfile(this.currentProfile).subscribe();
                return true;
            }
            else {
                if (followType === FollowType.Brands) {
                    this.currentProfile.followBrands = this.currentProfile.followBrands.filter((fb: any) => fb.brandName !== cardDef.Product.BrandName);
                }
                else if (followType === FollowType.MarketPlaces) {
                    this.currentProfile.followMarketPlaces = this.currentProfile.followMarketPlaces.filter((fm: any) => fm.marketPlaceName !== cardDef.Product.PriceDropMarketPlaceName);
                }
                else if (followType === FollowType.Tags) {
                    this.currentProfile.followTags = this.currentProfile.followTags.filter((ft: any) => ft.L3.Id !== cardDef.Product.Tags.L3);
                }
                else if (followType === FollowType.Channel) {
                    this.currentProfile.followChannels = this.currentProfile.followChannels.filter((fc: any) => fc.channel !== cardDef.Product.CreatorName);
                }

                this.apiService.createProfile(this.currentProfile).subscribe();
                return false;
            }
        }
        return false;
    }

    public setAlertNews(useAlert: boolean, useNews: boolean): boolean {
        if (this.currentProfile) {
            this.currentProfile.setup.notification.alert = useAlert;
            this.currentProfile.setup.notification.news = useNews;
            this.apiService.createProfile(this.currentProfile).subscribe();

            return useAlert;
        }
        return false;
    }

    private updateCookies(): void {
        if (this.currentProfile) {
            let cookieStr: string = "";
            for (let i = 0; i < this.currentProfile.watch.length; i++) {
                cookieStr += this.currentProfile.watch[i].cardId + "-" + this.currentProfile.watch[i].lowestOffer;
                if (i !== this.currentProfile.watch.length - 1) cookieStr += "_";
            }

            localStorage.removeItem(environment.cookiename);
            localStorage.setItem(environment.cookiename, cookieStr);
        }
    }
}