<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll" (scroll)="onScroll($event)">

	<hero></hero>

	<subnav [isTitleMode]="true" [title]="'TV' | translate"></subnav>

	<views></views>

	<div *ngIf="productService.IsLoading" class="spinner text-light d-flex justify-content-center align-items-center p-3">
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
	
	<div *ngIf="!productService.IsLoading && productService.cardsLoaded.length === 0" class="d-flex justify-content-center align-items-center pre-line">
		{{ 'wap1_c_deals_noProduct' | translate }}
	</div>
	
	<div class="container-fluid px-4" (scroll)="onScroll($event)">
		<div class="row">
			<div class="col-12 col-sm-6 col-xl-3 my-3" *ngFor="let card of productService.cardsLoaded">
				<card-tube [card]="card"></card-tube>
			</div>
		</div>
	</div>
</div>

