
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ModalService } from "../services/modal.service";
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalResolver
{
    constructor(private modalService: ModalService, private authService: AuthService)
    {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    {
        return new Observable((observer) =>
        {
            if (route.params['auth'] === "true" && !this.authService.isLogin)
            {
                this.modalService.openAuthModal();
            }
            
            if (route.params['contact'] === "true")
            {
                this.modalService.openContactModal();
            }
            
            observer.next(true);
            observer.complete();
        });
    }    
}
