
<div class="filters position-absolute border-radius-std bg-color-gray-dark mt-1 p-3" [ngClass]="{ 'active': isOpen }">

    <form [formGroup]="filtersForm">
        
        <div class="d-flex flex-row justify-content-between align-items-center mb-3">
            <div class="color-white">{{ 'wap1_c_filters_sort' | translate }}</div>
            <select class="select-filters" formControlName="sortType">
                <option [ngValue]="0">{{ 'wap1_c_filters_sortLastUpdate' | translate }}</option>
                <option [ngValue]="1">{{ 'wap1_c_filters_sortPriceDown' | translate }}</option>
                <option [ngValue]="2">{{ 'wap1_c_filters_sortPriceUp' | translate }}</option>
                <option [ngValue]="3">{{ 'SortRatingDown' | translate }}</option>
                <option [ngValue]="4">{{ 'wap1_c_filters_sortRelevance' | translate }}</option>
            </select>
        </div>

        <div class="d-flex flex-row align-items-center my-3">
            <div class="color-white me-2">{{ 'wap1_c_filters_budget' | translate }} {{ 'wap1_c_filters_from' | translate }}</div>
            <input class="input-filters" type="number" formControlName="budgetMin" />
            <div class="color-white mx-2">To</div>
            <input class="input-filters" type="number" formControlName="budgetMax" />
            <div class="color-white ms-2"></div>
        </div>

        <div class="d-flex flex-row justify-content-between align-items-center my-3">
            <div class="color-white">{{ 'Discount' | translate }}</div>
            <div>
                <input class="input-filters" type="number" formControlName="priceSave" />
                <span class="color-white ms-2">%</span>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
            <btn-standard (click)="onFilterClick()">Filtrer</btn-standard>
        </div>

    </form>

</div>