<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
    <!-- SECTION HERO + SUBNAV -->
    <hero></hero>
    <subnav [isTitleMode]="true" title="Demo"></subnav>

    <div class="container">
        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100" src="https://framerusercontent.com/images/231116/NJfp3DfwEaIkErUwreM50Z02Qo.webp" />
            </div>
            <div class="col-12 col-md-5">
                <div class="markdown-container p-3 mb-3"
                    [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>

                <btn-standard>Mon bouton</btn-standard>
                <br><br>
                <btn-standard [useBlack]="true">Mon bouton</btn-standard>
                <br><br>
                <btn-standard icon="../../../assets/images/231116/pictos/Auth_SignInTab.svg">Mon bouton icon</btn-standard>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>

            <div class="col-12 col-md-7">
                <video class="w-100 rounded darker-shadow" controls
                    [poster]="getImagePath('../../../assets/images/231116/static/prems_0_en.jpg')">
                    <source src="../../../external/videos/prems_currency_en.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 col-md-7">
                <img class="w-100 img-fluid rounded darker-shadow hover-scale"
                    [src]="getImagePath('../../../assets/images/231116/static/prems_0_en.jpg')" />
            </div>

            <div class="col-12 col-md-5">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>

        <hr>
        <footer></footer>
    </div>
</div>