
<div class="views">
    
    <div class="container-fluid px-4">
        
        <!-- Titre - Filtre - Luxury -->
        <div class="row">
            <div class="col">

                <div class="d-flex flex-row justify-content-end align-items-center h-3rem">

                    <div class="check-container d-flex flex-row align-items-center p-1">
                        <input type="checkbox" id="topdeals" name="topdeals" [(ngModel)]="productService.TopDealsOnly" (click)="onTopDealsChanged()">
                        <label class="ms-2 typo-flag pre-line" for="topdeals">{{ 'wap1_c_views_topDeals' | translate }}</label>
                    </div>

                    <div class="d-flex flex-row align-items-center typo-regular-black mx-3">
                        <div class="me-1 pre-line">{{ 'wap1_c_filters_sort' | translate }}</div>
                        <select class="select-view text-uppercase px-2 py-1" (change)="onViewChanged($event)">
                            <option value="0">{{ 'wap1_c_views_viewLatestDeals' | translate }}</option>
                            <option value="4">{{ 'wap1_c_views_viewMostWatched' | translate }}</option>
                            <option value="3">{{ 'wap1_c_views_viewMostViewed' | translate }}</option>
                            <option value="1">{{ 'wap1_c_views_viewMostLiked' | translate }}</option>
                            <option value="2">{{ 'wap1_c_views_viewMostShared' | translate }}</option>
                        </select>
                    </div>

                    <!--
                    <div class="d-flex flex-row"><img class="picto-medium" src="../../../assets/images/231116/pictos/Reload.svg" (click)="onReloadClicked()" /></div>
                    -->
                </div>

            </div>
        </div>
    </div>

</div>