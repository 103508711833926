
<div *ngIf="infoModal" (click)="onCloseClick()" class="modal position-fixed w-100 h-100 align-items-center justify-content-center" [ngClass]="{ 'active': infoModal.isModalOpen }">
    <div (click)="onInsideClick($event)" class="mod-dialog">

        <!-- Header-->
        <div class="mod-header bg-color-14 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="typo-modal-header">{{ infoModal.title }}</div>
            <img class="mod-close mx-1" src="assets/images/231116/pictos/Close.svg" (click)="onCloseClick()"/>
        </div>
        
        <!-- Body -->
        <div class="mod-body d-flex flex-column justify-content-center align-items-center p-2">
            <div class="pre-line">{{ infoModal.description }}</div>
            <btn-standard class="m-3" *ngIf="infoModal.usePrimeBtn" (click)="goPrime()">{{ 'wap1_c_profile_go-prime' | translate }}</btn-standard>
        </div>

    </div>
</div>