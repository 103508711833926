
export enum Route {
    Base = '/',

    Home = "deals",
    Messages = "messages",
    Apps = "apps",
    PlanSuccess = "planSuccess",
    Profile = "profile",

    Product = "p",
    Category = "c",

    About = "about",
    PageNotFound = "pagenotfound",
    RegionNotOpen = "regionnotopen",
    ThankYou = "thanks",
    Affiliate = "affiliates",
    Terms = "terms",
    Cookie = "cookie",
    Privacy = "privacy",
    Pricing = "pricing",
    Help = "help",
    Info = "services",
    Checkout = "checkout",
    Seeyou = "seeyou",
    B2b = "b2b",
    Features = "features",
    Contact = "contact",

    Demo = "Demo"
}