<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll" (scroll)="onScroll($event)">

	<hero></hero>

	<subnav [isSearchMode]="productService.IsSearching" [searchInputUser]="productService.Keyword" 
			[L0_Array]="L0_Array" [LX_Array]="L1_Array" [useL0Chip]="productService.L0.Id === 'all'" [useLXChip]="productService.L0.Id !== 'all'"></subnav>

	<views [useLuxuryButton]="true"></views>
	
	<div *ngIf="productService.IsLoading" class="spinner text-light d-flex justify-content-center align-items-center p-3">
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
	
	<div *ngIf="!productService.IsLoading && productService.cardsLoaded.length === 0" class="d-flex justify-content-center align-items-center pre-line">
		{{ 'wap1_c_deals_noProduct' | translate }}
	</div>
	
	<div class="container-fluid px-4">
		<div class="row">
			<div class="col-12 col-sm-6 col-xl-3 my-3" *ngFor="let card of productService.cardsLoaded">
				<card *ngIf="!card.Product?.CreatorName" [card]="card"></card>
				<card-tube *ngIf="card.Product?.CreatorName" [card]="card"></card-tube>
			</div>
		</div>
	</div>

</div>
