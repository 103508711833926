
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, catchError, throwError } from 'rxjs';
import { ResultConfigDefinition } from '../interfaces/Serialization/ConfigDefinition';
import { PagesDefinition, ResultPagesDefinition } from '../interfaces/Serialization/PagesDefinition';
import { ResultDefinition, ResultsDefinition, ResultCounters, ResultUrls, OfferDefinition } from '../interfaces/Serialization/ResultsDefinition';
import { ResultLanguageTextsDefinition } from '../interfaces/Serialization/LanguageDefinition';
import { I18nService } from './i18n.service';
import { ProfileDefinition, ResultProfileDefinition } from '../interfaces/Serialization/ProfileDefinition';
import { environment } from '../../environments/environment';
import { LogEventService } from './logevent.service';
import { PriceHistoryDefinition } from '../interfaces/Serialization/PriceHistoryDefinition';

@Injectable({ providedIn: 'root' })
export class ApiService {
    public get PagesDefinition(): PagesDefinition | undefined {
        return this.pagesDefinition;
    }
    private pagesDefinition: PagesDefinition | undefined;

    constructor(private http: HttpClient, private i18nService: I18nService, private logEvent: LogEventService) {
    }

    public loadConfig(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const resultConfigDef: ResultConfigDefinition | undefined = await this.getConfig().toPromise();
                
                if (resultConfigDef && resultConfigDef.result.Id_Board) {
                    const resultBoardsDef: ResultPagesDefinition | undefined = await this.getBoardById(resultConfigDef.result.Id_Board).toPromise();
                    if (resultBoardsDef)
                    {
                        this.pagesDefinition = resultBoardsDef.result;
                        this.i18nService.initialize(resultConfigDef.result.Languages);
                        resolve();
                    }
                    else
                    {
                        console.error("BoardsUndefined");
                        reject("BoardsUndefined");
                    }
                } else {
                    const { origin } = window.location;
                    const { Locale } = this.i18nService;
                    window.location.assign(`${origin}/getready.html`);
                }
            } catch (err) {
                window.location.assign(`${origin}/getready.html`);
                reject(err);
            }
        });
    }    

    public select(L0: string, L1: string, L3: string, keyword: string, view: number, discount: number = 0, useTopDeals: boolean = false, sortType: number = 0, page: number = 1): Observable<ResultsDefinition> {
        // si tag, par default, on utilise le sortPopular
        // si keyword, par default, on utilise le metascore
        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_SELECT + environment.API_SIGNATURE;

        let standardSort: string = '';
        switch (sortType) {
            default:
            case 0:         // SortLastOffer
                standardSort = "\"{'bestOffer.lastUpdate': -1}\"";
                break;
            case 1:         // SortPriceDown
                standardSort = "\"{'bestOffer.lowestOffer.value': -1}\"";
                break;
            case 2:         // SortPriceUp
                standardSort = "\"{'bestOffer.lowestOffer.value': 1}\"";
                break;
            case 3:         // SortRatingDown
                standardSort = "\"{'front.rating.product.average': -1}\"";
                break;
            case 4:         // SortRelevance
                standardSort = "\"{'score':{'$meta':'textScore'}}\"";
                break;
            case 98:         // SortPopular
                standardSort = "\"{'bestOffer.numberOfOffers':-1, 'front.tags.Hot': -1}\"";
                break;
            case 99:         // SortPriceDropDown
                standardSort = "\"{'bestOffer.save': -1, 'front.tags.Hot': -1}\"";
                break;
        }

        // En mode recherche, force la recherche avec le SortRelevance
        if (keyword !== '') {
            standardSort = "\"{'score':{'$meta':'textScore'}}\"";
        }

        let viewSort: string = '';
        switch (view) {
            case 0:         // ViewLatestDeal
                viewSort = "";
                break;
            case 1:         // ViewMostLiked
                viewSort = "\"{'front.rating.counters.like':-1}\"";
                break;
            case 2:         // ViewMostShared
                viewSort = "\"{'front.rating.counters.share': -1}\"";
                break;
            case 3:         // ViewMostViewed
                viewSort = "\"{'front.rating.counters.view':-1}\"";
                break;
            case 4:         // ViewMostWatched
                viewSort = "\"{'front.rating.counters.watch':-1}\"";
                break;
        }

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        if (L0 === 'all' && keyword === '') {
            postInfos += "\"{'put.front':true}\" ";
            
            if (useTopDeals) {
                postInfos += ", \"{'bestOffer.flag':'blue'}\"";
            } else {
                postInfos += ", \"{'bestOffer.flag':{'$in':['blue','green']}}\"";
            }

            if (L3 !== '' && L3 !== 'all') {
                postInfos += ", \"{'front.tags.L3': '" + L3 + "'}\"";
            }
        }
        else if (L0 !== '' && keyword === '') {
            postInfos += "\"{'put.front':true}\" ";

            if (useTopDeals) {
                postInfos += ", \"{'bestOffer.flag':'blue'}\"";
            } else {
                postInfos += ", \"{'bestOffer.flag':{'$in':['blue','green']}}\"";
            }

            postInfos += ", \"{'front.tags.L0':'" + L0 + "'}\"";

            if (L1 !== '' && L1 !== 'all') {
                postInfos += ", \"{'front.tags.L1':'" + L1 + "'}\"";
            }
            if (L3 !== '' && L3 !== 'all') {
                postInfos += ", \"{'front.tags.L3': '" + L3 + "'}\"";
            }

            this.logEvent.sendEventBrowse(L0);
        }
        else if (keyword !== '') {
            postInfos += "\"{'front.tags.Tube':null}\" ";
            postInfos += ", \"{'$text':{'$search':'" + keyword + "'}}\"";

            if (useTopDeals) {
                postInfos += ", \"{'bestOffer.flag':'blue'}\" ";
            }

            this.logEvent.sendEventSearch(keyword);
        }

        postInfos += "], ";
        postInfos += "\"sorts\": [";
        if (viewSort !== '') postInfos += viewSort + ", ";
        postInfos += standardSort;
        postInfos += "], ";
        postInfos += "\"start\": " + page + ", ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public selectChannels(L0: string, view: number, page: number = 1): Observable<ResultsDefinition> {
        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_TUBE + environment.API_SIGNATURE;

        let standardSort: string = '';
        standardSort = "\"{'front.tags.Tube': -1}\"";

        switch (view) {
            case 0:         // ViewLatestDeal
                standardSort += ", \"{'bestOffer.lastUpdate': -1}\"";
                break;
            case 1:         // ViewMostLiked
                standardSort += ", \"{'front.rating.counters.like':-1}\"";
                break;
            case 2:         // ViewMostShared
                standardSort += ", \"{'front.rating.counters.share': -1}\"";
                break;
            case 3:         // ViewMostViewed
                standardSort += ", \"{'front.rating.counters.view':-1}\"";
                break;
        }

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        if (L0 !== '' && L0 !== 'all') {
            postInfos += ", \"{'front.tags.L0':'" + L0 + "'}\"";
        }
        postInfos += "], ";
        postInfos += "\"sorts\": [";
        postInfos += standardSort;
        postInfos += "], ";
        postInfos += "\"start\": " + page + ", ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public selectBrands(brands: string[], page: number = 1): Observable<ResultsDefinition> {
        let formattedBrand: string[] = [];
        brands.forEach((brand) => {
            formattedBrand.push(brand.replace("'", "´"));
        });

        let brandList = formattedBrand.join("', '");

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        postInfos += "\"{'put.front':true}\"";
        postInfos += ", \"{'bestOffer.flag':{'$in':['blue','green']}}\"";
        postInfos += ", \"{'front.rating.brand.name':{'$in': ['" + brandList + "'] }}\"";

        postInfos += "], ";
        postInfos += "\"sorts\": [\"{'bestOffer.lastUpdate': -1}\"], ";
        postInfos += "\"start\": " + page + ", ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        this.logEvent.sendEventMyBrands();

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_SELECT + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public selectMarketPlaces(marketplaces: string[], page: number = 1): Observable<ResultsDefinition> {
        let marketList = marketplaces.join("', '");

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        postInfos += "\"{'put.front':true}\" ";
        postInfos += ", \"{'bestOffer.flag':{'$in':['blue','green']}}\"";
        postInfos += ", \"{'bestOffer.marketPlaceName':{'$in': ['" + marketList + "'] }}\"";
        postInfos += "], ";
        postInfos += "\"sorts\": [\"{'bestOffer.lastUpdate': -1}\"], ";
        postInfos += "\"start\": " + page + ", ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        this.logEvent.sendEventMyMarketplace();

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_SELECT + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public selectTags(tags: string[], page: number = 1): Observable<ResultsDefinition> {
        let tagList = tags.join("', '");

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        postInfos += "\"{'put.front':true}\" ";
        postInfos += ", \"{'bestOffer.flag':{'$in':['blue','green']}}\"";
        postInfos += ", \"{'front.tags.L3':{'$in': ['" + tagList + "'] }}\"";
        postInfos += "], ";
        postInfos += "\"sorts\": [\"{'bestOffer.lastUpdate': -1}\"], ";
        postInfos += "\"start\": " + page + ", ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        this.logEvent.sendEventMyTags();

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_SELECT + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public selectSpecificChannels(channels: string[], page: number = 1): Observable<ResultsDefinition> {
        let tagList = channels.join("', '");

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        postInfos += "\"{'front.rating.creator.name':{'$in': ['" + tagList + "'] }}\"";
        postInfos += "], ";
        postInfos += "\"sorts\": [\"{'bestOffer.lastUpdate': -1}\"], ";
        postInfos += "\"start\": " + page + ", ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        this.logEvent.sendEventMyChannels();

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_SELECT + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public getSimilarProduct(L3: string): Observable<ResultsDefinition> {
        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        postInfos += "\"{'put.front':true}\" ";
        postInfos += ", \"{'bestOffer.flag':{'$in':['blue','green']}}\"";
        postInfos += ", \"{'front.tags.L3': '" + L3 + "'}\"";
        postInfos += "], ";
        postInfos += "\"sorts\": [\"{'bestOffer.lastUpdate': -1}\"], ";
        postInfos += "\"start\": 1, ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_SELECT + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public getMayLikeProduct(brand: string, L3: string = ""): Observable<ResultsDefinition> {
        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"queries\":[";
        postInfos += "\"{'put.front':true}\" ";
        postInfos += ", \"{'bestOffer.flag':{'$in':['blue','green']}}\"";
        if (L3 != "") postInfos += ", \"{'front.tags.L3': {'$ne':'" + L3 + "'}}\"";
        postInfos += ", \"{'front.rating.brand.name':'" + brand + "'}\"";
        postInfos += "], ";
        postInfos += "\"sorts\": [\"{'bestOffer.lastUpdate': -1}\"], ";
        postInfos += "\"start\": 1, ";
        postInfos += "\"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}";

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_SELECT + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public refresh(cardIds: string[]): Observable<ResultsDefinition> {
        let cardList = cardIds.join("\", \"");
        let postInfos: string = "{\"cardIds\": [\"" + cardList + "\"]}";

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_REFRESH + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public getPriceHistory(productUpc: string): Observable<PriceHistoryDefinition> {
        
        let postInfos: string = "{\"productRef\": \"" + productUpc + "\", \"lang\": \"" + this.i18nService.Locale + "\" }";

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_PRICEHISTORY + environment.API_SIGNATURE;
        return this.http.post<PriceHistoryDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public scanBarcode(barcode: string): Observable<ResultsDefinition> {
        let postInfos: string = "{\"upc\": [\"" + barcode + "\"], \"lang\": \"" + this.i18nService.Locale + "\"}";

        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_REFRESH + environment.API_SIGNATURE;
        return this.http.post<ResultsDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public getPaymentCheckout(plan: string): Observable<ResultUrls>
    {
        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_CHECKOUT + environment.API_SIGNATURE + environment.API_PARAM_PLAN + plan + environment.API_PARAM_LOCALE + this.i18nService.Locale;
        return this.http.get<ResultUrls>(requestUrl, this.getHttpHeaders());
    }

    public getPaymentBilling(): Observable<ResultUrls>
    {
        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_BILLING + environment.API_SIGNATURE;
        return this.http.get<ResultUrls>(requestUrl, this.getHttpHeaders());
    }

    public view(card: ResultDefinition): Observable<ResultCounters> {
        this.createAction("view", card).subscribe({
            error: (e) => console.error(e)
        });
        return this.count("view", card);
    }
    public share(card: ResultDefinition): Observable<ResultCounters> {
        this.createAction("share", card).subscribe({
            error: (e) => console.error(e)
        });
        return this.count("share", card);
    }
    public like(card: ResultDefinition): Observable<ResultCounters> {
        this.createAction("like", card).subscribe({
            error: (e) => console.error(e)
        });
        return this.count("like", card);
    }
    public watch(card: ResultDefinition): Observable<ResultCounters> {
        this.createAction("watch", card).subscribe({
            error: (e) => console.error(e)
        });
        return this.count("watch", card);
    }
    public unwatch(card: ResultDefinition): Observable<ResultCounters> {
        return this.uncount("watch", card);
    }
    public unlike(card: ResultDefinition): Observable<ResultCounters> {
        return this.uncount("like", card);
    }

    public report(card: ResultDefinition): void {
        this.createAction("report", card).subscribe({
            error: (e) => console.error(e)
        });
    }

    public visit(card: ResultDefinition, offer?: OfferDefinition): void {
        const cardToVisit = offer ? {
            ...card,
            Product: {
                ...card.Product,
                PriceDropMarketPlaceName: offer.MarketPlaceName,
                PriceDropLowestOffer: offer.Price || 0
            }
        } : card;
        
        this.createAction("visit", cardToVisit).subscribe({
            error: (e) => console.error(e)
        });        
    }

    public createProfile(profile: ProfileDefinition, logout: boolean = false): Observable<ProfileDefinition> {
        let postInfos: string = JSON.stringify(profile);

        let requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_PROFILE + environment.API_SIGNATURE;
        if (logout) {
            requestUrl = requestUrl + environment.API_PARAM_LOGOUT;
        }
        return this.http.post<ProfileDefinition>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public deleteProfile(): Observable<void> {
        let postInfos: string = "{\"deleteProfile\": true }";
        
        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_PROFILE + environment.API_SIGNATURE;
        return this.http.post<void>(requestUrl, postInfos, this.getHttpHeaders());
    }

    private count(action: string, card: ResultDefinition): Observable<ResultCounters> {
        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_COUNT + "/" + card.Product.Id + environment.API_SIGNATURE;
        let postInfos: string = "{\"" + action + "\" : 1}";

        return this.http.post<ResultCounters>(requestUrl, postInfos, this.getHttpHeaders())
    }
    private uncount(action: string, card: ResultDefinition): Observable<ResultCounters> {
        const requestUrl: string = environment.API_URL + environment.API_CARD + environment.API_ACTION_COUNT + "/" + card.Product.Id + environment.API_SIGNATURE;

        let postInfos: string = "{\"" + action + "\" : -1}";
        return this.http.post<ResultCounters>(requestUrl, postInfos, this.getHttpHeaders());
    }

    private createAction(action: string, card: ResultDefinition): Observable<boolean> {
        const today: string = new Date().toISOString();

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"data\":{\"what\":{";
        postInfos += "\"cardId\":\"" + card.Product.Id + "\",";
        postInfos += "\"brand\":\"" + card.Product.BrandName + "\",";
        postInfos += "\"creator\":\"" + card.Product.CreatorName + "\",";
        postInfos += "\"L0\":\"" + card.Product.Tags.L0 + "\",";
        postInfos += "\"L1\":\"" + card.Product.Tags.L1 + "\",";
        postInfos += "\"L2\":\"" + card.Product.Tags.L2 + "\",";
        postInfos += "\"L3\":\"" + card.Product.Tags.L3 + "\",";
        postInfos += "\"store\":\"" + card.Product.PriceDropMarketPlaceName + "\",";
        postInfos += "\"lowestOffer\":" + card.Product.PriceDropLowestOffer;
        postInfos += "}, \"when\": \"" + today + "\", \"how\": \"" + action + "\"}}";

        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_ACTION + environment.API_SIGNATURE;
        return this.http.post<boolean>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public createReview(nbStar: number, review: string): Observable<boolean>
    {
        const today: string = new Date().toISOString();

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"data\":{\"what\":{";
        postInfos += "\"source\":\"webapp\",";
        postInfos += "\"rating\": " + nbStar + ",";
        postInfos += "\"review\":\"" + review + "\"";
        postInfos += "}, \"when\": \"" + today + "\", \"how\": \"review\"}}";

        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_ACTION + environment.API_SIGNATURE;
        return this.http.post<boolean>(requestUrl, postInfos, this.getHttpHeaders()).pipe(catchError(this.handleError));
    }

    public createContact(email: string, contact: string): Observable<boolean>
    {
        const today: string = new Date().toISOString();

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"data\":{\"what\":{";
        postInfos += "\"source\":\"webapp\",";
        postInfos += "\"email\":\"" + email + "\",";
        postInfos += "\"contact\":\"" + contact + "\"";
        postInfos += "}, \"when\": \"" + today + "\", \"how\": \"contact\"}}";

        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_ACTION + environment.API_SIGNATURE;
        return this.http.post<boolean>(requestUrl, postInfos, this.getHttpHeaders()).pipe(catchError(this.handleError));
    }

    public requestProduct(ean: string): Observable<boolean> {
        const today: string = new Date().toISOString();

        let postInfos: string = '';
        postInfos = "{";
        postInfos += "\"data\":{\"what\":{";
        postInfos += "\"upc\":\"" + ean + "\", \"lang\": \"" + this.i18nService.Locale + "\"";
        postInfos += "}, \"when\": \"" + today + "\", \"how\": \"request\"}}";

        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_ACTION + environment.API_SIGNATURE;
        return this.http.post<boolean>(requestUrl, postInfos, this.getHttpHeaders());
    }

    public getProfile(): Observable<ResultProfileDefinition> {
        const requestUrl: string = environment.API_URL + environment.API_EVENT + environment.API_ACTION_PROFILE + environment.API_SIGNATURE;
        return this.http.get<ResultProfileDefinition>(requestUrl, this.getHttpHeaders());
    }

    // Récupère le fichier de langue
    public getLanguage(langId: string): Observable<ResultLanguageTextsDefinition> {
        const requestUrl: string = environment.API_URL + environment.API_BOARD + environment.API_ACTION_LOAD + environment.API_SIGNATURE;
        return this.http.get<ResultLanguageTextsDefinition>(requestUrl);
    }

    // Récupère le fichier de preload
    private getConfig(): Observable<ResultConfigDefinition> {
        const requestUrl: string = environment.API_URL + environment.API_BOARD + environment.API_ACTION_PRELOAD + environment.API_SIGNATURE + environment.API_VERSION;
        return this.http.get<ResultConfigDefinition>(requestUrl);
    }

    // Récupère le board
    private getBoardById(boardId: string): Observable<ResultPagesDefinition> {
        const requestUrl: string = environment.API_URL + environment.API_BOARD + environment.API_ACTION_LOAD + environment.API_SIGNATURE + environment.API_PARAM_ID + boardId;
        return this.http.get<ResultPagesDefinition>(requestUrl);
    }

    private getHttpHeaders() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        return { headers: headers };
    }

    private handleError(error: HttpErrorResponse)
    {
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          console.error('An error occurred:', error.error.message);
        } else {
          // Server-side error
          console.error(`Server returned code ${error.status}, body was: ${error.error}`);
        }
        // You can customize the error handling logic here, e.g., logging, displaying error messages, etc.
        return throwError('Something bad happened; please try again later.');
      }
}