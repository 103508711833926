<!-- SECTION HEADER -->
<header></header>

<div class="overflow-y-scroll">
    <!-- SECTION HERO + SUBNAV -->
    <hero></hero>
    <subnav [isTitleMode]="true" title="wap1_c_header_contact"></subnav>

    <div class="container px-4">

        <div class="row my-5">
			<div class="col-12 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_p_contact_contact' | translate | safeMark">
					</div>
				</div>
			</div>
		</div>

        <!-- hereForYou -->
        <div class="row my-5 justify-content-center">
            <div class="col-12">
                <div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
                </div>
            </div>
        </div>

        <hr>
        <footer></footer>
    </div>
</div>