import { Component } from '@angular/core';

@Component({
  selector: 'app-region-not-open',
  templateUrl: './region-not-open.component.html',
  styleUrls: ['./region-not-open.component.scss']
})
export class RegionNotOpenComponent
{
    constructor()
    {
    }

    
}
