<!-- SECTION HEADER -->
<header></header>


<div class="overflow-y-scroll">
    <hero></hero>
	<subnav [isTitleMode]="true" title="{{ 'wap1_p_seeyou_title' | translate }}"></subnav>

	<div class="container px-4">
		<div class="row my-5">
			<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
			<div class="col-12 d-flex align-items-center">
				<div class="col">
					<div class="markdown-container p-3" [innerHTML]="'wap1_p_seeyou_uninstall' | translate | safeMark">
					</div>
				</div>
			</div>
		</div>

		<div class="row my-5 justify-content-center">
			<div class="col-12 text-center">
				<div class="d-flex justify-content-center">
					<btn-standard (click)="onJoinClick()">{{ 'wap1_p_info_joinNow' | translate}}</btn-standard>
				</div>
			</div>
		</div>
	
		<div class="container-fluid px-4">
			<div class="row my-5">
				<!-- d-none: hidden on smaller screens, d-lg-block: visible on lg screens and above -->
				<div class="col-12 d-flex align-items-center">
					<div class="col">
						<div class="markdown-container p-3" [innerHTML]="'wap1_c_footer_hereForYou' | translate | safeMark">
						</div>
					</div>
				</div>
			</div>
		</div>
	
		<hr>
		<footer></footer>
	</div>
</div>